import React from 'react'

export const ArrowDown = ({ rotate = 'rotate(0)', width }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x='0'
    y='0'
    transform={rotate}
    version='1.1'
    viewBox='0 0 28 21'
    xmlSpace='preserve'
    width={width}
  >
    <switch>
      <g>
        <image
          width='21'
          height='28'
          overflow='visible'
          transform='rotate(90 14 14)'
          xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAcCAYAAACOGPReAAAACXBIWXMAAAsSAAALEgHS3X78AAAA GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAe9JREFUeNq0lgtTwjAQhNsQoYLi W///j/P9RKQildRL/UKvoVTHkc4sNCls9vbukiZJ1+XKNPnDlXYQxc/K6tOk5e9JazLDvFHPHSgr /EBso7En6gl2eGYgWgo+gRMBros4VSoD4UCwK8gg99dCMAeLFfkGYhstsAPhAdhl3pO9gpngQ1Bs UmwVoWGcQXguOGQuFzwL+kQz5X+txDbKtkHtEMIL1Hpl+zzrRcLWiG01qInLKApPeMR4qJKny6ys PFbE2lNHlkNScu5TCAeoTCFya6XmyqqO7dqKdVK8h2PBCJ+HitTx2wIslahWpXMS8QhZgFe6R/ie 8J1KyPH8M6i2q9ZzlR0hfP/DF4hGJCmoHkPywsJPDa8lP6alv53ydYZq7e8AK0Yksq9sSZMok/92 mZa2NayeqdCHzJWEnhPFOxEsVbJJ1HedpoTRV8V/Cg6ZLyAMXr5iU0hSo6T0DpWh7oSOOmPsF30T PAiuBXckK2cx11Ramxx6f0wnHUNo+LNXdyW4hHSCBbXSqE6NCj+LfJwR6g2ENyzw1vB01abNc0jf hwJPSM4TYd9iwbRR9Bs2lFCjhSr+JNr67vmekqCibbO2UdGH3p8wP+3cpDfs/jbqpiIK+U/HSXya /u7g6yDc2hG9lZeJZBuvPV8CDACpVwBDfa77LwAAAABJRU5ErkJggg=='
        />
        <path
          fill='#00FEFF'
          d='M14 12.2l5.7-5.7c.4-.4 1.1-.4 1.5 0 .4.4.4 1.1 0 1.5l-6.4 6.4c-.4.4-1.1.4-1.5 0L6.8 8c-.4-.4-.4-1 0-1.5.4-.4 1.1-.4 1.5 0l5.7 5.7z'
        />
      </g>
    </switch>
  </svg>
)
