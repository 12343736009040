import React from 'react'
import { createPortal } from 'react-dom'
import { ModalComponent, ModalContainer, ModalClose } from './styles'

export const Modal = ({ isOpen, onCloseModal, children, backgroundColor = false, isVisibleClose = true }) => {
  return (isOpen
    ? (createPortal(
      <ModalComponent>
        <ModalContainer backgroundColor={backgroundColor}>
          {isVisibleClose && (
            <ModalClose onClick={onCloseModal} />
          )}
          {children}
        </ModalContainer>
      </ModalComponent>,
      document.getElementById('modal')
      ))
    : null
  )
}
