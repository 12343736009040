import styled from 'styled-components'

export const ProgressCircleComponent = styled.div`
  --value: ${({ percentil }) => percentil};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  background: radial-gradient( circle closest-side, #070d36 86%, transparent 87.5% /* + 0.5% to avoid ugly border */ ), conic-gradient( #00FEFF var(--value), #6A65A8 var(--value) );
  cursor: pointer;
  box-shadow: 0 0 3px #00feff;
`
