import React from 'react'
import { ButtonComponent, ButtonAlertComponent, ButtonDisabledComponent } from './styles.js'
import { ArrowRight } from '../../assets/static/Arrow'

export const Button = ({
  buttonType = 'button',
  buttonOnClick,
  buttonTitle,
  buttonActive = 'false',
  buttonWidth = 'auto',
  buttonHeight = 'auto',
  buttonFontFamily = 'TitilliumWebSemiBold',
  buttonFontSize = '12px',
  buttonBorder = 'true',
  buttonIconArrowRight = 'true',
  buttonIconArrowSize = '32px'
}) => (
  <ButtonComponent
    type={buttonType}
    onClick={buttonOnClick}
    buttonActive={buttonActive}
    buttonWidth={buttonWidth}
    buttonHeight={buttonHeight}
    buttonFontFamily={buttonFontFamily}
    buttonFontSize={buttonFontSize}
    buttonBorder={buttonBorder}
    buttonIconArrowRight={buttonIconArrowRight}
    buttonIconArrowSize={buttonIconArrowSize}
  >
    {buttonTitle}
    {buttonIconArrowRight === 'true' && <ArrowRight width={buttonIconArrowSize} />}
  </ButtonComponent>
)

export const ButtonAlert = ({
  buttonType = 'button',
  buttonOnClick,
  buttonTitle,
  buttonActive = 'false',
  buttonWidth = 'auto',
  buttonHeight = 'auto',
  buttonFontFamily = 'TitilliumWebSemiBold',
  buttonFontSize = '12px',
  buttonBorder = 'true',
  buttonIconArrowRight = 'true',
  buttonIconArrowSize = '32px'
}) => (
  <ButtonAlertComponent
    type={buttonType}
    onClick={buttonOnClick}
    buttonActive={buttonActive}
    buttonWidth={buttonWidth}
    buttonHeight={buttonHeight}
    buttonFontFamily={buttonFontFamily}
    buttonFontSize={buttonFontSize}
    buttonBorder={buttonBorder}
    buttonIconArrowRight={buttonIconArrowRight}
    buttonIconArrowSize={buttonIconArrowSize}
  >
    {buttonTitle}
    {buttonIconArrowRight === 'true' && <ArrowRight width={buttonIconArrowSize} />}
  </ButtonAlertComponent>
)

export const ButtonDisabled = ({
  buttonType = 'button',
  buttonOnClick,
  buttonTitle,
  buttonActive = 'false',
  buttonWidth = 'auto',
  buttonHeight = 'auto',
  buttonFontFamily = 'TitilliumWebSemiBold',
  buttonFontSize = '12px',
  buttonBorder = 'true',
  buttonIconArrowRight = 'true',
  buttonIconArrowSize = '32px'
}) => (
  <ButtonDisabledComponent
    type={buttonType}
    onClick={buttonOnClick}
    buttonActive={buttonActive}
    buttonWidth={buttonWidth}
    buttonHeight={buttonHeight}
    buttonFontFamily={buttonFontFamily}
    buttonFontSize={buttonFontSize}
    buttonBorder={buttonBorder}
    buttonIconArrowRight={buttonIconArrowRight}
    buttonIconArrowSize={buttonIconArrowSize}
  >
    {buttonTitle}
    {buttonIconArrowRight === 'true' && <ArrowRight width={buttonIconArrowSize} />}
  </ButtonDisabledComponent>
)
