import React, { useEffect, useState } from 'react'
import {
  ModalArrowLeft,
  LoadingContainer,
  ModalContainer,
  Title,
  MainContainer,
  SubtitleContent,
  BalanceContent,
  CardFieldValues,
  Field,
  Value,
  ButtonContainer
} from './styles'

// Components
import { Modal } from '../../components/Modal'
import { Loader } from '../../components/Loader'
import { Button } from '../../components/Button'
import { DefaultCreditCard } from '../../components/DefaultCreditCard'

// Hooks
import { useCards } from '../../hooks/useCards'
import { useConfig } from '../../hooks/useConfig'
import { useAppContext } from '../../hooks/useAppContext'

// Helper
import { getDefaultCard } from '../../helpers/CreditCard'

// Notifications
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// API
import http from '../../services/DataService'
import { ENDPOINTS } from '../../constants'

// Service
import { getAccountInfo } from '../../services/User'
import useTranslation from '../../i18n'

export const PurchaseModal = ({
  coinsToBuy,
  isOpen,
  onCloseModal
}) => {
  const { profile, setProfile } = useAppContext()
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const { cards, loading: loading1, error: error1 } = useCards(isOpen)
  const { config, loading: loading2 } = useConfig()
  const [defaultCard, setDefaultCard] = useState(null)
  const [view] = useState('main') // main, verification
  const { t } = useTranslation()

  const handleBreak = () => {
    switch (view) {
      case 'main':
        onCloseModal()
        break
    }
  }

  // Loading handle
  useEffect(() => {
    if (loading1 || loading2) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [loading1, loading2])

  // Error handle
  useEffect(() => {
    if (error === null) return
    if (error) {
      toast.error(error)
    }
  }, [error, error1])

  // setDefaultCard
  useEffect(() => {
    setDefaultCard(getDefaultCard(cards))
  }, [cards, isOpen])

  const handlePurchase = async () => {
    if (defaultCard !== null) {
      try {
        setLoading(true)
        const res = await http.create(ENDPOINTS.PURCHASE_COINS, {
          idCard: defaultCard._id,
          coins: coinsToBuy
        })

        if (res.status === 200) {
          const res2 = await getAccountInfo()
          if (res2.status === 200) {
            setProfile(res2.result)
          }
          setLoading(false)
          onCloseModal()
        } else {
          setLoading(false)
          setError(res.message_en)
        }
      } catch (error) {
        setLoading(false)
        setError(error.message)
      }
    } else {
      toast.warning('You need a default credit card')
    }
  }

  const getDiscount = (coins) => {
    const discount = (100 - config.coinPremiunCost) / 100
    return (coins * discount).toFixed(2)
  }

  const getCoinsPerDolar = (coins) => {
    return coins * config.coinValue
  }

  if (loading) {
    return (
      <Modal isOpen={isOpen} onCloseModal={onCloseModal} backgroundColor>
        <ModalArrowLeft height='30px' onClick={handleBreak} />
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      </Modal>
    )
  }

  return (
    <>
      <Modal isOpen={isOpen} onCloseModal={onCloseModal} backgroundColor>
        <ModalArrowLeft height='30px' onClick={handleBreak} />
        <ModalContainer>
          <Title><h2>{t('PurchaseConfirmation')}</h2></Title>
          <MainContainer>
            <SubtitleContent>
              <h3>{t('OrderSummary')}</h3>
            </SubtitleContent>
            <DefaultCreditCard
              defaultCard={defaultCard}
            />
            <BalanceContent>
              <CardFieldValues>
                <Field>{t('PackageCoins')}</Field>
                <Value>{coinsToBuy} Coins</Value>
              </CardFieldValues>
              <CardFieldValues>
                <Field>{t('TotalOrder')}</Field>
                <Value>{profile.membership === 'Free' ? getCoinsPerDolar(coinsToBuy) : getDiscount(getCoinsPerDolar(coinsToBuy))} USD</Value>
              </CardFieldValues>
            </BalanceContent>
            <ButtonContainer>
              {
                defaultCard !== null &&
                  <Button
                    buttonTitle={`${t('Pay')} ${
                      profile.membership === 'Free'
                        ? getCoinsPerDolar(coinsToBuy)
                        : getDiscount(getCoinsPerDolar(coinsToBuy))} £`
                    }
                    buttonWidth='194px'
                    buttonHeight='56px'
                    buttonFontSize='18px'
                    buttonActive='true'
                    buttonOnClick={handlePurchase}
                  />
              }
            </ButtonContainer>
          </MainContainer>
        </ModalContainer>
      </Modal>
      <ToastContainer />
    </>
  )
}
