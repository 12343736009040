import React from 'react'
import { FooterComponent, Nav } from './styles'
import { FooterLink } from '../FooterLink'
import { Social } from '../Social'

export const Footer = () => {
  return (
    <FooterComponent>
      <Nav>
        <FooterLink textNavLink='About Meixter' toNavLink='/about' />
        <FooterLink textNavLink='FAQs' toNavLink='faqs' />
        <FooterLink textNavLink='Teams Of Service' toNavLink='team-service' />
        <FooterLink textNavLink='Privacy Policy' toNavLink='privacy-policy' />
      </Nav>
      <Social />
    </FooterComponent>
  )
}
