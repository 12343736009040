import styled from 'styled-components'
import { ArrowLeft } from '../../assets/static/Arrow'
import { fontStyleTitle, fontStyleContent } from '../../styles/general'

// fontFamilyMedium
// fontFamilyRegular
// fontFamilyBold == fontFamily

export const ModalArrowLeft = styled(ArrowLeft)`
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const ContainerModal = styled.section`
  height: 350px;
  width: 550px;
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.backgroundDark};
`

export const ModalContainer = styled(ContainerModal)`
  display: grid;
  grid-template-rows: 14% 86%;
  grid-template-columns: 1fr;
  grid-template-areas:  "title"
                        "content";
`

const ContainerSection = styled.section`
  width: 100%;
  height: 100%;
`

export const TitleContainer = styled(ContainerSection)`
  grid-area: title;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 20% 1fr 20%;
  grid-template-areas: "left-icon title-main right-icon";

  & h3 {
    ${fontStyleTitle};
    font-size: 16px;
  }
`

// Title
export const Title = styled.article`
  grid-area: title-main;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`

export const MainContainer = styled(ContainerSection)`
  grid-area: content;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
`

const WrapperMessage = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`

export const MainMessageContent = styled(WrapperMessage)`
  & h3 {
    ${fontStyleTitle}
  }
`

export const ButonContent = styled(WrapperMessage)`
  padding: 15px 0;
`

export const SecoundaryMessageContent = styled(WrapperMessage)`
  & p {
    ${fontStyleContent}
  }
`
