import styled from 'styled-components'
import { device } from '../../styles/device'

export const MarginPurple = styled.div`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  background-color: ${({ withPurple, theme }) => withPurple ? theme.colors.violet : 'transparent'};
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.tablet}{
    height: 26px;
    width: 26px;
  }
`

export const Image = styled.img`
  border-radius: 50%;
  overflow: hidden;
  height: 86%;
  width: 86%;
  object-fit: cover;
`
