import styled from 'styled-components'
import { Close } from '../../assets/static/Close'
import { device } from '../../styles/device'

export const ModalComponent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
`

export const ModalContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 60px;
  min-width: 100px;
  width: auto;
  min-height: 100px;
  height: auto;
  border-radius: 12px;
  ${({ backgroundColor, theme }) => backgroundColor
    ? `background-color: ${theme.backgroundDark}`
    : 'background-color: #070d36'
  };

  @media only screen and (max-width: 768px) {
    padding: 10px 20px;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`

export const ModalClose = styled(Close)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;

  @media ${device.tablet} {
    top: 25px;
    right: 40px;
  }
`
