/* eslint-disable react/react-in-jsx-scope */
import { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'

// Context
import { useAppContext } from '../hooks/useAppContext'
import { useLessonsDispatch } from '../hooks/useLessonsDispatch'

// Actions
import { handleLoginModal } from '../context/Lesssons/actions'

const ProtectedRoute = ({ children, ...rest }) => {
  const { isLoggin } = useAppContext()
  const dispatch = useLessonsDispatch()

  useEffect(() => {
    if (!isLoggin) {
      handleLoginModal(dispatch, true)
    }
  }, [])

  return (
    <Route
      {...rest}
      render={() =>
        isLoggin
          ? (
              children
            )
          : (
              <Redirect to='/' />
            )
      }
    />
  )
}

export default ProtectedRoute
