import {
  getAllLessons,
  getAllFreeLessons,
  getAllSavedLessons,
  getAllFollowedLessons,
  getAllMyLessons,
  getAllTeacherSessions,
  getAllCompletedLessons,
  getAllTagsLesson,
  getLessonsFromLessonByTags,
  // getUniqueUsersFromLessons,
  handleUpdateSave
} from '../../services/Lessons'

import { getAllUsers } from '../../services/User'

// true false
// isLoginModalOpen: false,
export const handleLoginModal = (dispatch, newState) => {
  dispatch({ type: 'CHANGE_STATE_LOGIN_MODAL', payload: newState })
}

export const handleIssueModal = (dispatch, newState) => {
  dispatch({ type: 'CHANGE_STATE_ISSUE_MODAL', payload: newState })
}

export const handleBookingModal = (dispatch) => {
  dispatch({ type: 'CHANGE_STATE_BOOKING_MODAL' })
}

export const handleTeacherModalPanel = (dispatch) => {
  dispatch({ type: 'CHANGE_STATE_TEACHER_MODAL_PANEL' })
}

export const handleTagsModalPanel = (dispatch) => {
  dispatch({ type: 'CHANGE_STATE_TAG_MODAL_PANEL' })
}

export const handleCreateLessonModal = (dispatch) => {
  dispatch({ type: 'CHANGE_STATE_CREATE_LESSON_MODAL' })
}

export const handleTeachCalendarModal = (dispatch) => {
  dispatch({ type: 'CHANGE_STATE_TEACH_CALENDAR_MODAL' })
}

export const handleFilterByTagsModal = (dispatch) => {
  dispatch({ type: 'CHANGE_STATE_FILTER_BY_TAGS_MODAL' })
}

export const handleStatisticsModal = (dispatch) => {
  dispatch({ type: 'CHANGE_STATE_STATISTICS_MODAL' })
}

export const handleMyWalletModal = (dispatch) => {
  dispatch({ type: 'CHANGE_STATE_MY_WALLET_MODAL' })
}

export const getMainLessons = async (dispatch, language) => {
  try {
    const res = await getAllLessons(language)

    if (res.status >= 200 && res.status < 300) {
      dispatch({
        type: 'GET_MAIN_LESSONS',
        payload: res.result
      })
    }
  } catch (error) {
    if (error.status) {
      dispatch({ type: 'REQUEST_ERROR', error: error.message_en })
      return
    }

    dispatch({ type: 'REQUEST_ERROR', error: error.message })
  }
}

export const getMainLessonsChangeLanguage = async (dispatch, language) => {
  try {
    const res = await getAllLessons(language)

    if (res.status >= 200 && res.status < 300) {
      dispatch({
        type: 'GET_MAIN_LESSONS_LANGUAGE',
        payload: res.result
      })
    }
  } catch (error) {
    if (error.status) {
      dispatch({ type: 'REQUEST_ERROR', error: error.message_en })
      return
    }

    dispatch({ type: 'REQUEST_ERROR', error: error.message })
  }
}

export const getMainFreeLessons = async (dispatch) => {
  try {
    const res = await getAllFreeLessons()

    if (res.status >= 200 && res.status < 300) {
      dispatch({
        type: 'GET_MAIN_FREE_LESSONS',
        payload: res.result
      })
    }
  } catch (error) {
    if (error.status) {
      dispatch({ type: 'REQUEST_ERROR', error: error.message_en })
      return
    }

    dispatch({ type: 'REQUEST_ERROR', error: error.message })
  }
}

export const getSavedLessons = async (dispatch) => {
  try {
    const res = await getAllSavedLessons()

    if (res.status >= 200 && res.status < 300) {
      dispatch({
        type: 'GET_SAVED_LESSONS',
        payload: res.result
      })
    }
  } catch (error) {
    if (error.status) {
      dispatch({ type: 'REQUEST_ERROR', error: error.message_en })
      return
    }

    dispatch({ type: 'REQUEST_ERROR', error: error.message })
  }
}

export const getFollowedLessons = async (dispatch) => {
  try {
    const res = await getAllFollowedLessons()

    if (res.status >= 200 && res.status < 300) {
      dispatch({
        type: 'GET_FOLLOWED_LESSONS',
        payload: res.result
      })
    }
  } catch (error) {
    if (error.status) {
      dispatch({ type: 'REQUEST_ERROR', error: error.message_en })
      return
    }

    dispatch({ type: 'REQUEST_ERROR', error: error.message })
  }
}

export const getMyLessons = async (dispatch) => {
  try {
    dispatch({ type: 'REQUEST_START' })
    const res = await getAllMyLessons()

    if (res.status >= 200 && res.status < 300) {
      dispatch({
        type: 'GET_MY_LESSONS',
        payload: res.result
      })
    }
  } catch (error) {
    if (error.status) {
      dispatch({ type: 'REQUEST_ERROR', error: error.message_en })
      return
    }

    dispatch({ type: 'REQUEST_ERROR', error: error.message })
  }
}

export const getScheduledLessons = async (dispatch) => {
  try {
    dispatch({ type: 'REQUEST_START' })
    const res = await getAllTeacherSessions()

    if (res.status >= 200 && res.status < 300) {
      dispatch({
        type: 'GET_SCHEDULED_LESSONS',
        payload: res.result
      })
    }
  } catch (error) {
    if (error.status) {
      dispatch({ type: 'REQUEST_ERROR', error: error.message_en })
      return
    }

    dispatch({ type: 'REQUEST_ERROR', error: error.message })
  }
}

export const getCompletedLessons = async (dispatch) => {
  try {
    dispatch({ type: 'REQUEST_START' })
    const res = await getAllCompletedLessons()

    if (res.status >= 200 && res.status < 300) {
      dispatch({
        type: 'GET_COMPLETED_LESSONS',
        payload: res.result
      })
    }
  } catch (error) {
    if (error.status) {
      dispatch({ type: 'REQUEST_ERROR', error: error.message_en })
      return
    }

    dispatch({ type: 'REQUEST_ERROR', error: error.message })
  }
}

export const getLessonsFiltersByTags = async (dispatch) => {
  try {
    const res = await getAllTagsLesson()
    const resU = await getAllUsers()

    if (res.status >= 200 && res.status < 300) {
      const res2 = await getLessonsFromLessonByTags(res.result)

      dispatch({
        type: 'GET_COMPLETED_LESSONS_BY_TAGS',
        payload: {
          lessonsByTag: res.result,
          lessonsFromLessonsByTags: res2,
          usersFromLessonsByTags: resU.result
        }
      })
    }
  } catch (error) {
    if (error.status) {
      dispatch({ type: 'REQUEST_ERROR', error: error.message_en })
      return
    }

    dispatch({ type: 'REQUEST_ERROR', error: error.message })
  }
}

export const setActiveLessons = (dispatch, lessons) => {
  dispatch({ type: 'CHANGE_ACTIVE_LESSONS', payload: [...lessons] })
}

export const handleChangeActiveIndex = (dispatch, index) => {
  dispatch({ type: 'CHANGE_ACTIVE_INDEX', payload: index })
}

const updateLesson = (id, lessons = [], addValue = {}) => {
  return new Promise((resolve, reject) => {
    try {
      const res = lessons.map(item => {
        if (item._id === id) {
          const newSaved = item.saved.length > 0 ? [] : [addValue]
          return {
            ...item,
            saved: newSaved,
            save: addValue.totalSave
          }
        } else {
          return item
        }
      })
      resolve(res)
    } catch (error) {
      reject(error)
    }
  })
}

export const saveLesson = async (dispatch, lessons, lesson) => {
  try {
    const res = await handleUpdateSave(lesson._id, lesson.saved.length > 0)

    if (res.status >= 200 && res.status < 300) {
      const main = await updateLesson(lesson._id, lessons.mainLessons, res.result)
      const followed = await updateLesson(lesson._id, lessons.followedLessons, res.result)
      const saved = await updateLesson(lesson._id, lessons.savedLessons, res.result)

      dispatch({
        type: 'SAVE_LESSONS',
        payload: { main, followed, saved }
      })
    }
  } catch (error) {
    if (error.status) {
      dispatch({ type: 'REQUEST_ERROR', error: error.message_en })
      return
    }

    dispatch({ type: 'REQUEST_ERROR', error: error.message })
  }
}
