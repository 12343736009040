import React, { useState } from 'react'
import {
  DefaultCreditCardWrapper,
  DefaultCreditCardContent,
  EmptyDefaultCreditCardContent,
  CreditCardSummary,
  CreditCardText,
  ButtonSummary,
  EmptyTextCreditCardContent
} from './styles'

// Components
import { CreditCardReadModal } from '../../container/CreditCardReadModal'
import { ArrowDown } from '../../assets/static/ArrowDown'
import useTranslation from '../../i18n'
// Helper
import { getIconCreditCard } from '../../helpers/CreditCard'

export const DefaultCreditCard = ({
  defaultCard,
  handleSetDefaultCard = null,
  callback
}) => {
  const [isOpenReadCreditCard, setIsOpenReadCreditCard] = useState(false)
  const { t } = useTranslation()

  // CreditCardReadModal
  const handleOpenReadCreditCardModal = () => {
    setIsOpenReadCreditCard(true)
  }
  const handleCloseReadCreditCardModal = () => {
    if (callback) {
      callback()
    }
    setIsOpenReadCreditCard(false)
    if (handleSetDefaultCard != null) {
      handleSetDefaultCard('reload')
    }
  }

  return (
    <>
      <DefaultCreditCardWrapper>
        {
          (defaultCard === null)
            ? <EmptyDefaultCreditCardContent onClick={handleOpenReadCreditCardModal}>
              <EmptyTextCreditCardContent>
                <h2>{t('AddCreditCard')}</h2>
              </EmptyTextCreditCardContent>
              <ButtonSummary>
                <ArrowDown width='24px' />
              </ButtonSummary>
            </EmptyDefaultCreditCardContent>
            : <DefaultCreditCardContent onClick={handleOpenReadCreditCardModal}>
              <CreditCardSummary>
                <img src={getIconCreditCard(defaultCard.type)} alt='Credit Card Icon' />
              </CreditCardSummary>
              <CreditCardText>
                <h3>{defaultCard.cardNumber.substr(-4)}</h3>
                <p>{t('CreditCard')}</p>
              </CreditCardText>
              <ButtonSummary>
                <ArrowDown width='24px' />
              </ButtonSummary>
            </DefaultCreditCardContent>
        }
      </DefaultCreditCardWrapper>
      <CreditCardReadModal title={t('paymentMethods')} isOpen={isOpenReadCreditCard} onCloseModal={handleCloseReadCreditCardModal} />
    </>
  )
}
