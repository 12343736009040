import React from 'react'
import { useLocation } from 'react-router-dom'
import { NavLinkComponent, Text } from './styles'
import { MenuLearnActive, MenuLearnInactive, MenuSessionActive, MenuSessionInactive, MenuTeachActive, MenuTeachInactive, MenuMeActive, MenuMeInactive } from '../../assets/static/Menu'

export const NavLink = ({ textNavLink, toNavLink, widthIconNavLink = '26px' }) => {
  const location = useLocation()
  return (
    <>
      <NavLinkComponent exact to={toNavLink}>
        {
          toNavLink === '/' && (
            location.pathname === toNavLink
              ? <MenuLearnActive width={widthIconNavLink} />
              : <MenuLearnInactive width={widthIconNavLink} />
          )
        }
        {
          toNavLink === '/sessions' && (
            location.pathname === toNavLink
              ? <MenuSessionActive width={widthIconNavLink} />
              : <MenuSessionInactive width={widthIconNavLink} />
          )
        }
        {
          toNavLink === '/teach/upcoming' && (
            location.pathname === toNavLink
              ? <MenuTeachActive width={widthIconNavLink} />
              : <MenuTeachInactive width={widthIconNavLink} />
          )
        }
        {
          toNavLink === '/me' && (
            location.pathname === toNavLink
              ? <MenuMeActive width={widthIconNavLink} />
              : <MenuMeInactive width={widthIconNavLink} />
          )
        }
        <Text>
          {textNavLink}
        </Text>
      </NavLinkComponent>
    </>
  )
}
