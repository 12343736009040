import { css } from 'styled-components'

// fontFamilyMedium
// fontFamilyRegular
// fontFamilyBold == fontFamily
export const fontStyleTitle = css`
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  color: ${({ theme }) => theme.colors.white};
  font-size: 13px;
`

export const fontStyleContent = css`
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  font-weight: 400;
`

export const scrollBar = css`
  &::-webkit-scrollbar {
      width: 8px;     /* Tamaño del scroll en vertical */
      height: 8px;    /* Tamaño del scroll en horizontal */
      display: none;  /* Ocultar scroll */
  }

  /* Ponemos un color de fondo y redondeamos las esquinas del thumb */
  &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 4px;
  }

  /* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
  &::-webkit-scrollbar-thumb:hover {
      background: #b3b3b3;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }

  /* Cambiamos el fondo cuando esté en active */
  &::-webkit-scrollbar-thumb:active {
      background-color: #999999;
  }

  /* Ponemos un color de fondo y redondeamos las esquinas del track */
  &::-webkit-scrollbar-track {
      background: #e1e1e1;
      border-radius: 4px;
  }

  /* Cambiamos el fondo cuando esté en active o hover */
  &::-webkit-scrollbar-track:hover,
  &::-webkit-scrollbar-track:active {
    background: #d4d4d4;
  }
`

export const centerHorizontalVertical = css`
  display: flex;
  justify-content: center;
  align-items: center;
`
