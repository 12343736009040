import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const FooterLinkComponent = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  width: auto;
  height: 100%;
  color: #fff;
  font-size: 10px;
  text-decoration: none;
  opacity: 0.4;

  &[aria-current] {
    opacity: 1;
  }

  &:hover {
    opacity: 1;
  }
`
