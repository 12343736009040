import styled, { keyframes } from 'styled-components'
import { ArrowLeft } from '../../assets/static/Arrow'

const animationRightToLeft = keyframes`
  from {
    right: 0;
  }
  to {
    left: 24%;
  }
`

export const ModalComponent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: ${({ opacity }) => `rgba(0, 0, 0, ${opacity})`};
  display: flex;
`

export const ModalContainer = styled.div`
  position: fixed;
  top: 56px;
  right: 0;
  width: 24%;
  height: calc(100% - 81px);
  z-index: 10;
  /* animation: ${animationRightToLeft} 0.3s alternate; */
`

export const ModalArrowLeft = styled(ArrowLeft)`
  position: absolute;
  top: 8px;
  left: 8px;
  cursor: pointer;
`
