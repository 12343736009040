import styled, { css } from 'styled-components'
import {
  fontStyleTitle,
  scrollBar
} from '../../styles/general'

export const fontStyle = css`
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 400;
`

// Content
const Content = styled.section`
  width: 100%;
  height: 100%;
`

export const SettingsWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 40px 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: "title" "content";
  overflow-y: scroll;
  ${scrollBar};
`

// Icons
const MeIconContainer = styled.article`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  & button{
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`

export const IconContainer = styled(MeIconContainer)`
  grid-area: ${({ gridArea }) => gridArea};

  & button{
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

// Title container
export const TitleContainer = styled.div`
  grid-area: title;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 13% 1fr 13%;
  grid-template-areas: "left-icon title-main right-icon";
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueSemiDark};
`

// Title
export const TitleContent = styled.article`
  grid-area: title-main;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`

export const H3 = styled.h3`
  ${fontStyleTitle};
  font-size: 14px;
  font-weight: 400;
`

// Main container
export const UserSettingsWrapper = styled(Content)`
  grid-area: content;
  display: grid;
  grid-template-rows: 317px 105.6px 105.6px 8%;
  grid-template-columns: 1fr;
  grid-template-areas:  "account"
                        "help"
                        "about"
                        "version";
`

export const SettingContainer = styled.article`
  grid-area: ${({ gridArea }) => gridArea};
  height: 100%;
  width: 85%;
  margin: 0 auto;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueSemiDark};

  ul:last-child {
    border-bottom: none;
  }
`

export const SettingTitleContainer = styled.article`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 35px;
`

export const Title = styled.h3`
  ${fontStyle};
  color: ${({ theme }) => theme.colors.lila};
`

export const SettingSectionContainer = styled.ul`
  width: 100%;
  height: calc(100% - 35px);
`

export const SettingVersionContainer = styled.div`
  grid-area: version;
  display: flex;
  align-items: center;
  justify-content: center;

  & h3{
    ${fontStyle};
    color: ${({ theme }) => theme.colors.gray}
  }
`
export const InvoiceImg = styled.img`
  width:22px;
  height:30px;
  align-self:flex-end;
  cursor:pointer;
  margin-left:45px;
`
