// Icons
import IMasterCard from '../assets/images/iconMaster.png'
import IVisa from '../assets/images/iconVisa.png'
import IDinners from '../assets/images/iconDiners.png'
import IAmex from '../assets/images/iconAmex.png'

export const getIconCreditCard = (typecard) => {
  if (typecard === 'MASTERCARD') {
    return IMasterCard
  } else if (typecard === 'VISA') {
    return IVisa
  } else if (typecard === 'DINERS') {
    return IDinners
  } else if (typecard === 'AMEX') {
    return IAmex
  } else {
    return IVisa
  }
}

export const getDefaultCard = (creditCardArray = []) => {
  if (creditCardArray.length > 0) {
    const defaultCardFiltered = creditCardArray.filter(card => card.default)
    return defaultCardFiltered[0]
  }
  return null
}

export const getCardType = (number) => {
  const re = {
    ELECTRON: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
    MAESTRO: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    DANKORT: /^(5019)\d+$/,
    INTERPAYMENT: /^(636)\d+$/,
    UNIONPAY: /^(62|88)\d+$/,
    VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
    MASTERCARD: /^5[1-5][0-9]{14}$/,
    AMEX: /^3[47][0-9]{13}$/,
    DINERS: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    JCB: /^(?:2131|1800|35\d{3})\d{11}$/
  }

  for (const key in re) {
    if (re[key].test(number)) {
      return key
    }
  }
}
