import React, { useState } from 'react'
import styled from 'styled-components'
import { RadioButtonEarnings } from '../../components/RadioButtonEarnings'
import { Earnings } from '../Earnings'
import { WithDrawal } from '../WithDrawal'

export const MyEarnings = ({ handleChangeViewMembership }) => {
  const [view, setView] = useState('earnings')
  const handleChangeView = (e) => {
    setView(e.target.value)
  }

  return (
      <Container>
        <RadioButtonEarnings
          gridArea='tab'
          selectedRadioButton={view}
          handleSelectRadioButton={handleChangeView}
        />
        <Content>
          {view === 'withdrawal' && <WithDrawal/>}
          {view === 'earnings' && <Earnings handleChangeViewMembership={handleChangeViewMembership}/>}
        </Content>
      </Container>
  )
}

const Container = styled.div`
  background: ${({ theme }) => theme.colors.blueDark};
  width:100%;
  height:100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const Content = styled.div`
  height:100%;
  width:100%;
`
