import { createGlobalStyle } from 'styled-components'
import TitilliumWebSemiBold from '../assets/fonts/TitilliumWeb-SemiBold.ttf'
import TitlingGothicFBNormalMedium from '../assets/fonts/TitlingGothicFBNormalMedium.otf'
import TitlingGothicFBRegular from '../assets/fonts/TitlingGothicFBNormalRegular.otf'
import DeadStockDemo from '../assets/fonts/DeadStock.ttf'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'TitilliumWebSemiBold';
    src: local('TitilliumWebSemiBold'),
    url(${TitilliumWebSemiBold}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'TitlingGothicFBNormalMedium';
    src: local('TitlingGothicFBNormalMedium'),
    url(${TitlingGothicFBNormalMedium}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'TitlingGothicFBRegular';
    src: local('TitlingGothicFBRegular'),
    url(${TitlingGothicFBRegular}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'DeadStockDemo';
    src: local('DeadStockDemo'),
    url(${DeadStockDemo}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  html {
    box-sizing: border-box;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
  }

  *, *:before, *:after {
    font-family: 'TitlingGothicFBRegular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 12px;
    box-sizing: inherit;
  }

  ul, li, h1, h2, h3, p, button { margin: 0; padding: 0; }
  ul { list-style: none; }
  button { background: transparent; border: 0; outline: 0 }

  body {
    height:100%;
    width: 100%;
    margin: 0 auto;
    overscroll-behavior: none;
    width: 100%;
    padding: 0;
    margin: 0;
    background: #020323;
  }

  #app {
    box-shadow: 0 0 10px rgba(0, 0, 0, .05);
    overflow-x: hidden;
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
  }
`
