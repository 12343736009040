import React, { createContext, useReducer, useRef } from 'react'

// Reducers
import { LessonsReducer } from './reducers'

// Actions
export const LessonsStateContext = createContext()
export const LessonsDispatchContext = createContext()

export const LessonsProvider = ({ children }) => {
  const initialState = {
    activeIndex: 0,
    lessons: {
      activeLessons: [],
      mainLessons: [],
      mainFreeLessons: [],
      savedLessons: [],
      followedLessons: [],
      myLessons: [],
      scheduledLessons: [],
      completedLessons: [],
      lessonsByTag: [],
      lessonsFromLessonsByTags: [],
      usersFromLessonsByTags: []
    },
    isLoginModalOpen: false,
    isBookingModalOpen: false,
    isTeacherDetailsModalOpen: false,
    isTagsUpdateModalOpen: false,
    isCreateLessonModalOpen: false,
    isTeachCalendarModalOpen: false,
    isFilterByTagsModalOpen: false,
    isStatisticsModalOpen: false,
    isMyWalletModalOpen: false,
    isIssueModalOpen: false,
    swiperRef: useRef(null),
    loading: true,
    error: null
  }

  const [appState, dispatch] = useReducer(LessonsReducer, initialState)

  return (
    <LessonsStateContext.Provider value={appState}>
      <LessonsDispatchContext.Provider value={dispatch}>
        {children}
      </LessonsDispatchContext.Provider>
    </LessonsStateContext.Provider>
  )
}
