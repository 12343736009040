import styled from 'styled-components'

export const SocialComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 260px;
  color: #fff;
`

export const A = styled.a`
  display: flex;
  padding: 6px;
  color: #fff;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
`
