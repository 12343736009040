import styled from 'styled-components'

export const ButtonComponent = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 1%;
  width: ${({ buttonWidth }) => buttonWidth};
  height: ${({ buttonHeight }) => buttonHeight};
  font-family: ${({ buttonFontFamily }) => buttonFontFamily};
  font-size: ${({ buttonFontSize }) => buttonFontSize};
  color: ${({ buttonActive }) => buttonActive === 'true' ? '#00FEFF' : '#fff'};
  border: none;
  ${({ buttonBorder }) => buttonBorder === 'true' && 'border: 2px solid #00FEFF'};
  ${({ buttonBorder }) => buttonBorder === 'true' && 'box-shadow: 0 0 10px #00FEFF, inset 0 0 10px #00FEFF'};
  border-radius: 6px;
  cursor: pointer;

  /* &:hover {
    border: ${({ buttonBorder }) => buttonBorder === 'false' && '2px solid #00FEFF'}
  } */
`

export const ButtonAlertComponent = styled(ButtonComponent)`
  color: ${({ buttonActive, theme }) => buttonActive === 'true' ? '#E81D80' : '#fff'};
  border: none;
  ${({ buttonBorder }) => buttonBorder === 'true' && 'border: 2px solid #E81D80'};
  ${({ buttonBorder }) => buttonBorder === 'true' && 'box-shadow: 0 0 10px #E81D80, inset 0 0 10px #E81D80'};
`
export const ButtonDisabledComponent = styled(ButtonComponent)`
  color: #3A385B;
  border: none;
  border: 2px solid #3A385B;
  box-shadow: none;
  
`
