import React from 'react'
import { createPortal } from 'react-dom'
import { ModalComponent, DrawMenuWrapper, ContentMenu } from './styles'

export const DrawMenu = ({
  isOpen,
  children,
  backgroundColor = false,
  height = '70%',
  width = '272px',
  opacity = '0.6',
  onCloseModal
}) => {
  return (isOpen
    ? (createPortal(
      <ModalComponent onClick={onCloseModal} opacity={opacity}>
        <DrawMenuWrapper
          height={height}
          width={width}
          onClick={e => e.stopPropagation()}
        >
          <ContentMenu backgroundColor={backgroundColor}>
            {children}
          </ContentMenu>
        </DrawMenuWrapper>
      </ModalComponent>,
      document.getElementById('modal')
      ))
    : null

  )
}
