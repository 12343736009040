import styled, { css } from 'styled-components'

export const fontStyle = css`
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 400;
`

export const WrapperMeSettingsItem = styled.li`
  width: 100%;
  height: 35px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 32px 1fr 32px;
  grid-template-areas: "left text right";
  cursor: pointer;
`

// Icons
export const IconContent = styled.article`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  & button{
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`

export const LeftIconContainer = styled(IconContent)`
  grid-area: left;
`

export const TextContainer = styled.div`
  grid-area: text;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding-left: 8px;

  & h3{
    ${fontStyle};
    color: ${({ alert, theme }) => alert && theme.colors.fucsia}
  }
`
export const RightIconContainer = styled(IconContent)`
  grid-area: right;
`
