import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  ModalContainer,
  ModalArrowLeft,
  TitleContainer,
  Title,
  MainContainer,
  MainMessageContent,
  ButonContent,
  SecoundaryMessageContent
} from './styles'

// Component
import { Modal } from '../../components/Modal'
import { ButtonAlert } from '../../components/Button'

// Hooks
import { useAppContext } from '../../hooks/useAppContext'
import useTranslation from '../../i18n'

export const LogoutModal = ({ isOpen, onCloseModal }) => {
  const { setTokenVerify, setToken, setIsLoggin, setProfile } = useAppContext()
  const history = useHistory()
  const { t } = useTranslation()

  const handleLogOut = () => {
    setTokenVerify('')
    setToken('')
    setIsLoggin(false)
    setProfile({})
    history.push('/')
  }

  return (
    <Modal isOpen={isOpen} onCloseModal={onCloseModal} backgroundColor>
      <ModalArrowLeft height='30px' onClick={onCloseModal} />
      <ModalContainer>
        <TitleContainer>
          <Title><h3>{t('logOut')}</h3></Title>
        </TitleContainer>
        <MainContainer>
          <MainMessageContent>
            <h3>{t('toLogOut')}</h3>
          </MainMessageContent>
          <ButonContent>
            <ButtonAlert
              buttonTitle={t('yesLogOut')}
              buttonActive='true'
              buttonFontSize='17px'
              buttonWidth='190px'
              buttonHeight='50px'
              buttonIconArrowRight='false'
              buttonAlert
              buttonOnClick={handleLogOut}
            />
          </ButonContent>
          <SecoundaryMessageContent>
            <p>{t('notReceiveNotifications')}</p>
          </SecoundaryMessageContent>
        </MainContainer>
      </ModalContainer>
    </Modal>
  )
}
