import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Layout } from '../components/Layout'
import ProtectedRoute from './ProtectedRoute'

const Home = React.lazy(() => import('../pages/Home'))
const HomeLesson = React.lazy(() => import('../pages/HomeLesson'))
const Teach = React.lazy(() => import('../pages/Teach'))
const Session = React.lazy(() => import('../pages/Session'))
const Me = React.lazy(() => import('../pages/Me'))
const NoMatch = React.lazy(() => import('../pages/NoMatch'))
const VideoCall = React.lazy(() => import('../pages/VideoCall'))
const StripeConfirm = React.lazy(() => import('../pages/StripeConfirm'))
const StripeRefresh = React.lazy(() => import('../pages/StripeRefresh'))

export const Routes = () => (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/shared/:id' component={HomeLesson} />
          <Route exact path='/StripeAccount/:id' forceRefresh component={StripeConfirm} />
          <Route exact path='/refreshStripeAccount/:id' forceRefresh component={StripeRefresh} />
          <ProtectedRoute exact path='/teach/:panel?'>
            <Teach/>
          </ProtectedRoute>
          <ProtectedRoute exact path='/me'>
            <Me/>
          </ProtectedRoute>
          <ProtectedRoute exact path='/sessions'>
            <Session/>
          </ProtectedRoute>
          <ProtectedRoute exact path='/videoCall'>
            <VideoCall/>
          </ProtectedRoute>
          <Route component={NoMatch} />
        </Switch>
      </Layout>
    </BrowserRouter>
)
