import { useContext } from 'react'
import { LessonsDispatchContext } from '../context/Lesssons'

export const useLessonsDispatch = () => {
  const context = useContext(LessonsDispatchContext)

  if (context === undefined) {
    throw new Error('useLessonsDispatch must be used within a LessonsProvider')
  }
  return context
}
