import React from 'react'
import { FooterLinkComponent } from './styles'

export const FooterLink = ({ textNavLink, toNavLink }) => {
  return (
    <>
      <FooterLinkComponent exact to={toNavLink}>
        {textNavLink}
      </FooterLinkComponent>
    </>
  )
}
