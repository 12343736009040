import React, { useEffect, useState } from 'react'
import { useLocalStorage } from '../hooks/useLocalStorage'

// Hooks
import useTranslation from '../i18n'

// Services
import { getAccountInfo } from '../services/User'

export const AppContext = React.createContext()

export const AppContextProvider = ({ children }) => {
  const [tokenVerify, setTokenVerify] = useLocalStorage('@TOKEN_VERIFY', '')
  const [token, setToken] = useLocalStorage('@TOKEN', '')
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
  const [isLoggin, setIsLoggin] = useState(false)
  const [reload, setReload] = useState(false)
  const [profile, setProfile] = useState({})
  const [videoCall, setVideoCall] = useState({})
  const middlewareIsLoggin = (functionToExecute) => isLoggin ? functionToExecute() : setIsLoginModalOpen(true)
  const version = '1.0.2'

  // Language
  const { changeLocale } = useTranslation()

  // Inicialice profile
  useEffect(async () => {
    if (token !== '' && tokenVerify !== '') {
      try {
        const res = await getAccountInfo()

        if (res.status === 200) {
          setProfile(res.result)
          if (res.result.fullRecord) {
            if (Object.prototype.hasOwnProperty.call(res.result, 'adv')) {
              changeLocale({ name: res.result.adv.toLowerCase() })
            } else {
              changeLocale({ name: 'en'.toLowerCase() })
            }
            setIsLoggin(true)
          }
        } else if (res.status === 403 || res.status === 500) {
          setToken('')
          setTokenVerify('')
          setIsLoggin(false)
        } else {
          setToken('')
          setTokenVerify('')
          setIsLoggin(false)
        }
      } catch (error) {}
    } else {
      setIsLoggin(false)
    }
  }, [reload])

  return (
    <AppContext.Provider
      value={{
        isLoginModalOpen: isLoginModalOpen,
        setIsLoginModalOpen: setIsLoginModalOpen,
        tokenVerify: tokenVerify,
        setTokenVerify: setTokenVerify,
        token: token,
        setToken: setToken,
        profile,
        setProfile,
        isLoggin,
        setIsLoggin,
        middlewareIsLoggin,
        version: version,
        videoCall: videoCall,
        setVideoCall: setVideoCall,
        reloadProfile: setReload
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
