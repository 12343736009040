import styled from 'styled-components'
import { device } from '../../styles/device'

export const Nav = styled.nav`
  grid-area: 'controls';
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;

  @media ${device.tablet} {
    display:none;
  }
`
