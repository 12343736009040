import React from 'react'
import ReactDOM from 'react-dom'
import {
  ModalComponent,
  ModalContainer,
  ModalArrowLeft
} from './styles'

export const SideRightModal = ({
  isOpen,
  onCloseModal,
  opacity = '0.6',
  children
}) => {
  return (
    isOpen
      ? (
          ReactDOM.createPortal(
            <ModalComponent opacity={opacity} onClick={onCloseModal}>
              <ModalContainer onClick={e => e.stopPropagation()}>
                <ModalArrowLeft height='22px' onClick={onCloseModal} />
                {children}
              </ModalContainer>
            </ModalComponent>,
            document.getElementById('modal')
          ))
      : null
  )
}
