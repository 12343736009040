import styled, { css } from 'styled-components'
import { ArrowLeft } from '../../assets/static/Arrow'

// fontFamilyMedium
// fontFamilyRegular
// fontFamilyBold == fontFamily

export const fontStyleTitle = css`
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
`

export const fontStyleContent = css`
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
`

export const ModalArrowLeft = styled(ArrowLeft)`
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const ContainerModal = styled.section`
  height: 350px;
  width: 550px;
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.backgroundDark};
`

export const ModalContainer = styled(ContainerModal)`
  display: grid;
  grid-template-rows: 14% 86%;
  grid-template-columns: 1fr;
  grid-template-areas:  "title"
                        "content";
`

const ContainerSection = styled.section`
  width: 100%;
  height: 100%;
`

export const TitleContainer = styled(ContainerSection)`
  grid-area: title;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 20% 1fr 20%;
  grid-template-areas: "left-icon title-main right-icon";

  & h3 {
    ${fontStyleTitle};
    font-size: 17px;
    /* border: 1px solid red; */
  }
`

// Title
export const Title = styled.article`
  grid-area: title-main;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`

export const MainContainer = styled(ContainerSection)`
  grid-area: content;
`

// Form
export const FormCard = styled.form`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: 1fr 60px;
  grid-template-columns: 1fr;
  grid-template-areas:  "form-main"
                        "form-button";
`

export const FormDiv = styled.article`
  display: flex;
  justify-content: space-between;
  margin: 14px 0;
  height: 50px;
  width: 100%;
`

export const FormItem = styled(FormDiv)`
  display: grid;
  column-gap: 4px;
  grid-template-rows: 44% 56%;
  grid-template-columns: 28px 1fr 30px;
  grid-template-areas:  "    .     title-item     .    "
                        "icon-item input-item type-item";
  border-bottom: 1px solid ${({ theme }) => theme.colors.fucsia};
`

// Container Items
export const FormMain = styled.div`
  grid-area: form-main;
  margin: 0 auto;
  width: 60%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${FormItem}:first-child {
    margin-top: 0;
  }
`

export const FormItemTitle = styled.div`
  grid-area: title-item;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & h3 {
    ${fontStyleContent};
    font-family: ${({ theme }) => theme.fontFamilyBold};
    color: ${({ theme }) => theme.colors.fucsia};
    font-size: 13px;
  }
`

export const FormItemIcon = styled.div`
  grid-area: icon-item;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FormItemInput = styled.div`
  grid-area: input-item;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & input {
    ${fontStyleContent};
    font-size: 16px;
    border: 0;
    outline: none;
    margin: none;
    height: 100%;
    width: 100%;
    background-color: transparent;
  }

  & input[type=number]::-webkit-inner-spin-button,
  & input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const FormTypeItem = styled.div`
  grid-area: type-item;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & h3 {
    ${fontStyleTitle}
    font-family: ${({ theme }) => theme.fontFamilyBold};
    font-size: 12px;
    color: ${({ theme }) => theme.colors.cyan}
  }
`

export const FormItemExpiration = styled(FormItem)`
  width: 155px;
  display: grid;
  grid-template-rows: 44% 56%;
  grid-template-columns: 28px 1fr;
  grid-template-areas:  "    .     title-item"
                        "icon-item input-item";
`

export const FormItemCVV = styled(FormItem)`
  margin-top: 0;
  width: 95px;
  display: grid;
  grid-template-rows: 44% 56%;
  grid-template-columns: 28px 1fr;
  grid-template-areas:  "    .     title-item"
                        "icon-item input-item";
`

export const FormButton = styled.div`
  grid-area: form-button;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FormDelete = styled.div`
  grid-area: form-main;
  margin: 0 auto;
  width: 60%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & h3{
    ${fontStyleContent}
    font-weight: 400;
    text-align: center;
  }

  & p{
    ${fontStyleContent}
    color: ${({ theme }) => theme.colors.lila};
    font-weight: 400;
    text-align: center;
  }
`

export const ContainerSmallCreditCard = styled.article`
  display: grid;
  height: 35px;
  width: 100px;
  grid-template-rows: 1fr;
  grid-template-columns: 40% 60%;
  grid-template-areas: "delete-card delete-number";
`

export const SmallCreditIcon = styled.div`
  grid-area: delete-card;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SmallCreditNumber = styled.div`
  grid-area: delete-number;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FormUpdate = styled.div`
  grid-area: form-main;
  margin: 0 auto;
  width: 60%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
