import React from 'react'
import { HeaderComponent, UserDisplay } from './styles'
import { NavBar } from '../NavBar'
import { Logo } from '../Logo'
import { Language } from '../Language'
import { Notification } from '../Notification'
import { HeaderUser } from '../HeaderUser'

// Hooks
import { useAppContext } from '../../hooks/useAppContext'

export const Header = () => {
  const { isLoggin, profile } = useAppContext()

  return (
    <HeaderComponent>
      <NavBar />
      <Logo />
      {
        isLoggin &&
          <UserDisplay>
            <Language />
            <Notification />
            <HeaderUser profile={profile} />
          </UserDisplay>
      }
    </HeaderComponent>
  )
}
