import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
// import moment from 'moment'

// Notifications
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// API
// import { ROUTES } from '../../constants'
// import http from '../../services/DataService'
import Success from '../../assets/images/success.png'
import CheckOut from '../../assets/images/checkOut.png'
import Close from '../../assets/images/close-circle-outline.png'
import AngleRight from '../../assets/images/angleRight.png'
import FreeCoin from '../../assets/images/freeCoins.png'
import { Button, ButtonDisabled } from '../Button'
import { useAppContext } from '../../hooks/useAppContext'
import useTranslation from '../../i18n'

// Services
import { getFreeCoins, getClaimCoins } from '../../services/User'

export function FreeCoins () {
  const { profile, setProfile } = useAppContext()
  const [modal, setModal] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    async function upload () {
      await getMyFreeCoins()
    }
    if ((profile.freeCoins === undefined || profile.freeCoins !== 'claimed')) { upload() }
  }, [])

  const getMyFreeCoins = async () => {
    try {
      const res = await getFreeCoins()
      if (res.status === 200) {
        setProfile(res.result)
      }
    } catch (error) {
      if (error.status === 401) {
        toast.error(error.message_en)
      } else if (error.status === 403) {
        toast.error(error.message_en)
      } else {
        toast.error(error.message)
      }
    }
  }

  const handleClaimCoins = async () => {
    try {
      const res = await getClaimCoins()
      if (res.status === 200) {
        setProfile(res.result)
      }
      handleCloseModal()
    } catch (error) {
      handleCloseModal()
      if (error.status === 401) {
        toast.error(error.message_en)
      } else if (error.status === 403) {
        toast.error(error.message_en)
      } else {
        toast.error(error.message)
      }
    }
  }

  const handleOpenModal = () => setModal(true)

  const handleCloseModal = () => setModal(false)

  return (
    <>
      {!modal &&
        <Container>
          <Body>
            <CompleteTheFollowingToReceive>{t('CompleteTheFollowingToReceive')}</CompleteTheFollowingToReceive>
            <PanelItems>
              <IconCheck
                resizeMode='contain' src={profile?.freeCoinsItems?.firstlesson ? Success : CheckOut}
              />
              <TextPoints active={profile?.freeCoinsItems?.firstlesson}>{t('AddYourFirstLesson')}</TextPoints>
              <IconAngle resizeMode='contain' src={AngleRight} />
            </PanelItems>

            <PanelItems>
              <IconCheck
                resizeMode='contain' src={profile?.freeCoinsItems?.firstbooking ? Success : CheckOut}
              />
              <TextPoints active={profile?.freeCoinsItems?.firstbooking}>{t('BookYourFirstLesson')}</TextPoints>
              <IconAngle resizeMode='contain' src={AngleRight} />
            </PanelItems>

            <PanelItems>
              <IconCheck
                resizeMode='contain' src={profile?.freeCoinsItems?.instagram ? Success : CheckOut}
              />
              <TextPoints active={profile?.freeCoinsItems?.instagram}>{t('ConnectYourInstagramAccount')}</TextPoints>
              <IconAngle resizeMode='contain' src={AngleRight} />
            </PanelItems>

            <PanelItems>
              <IconCheck
                resizeMode='contain' src={profile?.freeCoinsItems?.followaccounts ? Success : CheckOut}
              />
              <TextPoints active={profile?.freeCoinsItems?.followaccounts}>{t('FollowAccounts')}</TextPoints>
              <IconAngle resizeMode='contain' src={AngleRight} />
            </PanelItems>

            <PanelItems>
              <IconCheck
                resizeMode='contain' src={profile?.freeCoinsItems?.followtags ? Success : CheckOut}
              />
              <TextPoints active={profile?.freeCoinsItems?.followtags}>{t('FollowTags')}</TextPoints>
              <IconAngle resizeMode='contain' src={AngleRight} />
            </PanelItems>

            <PanelItems>
              <IconCheck
                resizeMode='contain' src={profile?.freeCoinsItems?.firsttree ? Success : CheckOut}
              />
              <TextPoints active={profile?.freeCoinsItems?.firsttree}>{t('CompleteTourFirstTree')}</TextPoints>
              <IconAngle resizeMode='contain' src={AngleRight} />
            </PanelItems>

            <PanelItems>
              <IconCheck
                resizeMode='contain' src={profile?.freeCoinsItems?.shareMeixter ? Success : CheckOut}
              />
              <TextPoints active={profile?.freeCoinsItems?.shareMeixter}>{t('ShareMeiXterWithFriends')}</TextPoints>
              <IconAngle resizeMode='contain' src={AngleRight} />
            </PanelItems>

            {(profile.freeCoins === undefined || profile.freeCoins) !== 'claimed' &&
              <ContainerButtom>
                {profile.freeCoins === 'pending' &&
                  <Button
                    buttonActive='true'
                    buttonTitle={t('ClaimCoins')}
                    buttonWidth='170px'
                    buttonHeight='60px'
                    buttonFontSize='18px'
                    buttonOnClick={handleOpenModal}
                  />}
                {profile.freeCoins !== 'pending' &&
                  <ButtonDisabled
                    buttonTitle={t('ClaimCoins')}
                    buttonWidth='170px'
                    buttonHeight='60px'
                    buttonFontSize='18px'
                    buttonIconArrowRight='false'
                  />}
              </ContainerButtom>}
          </Body>

        </Container>}
      {modal &&
        <ModalBottom>
          <ContainerModal>
            <HeaderModal>
              <Menu />
              <MenuCenter>
                <Title>
                  Congratulations
                </Title>
              </MenuCenter>
              <Menu>
                <div onClick={handleCloseModal}>
                  <IconClose resizeMode='contain' src={Close} />
                </div>
              </Menu>
            </HeaderModal>

            <Body>
              <IconMoney resizeMode='cover' src={FreeCoin} />
              <YouEarnedCoins>You Earned 5 Coins </YouEarnedCoins>
              <YouCanUseYourCoinsInYourNextLesson>You can use your 5 coins in your next lesson.</YouCanUseYourCoinsInYourNextLesson>

              <ContainerButtom>
                <Button
                  buttonActive='true'
                  buttonTitle='Add Coins to my wallet'
                  buttonWidth='170px'
                  buttonHeight='45px'
                  buttonOnClick={handleClaimCoins}
                />
              </ContainerButtom>
            </Body>

          </ContainerModal>
        </ModalBottom>}
    </>
  )
}

const ModalBottom = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.backgroundDark};
  align-items: flex-start;
  z-index: 10;
  border: 2px solid ${({ theme }) => theme.colors.grayLight};
`

const IconMoney = styled.img`
  height: 75px;
  width: 75px;
  align-self: center;
  justify-content: center;
  align-items: center;
`

const YouEarnedCoins = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 18px;
  font-family: ${({ theme }) => theme.fontFamilyMedium};
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  margin-top: 10px;
`

const YouCanUseYourCoinsInYourNextLesson = styled.div`
  color:  ${({ theme }) => theme.colors.white};
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  margin-top: 30px;
`

const PanelItems = styled.div`
  flex-direction: row;
  margin-bottom: 20px;
  display:flex;
  justify-content:space-between;
`

const IconCheck = styled.img`
  height: 25px;
  align-self: center;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
`

const IconAngle = styled.img`
  height: 25px;
  align-self: center;
  justify-content: center;
  align-items: center;
`

const TextPoints = styled.div`
  color: ${({ active, theme }) => active ? theme.colors.white : theme.colors.whiteTrasparent};
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  text-align: left;
  line-height: 20px;
  align-self: center;
  flex: 5;
`

const CompleteTheFollowingToReceive = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  font-family:  ${({ theme }) => theme.fontFamilyRegular};
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  margin-top: 30px;
  width: 200px;
  line-height: 20px;
  margin-bottom: 40px;
`

const ContainerModal = styled.div`
  flex: 1;
  align-items: flex-start;
  display:flex;
  justify-content:center;
  flex-direction:column;
`

const HeaderModal = styled.div`
  height: 40px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display:flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lila};
`

const IconClose = styled.img`
  height: 20px;
  justify-content: center;
  align-items: center;
  cursor:pointer;
`

const ContainerButtom = styled.div`
  width:100%;
  display:flex;
  justify-content:center;
  margin: 4% 0;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: ${({ theme }) => theme.backgroundDark};
`

const Body = styled.div`
  background: ${({ theme }) => theme.backgroundDark};
  flex-direction: column;
  justify-content:center;
  display:flex;
  width: 92%;
`

const Title = styled.div`
  color: #fff;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fontFamilyMedium};
  margin-top: 10px;
`

const Menu = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1;
`

const MenuCenter = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 5;
  height: 40px;
`
