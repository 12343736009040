import styled from 'styled-components'
import { NotificationItemWrapper } from '../NotificationItem/styles'
import { fontStyleTitle, scrollBar } from '../../styles/general'

export const NotificationComponent = styled.div`
  display: grid;
  width: 17%;
  cursor: pointer;
  position: relative;
  top: ${({ isPending }) => isPending ? '8px' : '0px'};

  div {
    width: 16px;
    height: 16px;
    position: relative;
    bottom: 40px;
    left: 23px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.violet};
    text-align: center;
  }


  span {
    position: relative;
    font-size: 10px;
    font-weight: 600;
  }
`

export const ModalContainer = styled.section`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: 40px 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:  "title"
                        "content";
  border: 1px solid ${({ theme }) => theme.colors.blueSemiDark};
  background-color: ${({ theme }) => theme.backgroundDark};
`

// Title container
export const HeaderContainer = styled.div`
  grid-area: title;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 13% 1fr 13%;
  grid-template-areas: "left-icon title-main right-icon";
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueSemiDark};
`

// Title
export const TitleConteiner = styled.article`
  grid-area: title-main;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`

export const H3 = styled.h3`
  ${fontStyleTitle};
  font-size: 14px;
  font-weight: 400;
`

export const List = styled.ul`
  width: 100%;
  height: fit-content;

  & ${NotificationItemWrapper} {
    margin-bottom: 10px;
  }
`

// Main container
export const MainContainer = styled.section`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  ${scrollBar};
`

export const Space = styled.div`
  width: 100%;
  margin-bottom: 16px;
`
