import React from 'react'
import { URI } from '../../constants'
import {
  NotificationItemWrapper,
  AvatarContainer,
  TitleContainer,
  MessageContainer,
  NotificationContainer,
  TimeContainer,
  TextMessage,
  CyanSpan,
  BoldSpan
} from './styles'
import moment from 'moment'

// Components
import { Photo } from '../Photo'

// Avatar Icons
import IFreeCoins from '../../assets/images/freeCoins.png'
import IPayCoins from '../../assets/images/payCoins.png'

// Notification Icons
import INotiComment from '../../assets/images/noti-comment.png'
import INotiMoney from '../../assets/images/noti-money.png'
import INotiSession from '../../assets/images/noti-session.png'
import INotiShare from '../../assets/images/noti-share.png'
import INotiTeach from '../../assets/images/noti-teach.png'
import INotification from '../../assets/images/notification.png'
import INotiRejected from '../../assets/images/close-circle.png'
import INotiApproved from '../../assets/images/success.png'

import useTranslation from '../../i18n'

export const NotificationItem = ({
  notification,
  handleChangeView,
  onCloseModal
}) => {
  const { t } = useTranslation()

  const getTitleInNotifications = (type) => {
    switch (type) {
      case 'TEACH_UPCOMING':
        return t('NewLessonSale')
      case 'COMMENT_LESSON':
        return t('NewComment')
      case 'USER_SESSION_CANCELED':
      case 'TEACHER_SESSION_CANCELED':
        return t('SessionCancelled')
      case 'TEACH_LESSON_START':
      case 'SESSION_LESSON_START':
        return t('LessonReminder')
      case 'INCOME_REFERRAL':
        return t('NewIncomePerReferral')
      case 'INCOME_LESSON':
        return t('NewIncomePerLesson')
      case 'SHARE_LESSON':
        return t('SharedWithYou')
      case 'WITHDRAWAL_COMPLETED':
        return t('WithdrawalRequestCompleted')
      case 'STRIPE_ACCOUNT':
        return t('ConfirmedStripeAccount')
      case 'MEMBERSHIP_RENOVED':
        return t('MembershipRenoved')
      case 'LESSON_FEEDBACK':
        return t('LessonFeedback')
      case 'RECORDINS_ AVAILABLE':
        return t('RecordingsAvailable')
      default:
        return ''
    }
  }

  const getTitleLessonsStatus = (noti) => {
    switch (noti.data.lesson[0].status) {
      case 'rejected':
        return noti.title
      case 'approved':
        return noti.title
      default:
        return noti.title
    }
  }

  const getWithPurple = (item) => {
    switch (item.type) {
      case 'INCOME_LESSON':
      case 'INCOME_REFERRAL':
      case 'MEMBERSHIP_RENOVED':
      case 'WITHDRAWAL_COMPLETED':
      case 'STRIPE_ACCOUNT':
      case 'LESSON_STATUS':
      case 'LESSON_FEEDBACK':
        return false
      default:
        return true
    }
  }

  const getAvatarInNotifications = (item) => {
    switch (item.type) {
      case 'INCOME_LESSON':
      case 'INCOME_REFERRAL':
      case 'MEMBERSHIP_RENOVED':
        return IFreeCoins
      case 'WITHDRAWAL_COMPLETED':
      case 'STRIPE_ACCOUNT':
        return IPayCoins
      case 'LESSON_FEEDBACK':
        return INotiRejected
      case 'RECORDINS_ AVAILABLE':
        return INotiSession
      default:
        return `${URI}/${item.data.image}`
    }
  }

  const getIconNotifications = (type) => {
    switch (type) {
      case 'rejected':
        return INotiRejected
      case 'approved':
        return INotiApproved
      default:
        return INotiApproved
    }
  }

  const getTypeOfNotification = (type) => {
    switch (type) {
      case 'SHARE_LESSON':
        return INotiShare
      case 'COMMENT_LESSON':
      case 'LESSON_FEEDBACK':
        return INotiComment
      case 'USER_SESSION_CANCELED':
      case 'TEACHER_SESSION_CANCELED':
        return INotiTeach
      case 'TEACH_UPCOMING':
      case 'TEACH_LESSON_START':
      case 'SESSION_LESSON_START':
      case 'LESSON_STATUS':
      case 'RECORDINS_ AVAILABLE':
        return INotiSession
      case 'INCOME_REFERRAL':
      case 'INCOME_LESSON':
      case 'WITHDRAWAL_COMPLETED':
      case 'STRIPE_ACCOUNT':
      case 'MEMBERSHIP_RENOVED':
        return INotiMoney
      default:
        break
    }
  }

  const getMessageByType = (item) => {
    switch (item.type) {
      case 'TEACH_UPCOMING':
        return (
          <TextMessage>
            <CyanSpan>@{item.data.nickName}</CyanSpan>
            {`${t('hasPurchasedA')} ${item.data.duration} ${t('LessonOn')}
            ${moment(item.data.date).format('MMM')}
            ${moment(item.data.date).format('DD')} ${t('At')}
            ${moment(item.data.date).format('hh:mm a')}: `}
            <th />
            <BoldSpan>{item.data.lesson}</BoldSpan>
          </TextMessage>
        )
      case 'COMMENT_LESSON':
        return (
          <TextMessage>
            <CyanSpan>@{item.data.nickName}</CyanSpan>
            {` ${t('HasCommentedYourLesson')}: `}
            <th />
            <BoldSpan>{item.data.lesson}</BoldSpan>
          </TextMessage>
        )
      case 'USER_SESSION_CANCELED':
        return (
          <TextMessage>
            <CyanSpan>@{item.data.nickName}</CyanSpan>
            {` ${t('hasCancelledYourSession')}: `}
            <th />
            <BoldSpan>{item.data.lesson}</BoldSpan>
          </TextMessage>
        )
      case 'TEACHER_SESSION_CANCELED':
        return (
          <TextMessage>
            <CyanSpan>@{item.data.nickName}</CyanSpan>
            {` ${t('hasCancelledYourSession')}: `}
            <th />
            <BoldSpan>{item.data.lesson}</BoldSpan>
          </TextMessage>
        )
      case 'TEACH_LESSON_START':
      case 'SESSION_LESSON_START':
        return (
          <TextMessage>
            {`${t('YourLessonWith')}`}
            <CyanSpan>@{item.data.nickName}</CyanSpan>
            {` ${t('ItIsAboutToStart')}: `}
            <BoldSpan>{item.data.lesson}</BoldSpan>
          </TextMessage>
        )
      case 'INCOME_LESSON':
      case 'INCOME_REFERRAL':
        return (
          <TextMessage>
            {`${t('ATotalOf')}: `}
            <CyanSpan>{item.data.coins}</CyanSpan>
            {` COINS ${t('HasBeenCredited')} `}
            <CyanSpan>@{item.data.nickName}</CyanSpan>
            {` ${t('Lesson')}. `}
          </TextMessage>
        )
      case 'SHARE_LESSON':
        return (
          <TextMessage>
            <CyanSpan>@{item.data.nickName}</CyanSpan>
            {` ${t('HasSharedWithYouTheLesson')}: `}
            <th />
            <BoldSpan>{item.data.lesson}</BoldSpan>
          </TextMessage>
        )
      case 'WITHDRAWAL_COMPLETED':
        return (
          <TextMessage>
            {`${t('YourWithdrawalRequestby')} `}
            <CyanSpan>{item.data?.usd} £</CyanSpan>
            {` ${t('WasCompletedSuccessfuly')} `}
          </TextMessage>
        )
      case 'STRIPE_ACCOUNT':
        return (
          <TextMessage>{t('YourStripeAccountWasConfirmedSuccessfuly')}.</TextMessage>
        )
      case 'MEMBERSHIP_RENOVED':
        return (
          <TextMessage>{t('YourMembershipWasRenovedSuccessfuly')}.</TextMessage>
        )
      case 'LESSON_STATUS':
      case 'LESSON_FEEDBACK':
      case 'RECORDINS_ AVAILABLE':
        return (<TextMessage>{item.body}</TextMessage>)
      default:
        break
    }
  }

  const handleRedirect = (type) => {
    // console.log(type)
    switch (type) {
      case 'SHARE_LESSON':
        handleChangeView('share', notification._id, notification.data.data[0]._id)
        break
      case 'WITHDRAWAL_COMPLETED':
      case 'STRIPE_ACCOUNT':
        handleChangeView('withdrawall', notification._id)
        break
      case 'INCOME_LESSON':
        handleChangeView('earnings', notification._id)
        break
      case 'MEMBERSHIP_RENOVED':
        handleChangeView('wallet', notification._id)
        break
      case 'TEACH_UPCOMING':
        handleChangeView('upcoming', notification._id)
        break
      case 'TEACH_LESSON_START':
        handleChangeView('completed', notification._id)
        break
      case 'TEACHER_SESSION_CANCELED':
        handleChangeView('completed', notification._id)
        break
      case 'LESSON_STATUS':
      case 'LESSON_FEEDBACK':
        handleChangeView('lesson', notification._id)
        break
      case 'COMMENT_LESSON':
        handleChangeView('share', notification._id, notification.data.id)
        break
      case 'SESSION_LESSON_START':
        handleChangeView('session', notification._id)
        break
      case 'USER_SESSION_CANCELED':
        handleChangeView('session', notification._id)
        break
      default:
        break
    }
  }

  return (
    <NotificationItemWrapper onClick={() => handleRedirect(notification.type)}>
      <AvatarContainer>
        {
          getWithPurple(notification)
            ? <Photo
              photoImage={notification.type === 'LESSON_STATUS' ? getIconNotifications(notification.data.lesson[0].status) : getAvatarInNotifications(notification)}
              photoSize='32px'
            />
            : <Photo
            withPurple={false}
            photoImage={notification.type === 'LESSON_STATUS' ? getIconNotifications(notification.data.lesson[0].status) : getAvatarInNotifications(notification)}
            photoSize='32px'
          />
        }
      </AvatarContainer>
      <TitleContainer>
        {notification.type === 'LESSON_STATUS' ? getTitleLessonsStatus(notification) : getTitleInNotifications(notification.type)}
      </TitleContainer>
      <MessageContainer>
        {getMessageByType(notification)}
      </MessageContainer>
      <NotificationContainer>
        {
          !notification.view &&
            <img
              src={INotification}
              alt='active'
              width='14px'
            />
        }
      </NotificationContainer>
      <TimeContainer>
        <img
          src={getTypeOfNotification(notification.type)}
          alt='type-notification'
        />
        <p>{moment(notification.createdAt).fromNow()}</p>
      </TimeContainer>
    </NotificationItemWrapper>
  )
}
