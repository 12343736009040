import React from 'react'
import {
  WrapperMeSettingsItem,
  LeftIconContainer,
  RightIconContainer,
  TextContainer
} from './styles'

import { ArrowDown } from '../../assets/static/ArrowDown'

export const MeSettingsItem = ({ icon, iconWidth, text, alert = false, handleChangeView }) => {
  return (
    <WrapperMeSettingsItem onClick={handleChangeView}>
      <LeftIconContainer>
        <button>
          <img src={icon} width={iconWidth} />
        </button>
      </LeftIconContainer>
      <TextContainer alert={alert}><h3>{text}</h3></TextContainer>
      <RightIconContainer>
        <button>
          <ArrowDown width='22px' rotate='rotate(-90)' />
        </button>
      </RightIconContainer>
    </WrapperMeSettingsItem>
  )
}
