import React from 'react'
import { Tools, Input, Label } from './styles'

export const RadioButtonEarnings = ({
  gridArea,
  selectedRadioButton,
  handleSelectRadioButton
}) => (
  <Tools gridArea={gridArea}>

    <Label active={selectedRadioButton === 'earnings'} htmlFor='earnings'>Earnings
      <Input
        type='radio'
        value='earnings'
        id='earnings' name='earnings'
        checked={selectedRadioButton === 'earnings'}
        onChange={handleSelectRadioButton}
      />
    </Label>
    <Label active={selectedRadioButton === 'withdrawal'} htmlFor='withdrawal'>Withdrawal
      <Input
        type='radio'
        value='withdrawal'
        id='withdrawal' name='withdrawal'
        checked={selectedRadioButton === 'withdrawal'}
        onChange={handleSelectRadioButton}
      />
    </Label>
  </Tools>
)
