
import React from 'react'
import {
  LayoutWrapper,
  HeaderContainer,
  MainContainter,
  FooterContainer,
  Background
} from './styles'

// Components
import { Header } from '../Header'
import { Footer } from '../Footer'

// Video
import video from '../../public/background_Web.mp4'

export const Layout = ({ children }) => {
  return (
    <LayoutWrapper>
      <HeaderContainer>
        <Header />
      </HeaderContainer>
      <MainContainter className='container'>
        <Background loop autoPlay muted>
          <source src={video} type='video/mp4' />
        </Background>
        {children}
      </MainContainter>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </LayoutWrapper>
  )
}
