import styled, { css } from 'styled-components'
import { device } from '../../styles/device'

export const generalGrid = css`
  width: 100%;
  height: 100%;
`

export const LayoutWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 56px 1fr 25px;
  grid-template-columns: 1fr;
  grid-template-areas:  "header"
                        "main"
                        "footer";

  @media ${device.tablet} {
    grid-template-rows: 56px 1fr;
    grid-template-areas:  "header"
                          "main";
  }
`

export const HeaderContainer = styled.div`
  grid-area: header;
  ${generalGrid};
`

export const MainContainter = styled.div`
  position: relative;
  grid-area: main;
  ${generalGrid}
  background-color: #0A0B25;
`

export const Background = styled.video`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  height: calc(100vh - 80px);
  /* width: 77%; */

  @media ${device.tablet} {
    width: auto;
    height: 100%;
  }
`
export const FooterContainer = styled.div`
  grid-area: footer;
  ${generalGrid};

  @media ${device.tablet} {
    display: none;
  }
`
