import { useEffect, useState } from 'react'
import http from '../services/DataService'
import { ENDPOINTS } from '../constants'

import { useAppContext } from './useAppContext'

export const useInvoice = (reload) => {
  const { isLoggin } = useAppContext()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [inv, setInv] = useState([])

  useEffect(async () => {
    if (isLoggin) {
      setError(false)
      setLoading(true)

      try {
        const res = await http.get(ENDPOINTS.INVOICES)
        if (res.status === 200) {
          setInv(res.result)
        } else {
          setError(res.message_en)
        }
      } catch (error) {
        setError(error)
      }
      setLoading(false)
    }
  }, [reload])

  return { inv, loading, error }
}
