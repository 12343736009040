import axios from 'axios'
import { ROUTES } from '../constants'

export const verify = async (data) => {
  try {
    const res = await axios.post(ROUTES.VERIFY, data)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}

export const setAuthorization = (token) => {
  axios.defaults.headers.common.Authorization = token
}

export const login = async (data) => {
  try {
    const res = await axios.post(ROUTES.LOGIN, data)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}

export async function account () {
  try {
    const res = await axios.get(ROUTES.ACCOUNT)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}

export async function accountProfile (id) {
  try {
    const res = await axios.get(ROUTES.ACCOUNT_PROFILE, {
      params: {
        idUser: id
      }
    })
    return res.data
  } catch (e) {
    throw handler(e)
  }
}

export function deleteAuthorization () {
  delete axios.defaults.headers.common.Authorization
}

export async function followTeach (data) {
  try {
    const res = await axios.post(ROUTES.FOLLOW_TEACH, data)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}

export async function getLocales () {
  try {
    const res = await axios.get(ROUTES.ALL_LOCALE)
    return res.data
  } catch (e) {
    throw handler(e)
  }
}

export function handler (err) {
  console.log('err', err)
  if (err.response && err.response.data) {
    return err.response.data
  }
  return { message: err.Error }
}
