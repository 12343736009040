import http, { handler } from '../services/DataService'
import { ENDPOINTS, URI } from '../constants'

export const getFeedbackLesson = async (id) => {
  try {
    const res = await http.get(ENDPOINTS.LESSON_FEEDBACK, {
      id
    })

    return res
  } catch (e) {
    throw handler(e)
  }
}

export async function fiveFeedback (id, comment) {
  try {
    const res = await http.create(ENDPOINTS.LESSON_FEEDBACK, {
      id, comment
    })
    return res
  } catch (e) {
    throw handler(e)
  }
}

export async function getALessons (id) {
  try {
    const res = await http.get(ENDPOINTS.A_LESSON, {
      id
    })
    let list = []
    if (res.status === 200) {
      list = res.result.map(item => {
        return {
          ...item,
          cover: `${URI}/${item.cover}`,
          video: `${URI}/${item.video}`,
          tacherUrlProfile: `${URI}/${item.tacherUrlProfile}`
        }
      })
    }
    return {
      ...res,
      result: list
    }
  } catch (e) {
    throw handler(e)
  }
}

export async function shareWithMeixter (idLesson, idUser) {
  try {
    const res = await http.create(ENDPOINTS.SHARED_P2P,
      {
        idLesson,
        idUser
      }
    )
    return res
  } catch (e) {
    throw handler(e)
  }
}

export async function getAllLessons (lang) {
  try {
    const res = await http.getAll(ENDPOINTS.ALL_LESSON,
      {
        lang,
        search: ''
      }
    )

    let handleResult = []

    if (res.status === 200) {
      handleResult = handleDataLessons(res.result)
    }

    return {
      ...res,
      result: handleResult
    }
  } catch (e) {
    throw handler(e)
  }
}

export async function getAllFreeLessons () {
  try {
    const res = await http.getAll(ENDPOINTS.LESSON_FREE,
      {
        lang: '5f75f04bf362c0d181a8a207',
        search: ''
      }
    )

    let handleResult = []

    if (res.status === 200) {
      handleResult = handleDataLessons(res.result)
    }

    return {
      ...res,
      result: handleResult
    }
  } catch (e) {
    throw handler(e)
  }
}

export async function getAllFollowedLessons () {
  try {
    const res = await http.getAll(ENDPOINTS.FOLLOWING_LESSON)

    let handleResult = []

    if (res.status === 200) {
      handleResult = handleDataLessons(res.result)
    }

    return {
      ...res,
      result: handleResult
    }
  } catch (e) {
    throw handler(e)
  }
}

export async function getAllSavedLessons () {
  try {
    const res = await http.getAll(ENDPOINTS.SAVED_LESSON)

    let handleResult = []

    if (res.status === 200) {
      handleResult = handleDataLessons(res.result)
    }

    return {
      ...res,
      result: handleResult
    }
  } catch (e) {
    throw handler(e)
  }
}

export async function getAllTagsLesson () {
  try {
    const res = await http.getAll(ENDPOINTS.TAGS_LESSON)
    let list = []
    if (res.status === 200) {
      list = res.result.filter(item => item.lessons.length > 0).map(
        item => {
          return {
            ...item,
            lessons: handleDataLessons(item.lessons)
          }
        }
      ).sort((a, b) => {
        const textA = a?.lessons[0]?.tags[0].name?.toLowerCase()
        const textB = b?.lessons[0]?.tags[0].name?.toLowerCase()
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
      })
    }

    return {
      ...res,
      result: list
    }
  } catch (e) {
    throw handler(e)
  }
}

export const getAllMyLessons = async () => {
  try {
    const res = await http.getAll(ENDPOINTS.LIST_LESSON)

    if (res.status >= 200 && res.status < 300) {
      res.result = res.result.map(item => {
        return {
          ...item,
          video: `${URI}/${item.video}`,
          cover: `${URI}/${item.cover}?time${Date.now()}`,
          tacherUrlProfile: `${URI}/${item.tacherUrlProfile}`,
          volume: parseFloat((parseInt(item.volume) / 100).toFixed(1))
        }
      })
    }

    return res
  } catch (e) {
    throw handler(e)
  }
}

export const getAllMyLessonsStatistics = async () => {
  try {
    const res = await http.getAll(ENDPOINTS.LIST_LESSON)

    if (res.status >= 200 && res.status < 300) {
      res.result = res.result.map(item => {
        return {
          _id: item._id,
          description: item.description
        }
      })
    }

    return res
  } catch (e) {
    throw handler(e)
  }
}

export const getAllTeacherSessions = async () => {
  try {
    const res = await http.getAll(ENDPOINTS.TEACHER_SESSIONS, {
      status: 'upcoming'
    })

    if (res.status >= 200 && res.status < 300) {
      const now = Date.now()
      res.result = res.result.map(item => {
        return {
          ...item,
          userImage: `${URI}/${item.userImage}?time${now}`,
          lessonCover: `${URI}/${item.lessonCover}?time${now}`
        }
      })
    }

    return res
  } catch (e) {
    throw handler(e)
  }
}

export const getAllCompletedLessons = async () => {
  try {
    const res = await http.getAll(ENDPOINTS.TEACHER_SESSIONS, {
      status: 'finished'
    })

    if (res.status >= 200 && res.status < 300) {
      const now = Date.now()
      res.result = res.result.map(item => {
        return {
          ...item,
          userImage: `${URI}/${item.userImage}?time${now}`,
          lessonCover: `${URI}/${item.lessonCover}?time${now}`
        }
      })
    }

    return res
  } catch (e) {
    throw handler(e)
  }
}

export const getLessonsFromLessonByTags = (tagLessons = []) => {
  return new Promise((resolve, reject) => {
    try {
      const res = tagLessons.map(item => item.lessons).reduce((acc, value) => acc.concat(value), []).map(item => {
        return {
          ...item,
          minutes: item.thirtymin ? '30min' : '60min',
          priceCoins: item.thirtymin ? item.thirtymincoins : item.sixtymincoins
        }
      })
      resolve(res)
    } catch (error) {
      reject(error)
    }
  })
}

export const getUniqueUsersFromLessons = (lessons = []) => {
  return new Promise((resolve, reject) => {
    try {
      const uniqueUsers = lessons.map(item => {
        return {
          tacherId: item.tacherId,
          tacherFullName: item.tacherFullName,
          tacherUrlProfile: item.tacherUrlProfile,
          tacherNickname: item.tacherNickname
        }
      }).reduce((acc, value) => {
        if (!acc.has(value.tacherId)) {
          acc.set(value.tacherId, value)
        }

        return acc
      }, new Map())

      resolve(Array.from(uniqueUsers.values()))
    } catch (error) {
      reject(error)
    }
  })
}

export async function followTag (idTag) {
  try {
    const res = await http.create(ENDPOINTS.TAG_FOLLOW, {
      idTag
    })

    return res
  } catch (e) {
    throw handler(e)
  }
}

export const handleUpdateSave = async (id, isSaved) => {
  try {
    let res
    if (!isSaved) {
      res = await http.create(ENDPOINTS.SAVE_LESSON, { id })
    } else {
      res = await http.update(ENDPOINTS.SAVE_LESSON, { id })
    }

    return res
  } catch (e) {
    throw handler(e)
  }
}

export const validateReferalCode = async (code) => {
  try {
    const res = await http.getAll(ENDPOINTS.VALIDATE_CODE_REF, { code })
    return res
  } catch (e) {
    throw handler(e)
  }
}

export function handleDataLessons (res) {
  let list = []

  if (res.length > 0) {
    list = res.filter(item => item.offer).map(item => {
      return {
        ...item,
        video: `${URI}/${item.video}`,
        cover: `${URI}/${item.cover}`,
        tacherUrlProfile: `${URI}/${item.tacherUrlProfile}`,
        volume: parseFloat((parseInt(item.volume) / 100).toFixed(1)),
        volumeVideo: parseFloat(((100 - parseInt(item.volume)) / 100).toFixed(1)),
        priceCoins: item.thirtymin ? item.thirtymincoins : item.sixtymincoins
      }
    })
  }
  return list
}
