import React from 'react'
import {
  ContainerSubscription,
  IconContent,
  TextContent,
  TextButtonTitle,
  TextButtonSubTitle
} from './styles'

// Container
import ICheck from '../../assets/images/check.png'

// Hooks
import useTranslation from '../../i18n'

export const BuyMembership = ({
  onClickButton
}) => {
  const { t } = useTranslation()

  return (
    <ContainerSubscription onClick={onClickButton}>
      <IconContent>
        <img src={ICheck} width='28px' />
      </IconContent>
      <TextContent>
        <TextButtonTitle>{t('YouDontHaveAMembershipYet')}</TextButtonTitle>
        <TextButtonSubTitle>{t('ReceiveOfYourLessonRevenue')}</TextButtonSubTitle>
      </TextContent>
    </ContainerSubscription>
  )
}
