import React from 'react'

export const Close = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={29.25}
    height={29.25}
    {...props}
  >
    <defs>
      <style>{'.prefix__close{fill:#fff}'}</style>
    </defs>
    <path
      className='prefix__close'
      d='M19.92 18.33l-3.706-3.705 3.705-3.705a1.124 1.124 0 00-1.589-1.589l-3.705 3.705-3.705-3.705a1.124 1.124 0 00-1.589 1.589l3.705 3.705-3.705 3.705a1.086 1.086 0 000 1.589 1.116 1.116 0 001.589 0l3.705-3.705 3.705 3.705a1.129 1.129 0 001.589 0 1.116 1.116 0 00.001-1.589z'
    />
    <path
      className='prefix__close'
      d='M14.625 1.969a12.651 12.651 0 11-8.951 3.705 12.573 12.573 0 018.951-3.705m0-1.969A14.625 14.625 0 1029.25 14.625 14.623 14.623 0 0014.625 0z'
    />
  </svg>
)
