import React, { useState } from 'react'
import {
  SettingsWrapper,
  TitleContainer,
  TitleContent,
  H3,
  IconContainer,
  UserSettingsWrapper,
  SettingContainer,
  SettingTitleContainer,
  Title,
  SettingSectionContainer,
  SettingVersionContainer,
  InvoiceImg
} from './styles'

// Icons
import { ArrowLeft } from '../../assets/static/Arrow'
import IFreeCoins from '../../assets/images/freeCoins.png'
import IManageAccount from '../../assets/images/manageAccount.png'
import IPreferences from '../../assets/images/preferences.png'
import IWallet from '../../assets/images/wallet.png'
import IPaymentMethods from '../../assets/images/paymentMethods.png'
import IEraning from '../../assets/images/eraning.png'
import IReferralProgram from '../../assets/images/manageNickname.png'
import IMyTrees from '../../assets/images/myTrees.png'
import IReportProblem from '../../assets/images/reportProblem.png'
import IHelpDesk from '../../assets/images/helpDesk.png'
import IPolices from '../../assets/images/policies.png'
import ILogOut from '../../assets/images/logout.png'
import InvoicesPng from '../../assets/images/invoices.png'

// Container
import { LogoutModal } from '../../container/LogoutModal'
import { ReportIssueModal } from '../../container/ReportIssueModal'

// Components
import { MeSettingsItem } from '../../components/MeSettingsItem'
import { CreditCard } from '../CreditCard'
import { MyWallet } from '../MyWallet'
import { FreeCoins } from '../../components/FreeCoins'
import { MyTree } from '../../components/MyTree'
import { ReferralProgram } from '../../components/ReferralProgram'
import { Account } from '../../components/Account'
import { MoreOptions } from '../../components/MoreOptions'
import { MyEarnings } from '../../components/MyEarnings'
import { Preferences } from '../../components/Preferences'
import { Invoices } from '../../components/Invoices'

// Hooks
import { useAppContext } from '../../hooks/useAppContext'

import useTranslation from '../../i18n'

export const UserSettings = ({
  onClose,
  handleReloadAccount
}) => {
  // Here we handle the views for each seatting
  // freecoins monedasgratis
  // manageaccount
  // preferences
  // mywallet
  // paymentmethods
  // myearnings
  // mytrees
  // referralprogram
  // reportaproblem
  // helpdesk
  // privacypolices
  // logout
  // menu
  const { version } = useAppContext()
  const [view, setView] = useState('menu')
  const [isOpenLogoutModal, setIsOpenLogoutModal] = useState(false)
  const [isOpenReportModal, setIsOpenReportModal] = useState(false)
  const { t } = useTranslation()

  const handleChangeView = (to) => {
    setView(to)
  }

  // Logout Modal
  const handleCloseLogoutModal = () => {
    setIsOpenLogoutModal(false)
  }

  const handleOpenLogoutModal = () => {
    setIsOpenLogoutModal(true)
  }

  // Report Modal
  const handleOpenReportModal = () => {
    setIsOpenReportModal(true)
  }

  const handleCloseReportModal = () => {
    setIsOpenReportModal(false)
  }

  return (
    <SettingsWrapper>
      <TitleContainer>
        <IconContainer gridArea='left-icon'>
          {
            view === 'menu'
              ? <>
                <button onClick={onClose}>
                  <ArrowLeft width='22px' />
                </button>
                </>
              : view === 'moreoptions'
                ? <>
                  <button onClick={() => handleChangeView('manageaccount')}>
                    <ArrowLeft width='22px' />
                  </button>
                </>
                : view === 'invoices'
                  ? <>
                  <button onClick={() => handleChangeView('mywallet')}>
                    <ArrowLeft width='22px' />
                  </button>
                </>
                  : <>
                  <button onClick={() => handleChangeView('menu')}>
                    <ArrowLeft width='22px' />
                  </button>
                </>
          }
        </IconContainer>
        <TitleContent>
          {view === 'menu' && <H3>{t('settings')}</H3>}
          {view === 'freecoins' && <H3>{t('freeCoins')}</H3>}
          {view === 'manageaccount' && <H3>{t('manageAccount')}</H3>}
          {view === 'preferences' && <H3>{t('preferences')}</H3>}
          {view === 'mywallet' &&
             <>
             <H3>{t('myWallet')}</H3>
             <InvoiceImg src={InvoicesPng} onClick={() => handleChangeView('invoices')}/>
             </>
          }
          {view === 'paymentmethods' && <H3>{t('paymentMethods')}</H3>}
          {view === 'myearnings' && <H3>{t('myEarnings')}</H3>}
          {view === 'mytrees' && <H3>{t('myTrees')}</H3>}
          {view === 'referralprogram' && <H3>{t('ReferralProgram')}</H3>}
          {view === 'invoices' && <H3>{'Invoices'}</H3>}
        </TitleContent>
      </TitleContainer>
      {
        view === 'menu' &&
          <UserSettingsWrapper>
            <SettingContainer gridArea='account'>
              <SettingTitleContainer>
                <Title>{t('account')}</Title>
              </SettingTitleContainer>
              <SettingSectionContainer>
                <MeSettingsItem
                  icon={IFreeCoins}
                  iconWidth='28px'
                  text={t('freeCoins')}
                  handleChangeView={() => handleChangeView('freecoins')}
                />
                <MeSettingsItem
                  icon={IManageAccount}
                  iconWidth='21px'
                  text={t('manageAccount')}
                  handleChangeView={() => handleChangeView('manageaccount')}
                />
                <MeSettingsItem
                  icon={IPreferences}
                  iconWidth='21px'
                  text={t('preferences')}
                  handleChangeView={() => handleChangeView('preferences')}
                />
                <MeSettingsItem
                  icon={IWallet}
                  iconWidth='21px'
                  text={t('myWallet')}
                  handleChangeView={() => handleChangeView('mywallet')}
                />
                <MeSettingsItem
                  icon={IPaymentMethods}
                  iconWidth='21px'
                  text={t('paymentMethods')}
                  handleChangeView={() => handleChangeView('paymentmethods')}
                />
                <MeSettingsItem
                  icon={IEraning}
                  iconWidth='21px'
                  text={t('myEarnings')}
                  handleChangeView={() => handleChangeView('myearnings')}
                />
                <MeSettingsItem
                  icon={IMyTrees}
                  iconWidth='21px'
                  text={t('myTrees')}
                  handleChangeView={() => handleChangeView('mytrees')}
                />
                <MeSettingsItem
                  icon={IReferralProgram}
                  iconWidth='22px'
                  text={t('ReferralProgram')}
                  handleChangeView={() => handleChangeView('referralprogram')}
                />
              </SettingSectionContainer>
            </SettingContainer>
            <SettingContainer gridArea='help'>
              <SettingTitleContainer>
                <Title>{t('help')}</Title>
              </SettingTitleContainer>
              <SettingSectionContainer>
                <MeSettingsItem
                  icon={IReportProblem}
                  iconWidth='21px'
                  text={t('reportProblem')}
                  handleChangeView={() => handleOpenReportModal('reportaproblem')}
                />
                <a target='blank' href='https://meixter.com/faqs/'>
                  <MeSettingsItem
                    icon={IHelpDesk}
                    iconWidth='21px'
                    text={t('helpDesk')}
                  />
                </a>
              </SettingSectionContainer>
            </SettingContainer>
            <SettingContainer gridArea='about'>
              <SettingTitleContainer>
                <Title>{t('about')}</Title>
              </SettingTitleContainer>
              <SettingSectionContainer>
                <a target='blank' href='https://meixter.com/privacy'>
                  <MeSettingsItem
                    icon={IPolices}
                    iconWidth='15px'
                    text={t('privacyPolicies')}
                  />
                </a>
                <MeSettingsItem
                  icon={ILogOut}
                  iconWidth='20px'
                  text={t('logOut')}
                  handleChangeView={handleOpenLogoutModal}
                  alert
                />
              </SettingSectionContainer>
            </SettingContainer>
            <SettingVersionContainer>
              <h3>{t('version')} {version}</h3>
            </SettingVersionContainer>
          </UserSettingsWrapper>
      }
      {
        view === 'freecoins' && <FreeCoins />
      }
      {
        view === 'manageaccount' &&
          <Account
            handleChangeWithDrawal={() => handleChangeView('moreoptions')}
            handleReload={handleReloadAccount}
          />
      }
      {
        view === 'preferences' && <Preferences />
      }
      {
        view === 'mywallet' &&
          <MyWallet
            handleChangeWithDrawal={() => handleChangeView('myearnings')}
          />
      }
      {
        view === 'invoices' &&
          <Invoices/>
      }
      {
        view === 'paymentmethods' && <CreditCard />
      }
      {
        view === 'myearnings' &&
          <MyEarnings
            handleChangeViewMembership={() => handleChangeView('mywallet')}
          />
      }
      {
        view === 'mytrees' && <MyTree />
      }
      {
        view === 'referralprogram' && <ReferralProgram />
      }
      {
        view === 'moreoptions' && <MoreOptions handleReload={handleReloadAccount} />
      }
      <LogoutModal isOpen={isOpenLogoutModal} onCloseModal={handleCloseLogoutModal} />
      <ReportIssueModal isOpen={isOpenReportModal} onCloseModal={handleCloseReportModal} />
    </SettingsWrapper>
  )
}
