import React from 'react'

export const ArrowRight = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 27 21'
    {...props}
  >
    <image
      overflow='visible'
      opacity={0.4}
      width={27}
      height={21}
      xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAVCAYAAAC33pUlAAAACXBIWXMAAAsSAAALEgHS3X78AAAA GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAZ1JREFUeNrUlutLwlAYxt3puMIo oxtmWRB9DCqoPveX1/ek6/eCblaMgoSMttzpeecznEthFxk0+LlNt/PsvT3TKuXZfGPxSPYmOFKW GXW5lUNI4VODKTABuuAbeBD0xyfWi6gMFkADVEEHtIADviDYjd+mcyRRIloHR2ATtMEFOAePeKA/ glnFLKZulkIHrNki15TfH+KCWcUMa9RhRHJeA3asNAOCekRnJYnMAy/gEiwzrUtgP7KGYUo70jR6 SGfJXiUQVIzuDlyBeTADVphW6cgfdmgLGq5mRJoXN9hh5YQRimCF939yYVlnFeyBN3DPvRum0eYT HYItMJ0inYrX1yPCNlNa51gETaNKBW5hZC54BqfgJkMa1yIdWWUtHQ75B2tndOBlvpGTd7ZymgYJ hWpM5STr9hoZ8Cd+x8jEy3zjsZ2T1spm922AHQ63zQydgROKOfRL05+zvlubFN4oEe2CbTDHhUXo GDSDGev5pD8Ou6rQsiwO+DUjagbuETPkvHYlVnXL7waNeIjzF/qK+Qcvz4x/CwrdfgUYAD/RlpZB jDs1AAAAAElFTkSuQmCC'
    />
    <path
      fill='#00FEFF'
      d='M16.4 3.3c-.4.4-.4 1 0 1.3L20.8 9H3.9c-.5 0-.9.5-.9 1s.4.9.9.9h16.9l-4.4 4.4c-.4.4-.4 1 0 1.3.4.4 1 .4 1.3 0l6-6.1c.1-.1.1-.2.2-.3 0-.1.1-.2.1-.4s-.1-.5-.3-.7l-6-6.1c-.3-.1-.9-.1-1.3.3z'
    />
  </svg>
)

export const ArrowDown = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 28 21'
    {...props}
  >
    <switch>
      <g>
        <g>
          <image
            overflow='visible'
            width={21}
            height={28}
            xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAcCAYAAACOGPReAAAACXBIWXMAAAsSAAALEgHS3X78AAAA GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAe9JREFUeNq0lgtTwjAQhNsQoYLi W///j/P9RKQildRL/UKvoVTHkc4sNCls9vbukiZJ1+XKNPnDlXYQxc/K6tOk5e9JazLDvFHPHSgr /EBso7En6gl2eGYgWgo+gRMBros4VSoD4UCwK8gg99dCMAeLFfkGYhstsAPhAdhl3pO9gpngQ1Bs UmwVoWGcQXguOGQuFzwL+kQz5X+txDbKtkHtEMIL1Hpl+zzrRcLWiG01qInLKApPeMR4qJKny6ys PFbE2lNHlkNScu5TCAeoTCFya6XmyqqO7dqKdVK8h2PBCJ+HitTx2wIslahWpXMS8QhZgFe6R/ie 8J1KyPH8M6i2q9ZzlR0hfP/DF4hGJCmoHkPywsJPDa8lP6alv53ydYZq7e8AK0Yksq9sSZMok/92 mZa2NayeqdCHzJWEnhPFOxEsVbJJ1HedpoTRV8V/Cg6ZLyAMXr5iU0hSo6T0DpWh7oSOOmPsF30T PAiuBXckK2cx11Ramxx6f0wnHUNo+LNXdyW4hHSCBbXSqE6NCj+LfJwR6g2ENyzw1vB01abNc0jf hwJPSM4TYd9iwbRR9Bs2lFCjhSr+JNr67vmekqCibbO2UdGH3p8wP+3cpDfs/jbqpiIK+U/HSXya /u7g6yDc2hG9lZeJZBuvPV8CDACpVwBDfa77LwAAAABJRU5ErkJggg=='
            transform='rotate(90 14 14)'
          />
          <path
            fill='#00FEFF'
            d='M14 12.2l5.7-5.7c.4-.4 1.1-.4 1.5 0 .4.4.4 1.1 0 1.5l-6.4 6.4c-.4.4-1.1.4-1.5 0L6.8 8c-.4-.4-.4-1 0-1.5.4-.4 1.1-.4 1.5 0l5.7 5.7z'
          />
        </g>
      </g>
    </switch>
  </svg>
)

export const ArrowLeft = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 27 21'
    {...props}
  >
    <image
      overflow='visible'
      opacity={0.4}
      width={27}
      height={21}
      xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAVCAYAAAC33pUlAAAACXBIWXMAAAsSAAALEgHS3X78AAAA GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAZ1JREFUeNrUlutLwlAYxt3puMIo oxtmWRB9DCqoPveX1/ek6/eCblaMgoSMttzpeecznEthFxk0+LlNt/PsvT3TKuXZfGPxSPYmOFKW GXW5lUNI4VODKTABuuAbeBD0xyfWi6gMFkADVEEHtIADviDYjd+mcyRRIloHR2ATtMEFOAePeKA/ glnFLKZulkIHrNki15TfH+KCWcUMa9RhRHJeA3asNAOCekRnJYnMAy/gEiwzrUtgP7KGYUo70jR6 SGfJXiUQVIzuDlyBeTADVphW6cgfdmgLGq5mRJoXN9hh5YQRimCF939yYVlnFeyBN3DPvRum0eYT HYItMJ0inYrX1yPCNlNa51gETaNKBW5hZC54BqfgJkMa1yIdWWUtHQ75B2tndOBlvpGTd7ZymgYJ hWpM5STr9hoZ8Cd+x8jEy3zjsZ2T1spm922AHQ63zQydgROKOfRL05+zvlubFN4oEe2CbTDHhUXo GDSDGev5pD8Ou6rQsiwO+DUjagbuETPkvHYlVnXL7waNeIjzF/qK+Qcvz4x/CwrdfgUYAD/RlpZB jDs1AAAAAElFTkSuQmCC'
      transform='rotate(180 13.5 10.5)'
    />
    <path
      fill='#00FEFF'
      d='M10.6 17.7c.4-.4.4-1 0-1.3L6.2 12h16.9c.5 0 .9-.4.9-.9s-.4-.9-.9-.9H6.2l4.4-4.4c.4-.4.4-1 0-1.3-.4-.4-1-.4-1.3 0l-6 6.1c-.1.1-.1.2-.2.3-.1-.2-.1 0-.1.1 0 .2.1.5.3.7l6 6.1c.3.3.9.3 1.3-.1z'
    />
  </svg>
)

export const DisabledArrowRight = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20.243}
    height={13.501}
    viewBox='0 0 20.243 13.501'
    {...props}
  >
    <path
      fill='#E81D80'
      d='M20.784,11.51a.919.919,0,0,0-.007,1.294l4.275,4.282H8.782a.914.914,0,0,0,0,1.828H25.045L20.77,23.2a.925.925,0,0,0,.007,1.294.91.91,0,0,0,1.287-.007l5.794-5.836h0a1.026,1.026,0,0,0,.19-.288.872.872,0,0,0,.07-.352.916.916,0,0,0-.26-.64l-5.794-5.836A.9.9,0,0,0,20.784,11.51Z'
      transform='translate(-7.875 -11.252)'
    />
  </svg>
)
