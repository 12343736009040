import React from 'react'
import { EmptyViewWrapper, ElementsContainer } from './styles'
import EmptyViewImage from '../../assets/images/uncoming.png'
import EmptyViewImageSes from '../../assets/images/Icon_Nofound_03.png'

export const EmptyView = ({ children, session }) => {
  return (
    <EmptyViewWrapper>
      <ElementsContainer>
        <img src={(session ? EmptyViewImageSes : EmptyViewImage)} />
        {children}
      </ElementsContainer>
    </EmptyViewWrapper>
  )
}

/* <>
<h3>You don't have any student yet</h3>
<p>As soon as a student books a session with you, It will appear here and we'll notify you</p>
</> */
