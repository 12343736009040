import React, { useState } from 'react'
import {
  UserInfo,
  UserName,
  Name,
  Status,
  ContentImage
} from './styles'

// Components
import { DrawMenu } from '../DrawMenu'
import { Photo } from '../Photo'

// Conatainer
import { UserSettings } from '../../container/UserSettings'

// Hooks
import useTranslation from '../../i18n'

export const HeaderUser = ({ profile }) => {
  const { localeProvider } = useTranslation()
  const [isOpenDrawMenu, setIsOpenDrawMenu] = useState(false)

  const handleChangeDrawMenu = () => {
    setIsOpenDrawMenu(!isOpenDrawMenu)
  }

  return (
    <UserInfo>
      <UserName>
        <Name>{profile.fullName}</Name>
        <Status>
          {
            (profile.active && localeProvider.name === 'en') ? 'Available' : 'Disponible'
          }
        </Status>
      </UserName>
      <ContentImage onClick={handleChangeDrawMenu}>
        <Photo photoImage={profile.urlProfileImage} />
      </ContentImage>
      <DrawMenu
        isOpen={isOpenDrawMenu}
        onCloseModal={handleChangeDrawMenu}
        opacity={'0.1'}
      >
        <UserSettings onClose={handleChangeDrawMenu} />
      </DrawMenu>
    </UserInfo>
  )
}
