import styled from 'styled-components'

export const Tools = styled.div`
  grid-area: ${({ gridArea }) => gridArea};
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
 
  width: 100%;

`
export const Label = styled.label`
  width: 50%;
  padding-bottom: 8px;
  border-bottom: ${({ active, theme }) => active ? `4px solid ${theme.secondaryColor}` : `4px solid ${theme.primaryColor}`};
  color: ${({ active, theme }) => active ? theme.secondaryColor : theme.primaryColor};
  font-size: 12px;
  text-align: center;
  box-shadow: ${({ active, theme }) => active ? `0px 3px 3px -3px ${theme.secondaryColor}` : ''};
  text-shadow: ${({ active, theme }) => active ? `0 0 5px ${theme.secondaryColor}` : ''};
  font-family: ${({ theme }) => theme.fontFamilyBold};
  cursor:pointer;
`

export const Input = styled.input`
  -webkit-appearance: none;

  color: ${({ checked, theme }) => checked ? theme.secondaryColor : theme.primaryColor};
`
