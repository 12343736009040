import styled from 'styled-components'
import {
  fontStyleContent
} from '../../styles/general'
import { ArrowLeft } from '../../assets/static/Arrow'

export const ModalArrowLeft = styled(ArrowLeft)`
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
`

const ContainerModal = styled.section`
  height: 350px;
  width: 550px;
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.backgroundDark};
`

export const LoadingContainer = styled(ContainerModal)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalContainer = styled(ContainerModal)`
  display: grid;
  grid-template-rows: 14% 86%;
  grid-template-columns: 1fr;
  grid-template-areas:  "title"
                        "content";
`

export const Title = styled.section`
  grid-area: title;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  & h2{
    height: fit-content;
    width: fit-content;
    color: ${({ theme }) => theme.whiteColor};
    font-size: 16px;
  }
`

export const MainContainer = styled.section`
  width: 100%;
  height: 100%;
  grid-area: content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`

export const SubtitleContent = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & h3 {
    ${fontStyleContent};
    color: ${({ theme }) => theme.colors.gray};
  }
`

export const BalanceContent = styled.div`
  width: 240px;
  height: fit-content;
  margin: 10px 0;
`

export const CardFieldValues = styled.div`
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
`

export const Field = styled.h3`
  ${fontStyleContent};
  color: ${({ theme }) => theme.colors.gray};
`

export const Value = styled.p`
  ${fontStyleContent}
`

export const ButtonContainer = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ContentSucces = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;

  & img{
    width: 150px;
  }

  & h3 {
    ${fontStyleContent}
    font-size: 16px;
  }

  & p {
    ${fontStyleContent}
    color: ${({ theme }) => theme.colors.gray} ;
    margin-top: 6px;
    font-size: 14px;
  }
`

export const PanelType = styled.div`
  flex-direction: row;
  margin-bottom: 20px;
  display:flex;
  justify-content:space-between;
  width: 70%;
  align-self:center;
  align-items:center;
`
export const PanelItems = styled.div`
  flex-direction: row;  
  display:flex;
  justify-content:space-between;
  width: 100%;  
`
export const IconCheck = styled.img`
  height: 25px;
  align-self: center;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  cursor:pointer
`

export const TextPoints = styled.div`
  color: ${({ active, theme }) => active ? theme.colors.white : theme.colors.whiteTrasparent};
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  text-align: left;
  line-height: 20px;
  align-self: center;
  flex: 5;
`
