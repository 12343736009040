import styled from 'styled-components'
import { device } from '../../styles/device'

export const LanguageComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 41%;

  span {
    font-family: 'TitilliumWebSemiBold';
  }

  @media ${device.tablet} {
    width: 39%;
  }
`

export const ListLanguage = styled.ul`
  height: 100%;
  width: 140px;
  display: flex;
  justify-content: center;
  align-items:center;
`

export const ItemLanguage = styled.li`
  display: grid;
  margin: 4px;
  width: 100%;
  height: 30px;
  grid-template-columns: 30% 70%;
  grid-template-rows: 100%;
  grid-template-areas: "icon language";
  cursor: pointer;
`

export const IconCheckContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: icon;
  text-align: center;
`

export const IconCheck = styled.img`
  align-self: center;
  justify-content: center;
  align-items: center;
`

export const TextLanguage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: language;
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamilyMedium};
`
