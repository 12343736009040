import http from '../services/DataService'
import { ENDPOINTS, URI, ROUTES } from '../constants'
import { handleDataLessons } from './Lessons'

export const lessonViewed = async (id) => {
  try {
    const res = await http.create(ENDPOINTS.LESSON_VIEWED, { id })
    return res
  } catch (error) {
    throw handler(error)
  }
}

export const updateProfile = async (data = {}) => {
  try {
    const res = await http.update(ENDPOINTS.UPDATE_PROFILE, data)
    if (res.status === 200) {
      if (Object.prototype.hasOwnProperty.call(res.result, 'urlProfileImage')) {
        res.result.urlProfileImage = `${URI}/${res.result.urlProfileImage}`
      }
      if (Object.prototype.hasOwnProperty.call(res.result, 'tree')) {
        res.result.tree = `${URI}/${res.result.tree}`
      }
    }
    return res
  } catch (error) {
    throw handler(error)
  }
}

export const getFreeCoins = async () => {
  try {
    const res = await http.create(ENDPOINTS.MY_FREE_COINS)
    if (res.status === 200) {
      if (Object.prototype.hasOwnProperty.call(res.result, 'urlProfileImage')) {
        res.result.urlProfileImage = `${URI}/${res.result.urlProfileImage}`
      }
      if (Object.prototype.hasOwnProperty.call(res.result, 'tree')) {
        res.result.tree = `${URI}/${res.result.tree}`
      }
    }
    return res
  } catch (error) {
    throw handler(error)
  }
}

export const getClaimCoins = async () => {
  try {
    const res = await http.create(ENDPOINTS.CLAIM_COINS)
    if (res.status === 200) {
      if (Object.prototype.hasOwnProperty.call(res.result, 'urlProfileImage')) {
        res.result.urlProfileImage = `${URI}/${res.result.urlProfileImage}`
      }
      if (Object.prototype.hasOwnProperty.call(res.result, 'tree')) {
        res.result.tree = `${URI}/${res.result.tree}`
      }
    }
    return res
  } catch (error) {
    throw handler(error)
  }
}

export const getAccountInfo = async () => {
  try {
    await fetch('http://ip-api.com/json')
      .then(function (response) {
        return response.json()
      })
      .then(async function (payload) {
        const data = {
          type: 'country',
          value: payload.countryCode.toUpperCase()
        }
        await http.update(ROUTES.UPDATE_PROFILE, data)
      })
  } catch (err) {
    console.log(err)
  }
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const res = await http.get(ENDPOINTS.ACCOUNT, { timezone })
    if (res.status === 200) {
      if (Object.prototype.hasOwnProperty.call(res.result, 'urlProfileImage')) {
        res.result.urlProfileImage = `${URI}/${res.result.urlProfileImage}`
      }
      if (Object.prototype.hasOwnProperty.call(res.result, 'tree')) {
        res.result.tree = `${URI}/${res.result.tree}`
      }
    }
    return res
  } catch (error) {
    throw handler(error)
  }
}

export const getTeacherProfile = async (id) => {
  try {
    let list = []
    const res = await http.get(ENDPOINTS.ACCOUNT_PROFILE, {
      idUser: id
    })
    if (res.status === 200) {
      list = handleDataLessons(res.result.lessonsList)
    }
    return {
      ...res,
      result: {
        ...res.result,
        profileImage: `${URI}/${res.result.profileImage}`,
        lessonsList: list,
        ratings: res.result.ratings.map(item => {
          return {
            ...item,
            image: `${URI}/${item.image}`
          }
        })
      }
    }
  } catch (e) {
    throw handler(e)
  }
}

export const followTeach = async (id) => {
  try {
    const res = await http.create(ENDPOINTS.FOLLOW_TEACH, {
      idUser: id
    })
    return res
  } catch (e) {
    throw handler(e)
  }
}

export const getStatistics = async (data = {}) => {
  try {
    const res = await http.get(ENDPOINTS.TEACHER_STATISTICS, data)
    return res
  } catch (error) {
    throw handler(error)
  }
}

export const getNotifications = async () => {
  try {
    const res = await http.get(ENDPOINTS.LIST_NOTIS)
    return res
  } catch (error) {
    throw handler(error)
  }
}

export const updateViewNotification = async (id) => {
  try {
    const res = await http.update(ENDPOINTS.VIEW_NOTI, { id })
    return res
  } catch (error) {
    throw handler(error)
  }
}

export async function getAllUsers () {
  try {
    const res = await http.getAll(ENDPOINTS.GET_ALL_USERS)
    let list = []
    if (res.status === 200) {
      list = res.result.filter(item => item.fullRecord).map(item => {
        return {
          ...item,
          urlProfileImage: `${URI}/${item.urlProfileImage}`
        }
      })
    }
    return {
      ...res,
      result: list
    }
  } catch (e) {
    throw handler(e)
  }
}

export function handler (err) {
  if (err.response && err.response.data) {
    return err.response.data
  }
  return err
}
