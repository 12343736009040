import React, { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ROUTES } from '../../constants'
import http from '../../services/DataService'
import codes from '../../assets/json/Codes'
import { Loader } from '../../components/Loader'
import { useAppContext } from '../../hooks/useAppContext'
import Close from '../../assets/images/close-circle-outline.png'
import Arrowdown from '../../assets/images/arrowdown.png'
import Success from '../../assets/images/success.png'
import CheckOut from '../../assets/images/checkOut.png'
import IconCountrys from '../../assets/images/iconCountry.png'
import Search from '../../assets/images/search.png'
import useTranslation from '../../i18n'

// Services
import { updateProfile } from '../../services/User'
import { followTag, getAllTagsLesson } from '../../services/Lessons'

export function Preferences () {
  const { profile, setProfile } = useAppContext()
  const { t, localeProvider, changeLocale } = useTranslation()
  const [country, setCountry] = useState(codes[0])
  const [filters, setFilters] = useState('')
  const [languages, setLanguages] = useState([])
  const [lessonsList, setLessonsList] = useState([])
  const [modal, setModal] = useState('Main')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async function () {
      await getLanguages()
      await getListLessonOrderByTags()
      // eslint-disable-next-line array-callback-return
    })()
  }, [])

  useEffect(() => {
    const country = codes.find(
      item => item.code === profile.country
    )
    setCountry(country)
  }, [profile, codes])

  const getLanguages = async () => {
    try {
      setLoading(true)
      const resLanguage = await http.getAll(ROUTES.CONFIG_LANGUAGE)
      const filteredLanguages = resLanguage.result.filter(item => item.complete === 100)

      setLanguages(filteredLanguages)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (error.status === 401) {
        toast.error(
          localeProvider.name === 'en'
            ? error.message_en
            : error.message_es
        )
      } else if (error.status === 403) {
        toast.error(
          localeProvider.name === 'en'
            ? error.message_en
            : error.message_es
        )
      } else {
        toast.error(error.message)
      }
    }
  }

  const getListLessonOrderByTags = async () => {
    setLoading(true)
    try {
      const res = await getAllTagsLesson()
      setLessonsList(res.result)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (error.status === 401) {
        toast.error(error.message_en)
      } else if (error.status === 403) {
        toast.error(error.message_en)
      } else {
        toast.error(error.message)
      }
    }
  }

  const handleFollow = async (id) => {
    try {
      const res = await followTag(id)
      if (res.status === 200) {
        const res2 = await getAllTagsLesson()
        setLessonsList(res2.result)
      }
    } catch (error) {
      setLoading(false)
      if (error.status === 401) {
        toast.error(error.message_en)
      } else if (error.status === 403) {
        toast.error(error.message_en)
      } else {
        toast.error(error.message)
      }
    }
  }

  const updateCountry = async (countryData) => {
    setLoading(true)
    try {
      const data = {
        type: 'country',
        value: countryData
      }
      const res = await updateProfile(data)

      if (res.status === 200) {
        setProfile(res.result)
      }

      setLoading(false)
      closeModal()
    } catch (error) {
      setLoading(false)
      if (error.status === 401) {
        toast.error(error.message_en)
      } else if (error.status === 403) {
        toast.error(error.message_en)
      } else {
        toast.error(error.message)
      }
    }
  }

  const updateLanguage = async (item) => {
    try {
      const data = {
        type: 'language',
        value: item._id
      }
      const res = await updateProfile(data)
      setProfile(res.result)
      changeLocale({ name: res.result.adv })

      closeModal()
    } catch (error) {
      setLoading(false)
      if (error.status === 401) {
        toast.error(error.message_en)
      } else if (error.status === 403) {
        toast.error(error.message_en)
      } else {
        toast.error(error.message)
      }
    }
  }

  // Country
  const handleChangeCountry = (country) => {
    console.log('---', country, 'update')
    setCountry(country)
    updateCountry(country.code)
  }

  // Filter countries
  const handleChangeFilter = (e) => setFilters(e.target.value)

  const filteredCountries = useMemo(() =>
    codes.filter(country => {
      if (profile.adv === 'es') {
        return country.name_es.toLowerCase().includes(filters.toLowerCase()) ||
                country.code.toLowerCase().includes(filters.toLowerCase())
      } else if (profile.adv === 'en') {
        return country.name_en.toLowerCase().includes(filters.toLowerCase()) ||
                country.code.toLowerCase().includes(filters.toLowerCase())
      } else {
        return country.name_en.toLowerCase().includes(filters.toLowerCase()) ||
                country.name_es.toLowerCase().includes(filters.toLowerCase()) ||
                country.code.toLowerCase().includes(filters.toLowerCase())
      }
    })
  , [codes, filters])

  const closeModal = () => {
    setModal('Main')
  }

  const openModal = (type) => {
    setFilters('')
    setModal('modal')
  }

  return (
    <Container>
      {loading && <Loader />}
      {(modal === 'Main' && !loading) &&
        <>
          <Country>{t('country')}</Country>
          <div onClick={openModal}>
            <ContainerInput>
              <IconCountry resizeMode='contain' src={IconCountrys} />
              <TextSelectedCountry>{profile.adv === 'es' ? country?.name_es : country?.name_en}</TextSelectedCountry>
              <IconAngleDown resizeMode='contain' src={Arrowdown} />
            </ContainerInput>
          </div>
          <YourLanguage>{t('YourLanguage')}</YourLanguage>
          <SelectYourSystemLanguage>{t('SelectYourSystemLanguage')}.</SelectYourSystemLanguage>
          {
            languages.map((item, index) => (
              <ContainerLanguaje key={index}>
                <div style={{ flex: 1 }} onClick={() => updateLanguage(item)}>
                  <IconCheck
                    resizeMode='contain' src={profile.language === item._id ? Success : CheckOut}
                  />
                </div>
                <TextLanguage> {t(item.name)} </TextLanguage>
              </ContainerLanguaje>
            ))
          }
          <Hr />
          <YourTags>{t('YourTags')}:</YourTags>
          <ContainerTags>
            {lessonsList.map((item, index) => (
              <div key={index} onClick={() => handleFollow(item._id)}>
                <TextTags active={item.follow !== 0}>
                  {item.name}
                </TextTags>
              </div>
            ))}
          </ContainerTags>
        </>}
      {(modal !== 'Main' && !loading) &&
        <ContainerModal>
          <HeaderModal>
            <MenuCenter>
              <TitleModal>
                {t('selectCountry')}
              </TitleModal>
            </MenuCenter>
            <MenuExtModal>
              <div onClick={() => closeModal()}>
                <IconClose resizeMode='contain' src={Close} />
              </div>
            </MenuExtModal>
          </HeaderModal>

          <ContainerInputCountry>
            <IconSearch resizeMode='contain' src={Search} />
            <InputFilter
              maxLength={25}
              placeholder={t('country')}
              onChange={handleChangeFilter}
              value={filters}
            />
            <Div />
          </ContainerInputCountry>
          <ScrollView>
            {
              filteredCountries.map((item) => (
                <div
                  key={item.code} onClick={() => handleChangeCountry(item)}>
                  <ContainerItems>
                    <TextCountry> {item.code} - { profile.adv === 'es' ? item.name_es : item.name_en} </TextCountry>
                  </ContainerItems>
                </div>
              ))
            }
          </ScrollView>
        </ContainerModal>}
    </Container>
  )
}

const YourTags = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 15px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  text-align: center;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin-top: 20px;
`

const ContainerTags = styled.div`
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  display:flex;
  width:95%;
`

const TextTags = styled.div`
  color: ${({ active, theme }) => active ? theme.colors.cyan : theme.colors.whiteTrasparent};
  font-size: 14px;
  cursor:pointer;
  text-align: center;
  justify-content: center;
  margin-right: 15px;
  margin-top: 10px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  text-shadow: ${({ active, theme }) => active ? `0px 0px 8px ${theme.colors.cyan}` : `0px 0px 0px ${theme.colors.whiteTrasparent}`};
`

const Hr = styled.div`
  border-color: ${({ theme }) => theme.colors.grayLight};
  border-bottom-width: 1px;
  margin-top: 10px;
`

const YourLanguage = styled.div`
  color: ${({ theme }) => theme.colors.fucsia};
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamilyBold};
  text-align: left;
  width: 100%;
  margin-top: 5%;
  margin-bottom: 10px;
`
const SelectYourSystemLanguage = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamilyBold};
  text-align: left;
  width: 100%;
  margin-bottom: 10px;
`
const ContainerLanguaje = styled.div`
  flex-direction: row;
  height: 50px;
  justify-content: center;
  align-items: center;
  display:flex;
`
const IconCheck = styled.img`
  height: 25px;
  align-self: center;
  justify-content: center;
  align-items: center;
`
const TextLanguage = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  text-align: left;
  font-family: ${({ theme }) => theme.fontFamilyMedium};
  flex: 5;
`

const TextCountry = styled.div`
  color: ${({ theme }) => theme.colors.lila};
  font-size: 16px;
  text-align: left;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
`
const ContainerItems = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-self: flex-start;
  align-items: center;
  height: 50px;
  border-color: ${({ error, theme }) => error ? theme.colors.fucsia : theme.colors.grayLight};
  border-bottom-width: 1px;
  width: 90%;
  cursor: pointer;
`

const Div = styled.div`
  flex:1;
`

const ContainerInputCountry = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 20px;
  height: 40px;
  border-bottom: 2px solid ${({ error, theme }) => error ? theme.colors.fucsia : theme.colors.grayLight};
  display:flex;
`
const IconSearch = styled.img`
  height: 27px;
  justify-content: center;
  align-items: center;
  flex:1;
`
const InputFilter = styled.input`
  width:200px;
  height: 40px;
  font-size: 18px;
  padding-left: 10px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  color: ${({ theme }) => theme.colors.white};
  outline: none;
  background:transparent;
  border: none;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.lilaLight};
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.lilaLight};
  }
`

const Country = styled.div`
  color: ${({ theme }) => theme.colors.fucsia};
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamilyBold};
  text-align: left;
  padding-left: 30px;
  width: 100%;
  margin-top: 5%;
  margin-bottom: 10px;
`
const TextSelectedCountry = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 15px;
  text-align: left;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  flex:4;
`
const ContainerInput = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  border-bottom: 1px solid ${({ error, theme }) => error ? theme.colors.fucsia : theme.colors.grayLight};
  height: 50px;
  display:flex;
`

const IconCountry = styled.img`
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  margin-right:4px;
`

const IconAngleDown = styled.img`
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
`

const ContainerModal = styled.div`
  flex: 1;
  align-items: flex-start;
  display:flex;
  flex-direction:column;
  padding:5px;
  height:100%;
  width:100%;
  padding-bottom:10px;
`
const HeaderModal = styled.div`
  height: 50px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color:${({ theme }) => theme.colors.blueDark};
  padding-top: 0px;
  padding-bottom: 10px;
  border-color: ${({ theme }) => theme.colors.lilaLight};
  border-bottom-width: 1px;
  display:flex;
`
const IconClose = styled.img`
  height: 26px;
  justify-content: center;
  align-items: center;
`
const TitleModal = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  text-align: left;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
`
const MenuExtModal = styled.div`
  justify-content: center;
  align-items: center;
  flex:1;
  display:flex;
`
const MenuCenter = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  width:100%;
  display:flex;
`

const Container = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.colors.blueDark};
  align-items: flex-start;
  padding-left:5px;
  padding-bottom:10px;
`
const ScrollView = styled.div`
  width: 100%;
  height: 100%;
`
