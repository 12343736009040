import styled from 'styled-components'
import {
  // fontStyleTitle,
  // fontStyleContent,
  scrollBar
} from '../../styles/general'

export const CreditCardWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

// Content
const Content = styled.section`
  width: 100%;
  height: 100%;

  overflow: auto;
  ${scrollBar}
`

export const MainContainer = styled(Content)`
  grid-area: content;
  display: flex;
  flex-direction: column;
`

export const ContentPayment = styled.div`
  display:flex;
  width: 100%;
  height: fit-content;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & p {
    margin: 10px 0;
    font-size: 12px;
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamilyRegular};
    color: ${({ theme }) => theme.colors.whiteTrasparent};
  }
`

export const ContentCreditCards = styled.section`
  height: fit-content;
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const AddCreditCardContainer = styled.div`
  display: grid;
  height: 90px;
  width: 80%;
  margin: 0 auto;
  padding: 10px;
  grid-template-rows: 60% 40%;
  grid-template-columns: 1fr;
  grid-template-areas:  "credit-card-button"
                        "credit-card-text";
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayLight};
`

export const CreditCardButton = styled.div`
  grid-area: credit-card-button;
  width: 100%;
  height: 100%;

  & button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`

export const CreditCardText = styled.div`
  grid-area: credit-card-text;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & h3 {
    font-size: 14px;
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamilyRegular};
    color: ${({ theme }) => theme.colors.cyan};
  }
`
