import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

// Notifications
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// API
import { ROUTES } from '../../constants'
import http from '../../services/DataService'
import FreeCoins from '../../assets/images/freeCoins.png'
import { useAppContext } from '../../hooks/useAppContext'
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard'
import useTranslation from '../../i18n'

export function ReferralProgram () {
  const [config, setConfig] = useState({
    quantityReferrals: 0,
    referral: 0
  })
  const { profile } = useAppContext()
  const { handleCopy } = useCopyToClipboard()
  const { t } = useTranslation()

  useEffect(() => {
    async function upload () {
      await getConfigData()
    }
    upload()
  }, [])

  const getConfigData = async () => {
    try {
      const response = await http.getAll(ROUTES.CONFIG_GENERAL)
      setConfig(response.result[0])
      // console.log(response)
    } catch (error) {
      if (error.status === 401) {
        toast.error(error.message_en)
      } else if (error.status === 403) {
        toast.error(error.message_en)
      } else {
        toast.error(error.message)
      }
    }
  }

  const copyToClipboard = () => {
    handleCopy(profile.referralCode !== undefined ? profile.referralCode : '000000')
    toast.success('Copy code to clipboard')
  }

  return (
  <Container>
    <Body>
      <IconDinner resizeMode='contain' src={FreeCoins} />
      <TextReferralProgram>{t('ReferralProgram')}</TextReferralProgram>
      <ShareYourReferralCode>{t('ShareYourReferralCode')}</ShareYourReferralCode>
      <div onClick={() => copyToClipboard()} styled={{ cursor: 'pointer' }} title={t('CopyToClipboard')}>
        <Code>{profile.referralCode !== undefined ? profile.referralCode : '000000'}</Code>
      </div>
      <FlexRow>
        <Comission>{t('Comission')}:</Comission>
        <PerLesson>{`${config.quantityReferrals}% ${t('PerLesson')}`}</PerLesson>
      </FlexRow>
      <FlexRow>
        <Comission>{t('Referrals')}:</Comission>
        <PerLesson>{`${config.referral} ${t('Teachers')}`}</PerLesson>
      </FlexRow>
      <FlexRow>
        <Comission>{t('ReferralDays')}:</Comission>
        <PerLesson>{`${config.daysWithReferrals} ${t('Days')}`}</PerLesson>
      </FlexRow>
    </Body>
  </Container>
  )
}

const FlexRow = styled.div`
  flex-direction: row;
  display:flex;
`

const Comission = styled.div`
  color: ${({ theme }) => theme.colors.lilaLight};
  font-size: 12px;
  text-align: right;
  justify-content: center;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  margin-bottom: 2%;
  /* margin-horizontal: 5px; */
  flex:1;
`

const PerLesson = styled.div`
  color: #E4FF00;
  font-size: 12px;
  margin-left: 8px;
  /* text-align: left; */
  justify-content: center;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  margin-bottom: 2%;
  /* margin-horizontal: 5px; */
  flex:1;
`

const IconDinner = styled.img`
  height: 80px;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 7%;
  margin-bottom: 1%;
`

const TextReferralProgram = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  text-align: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fontFamilyMedium};
  margin-bottom: 3%;
`

const ShareYourReferralCode = styled.div`
  color: ${({ theme }) => theme.colors.lila};
  font-size: 14px;
  text-align: center;
  justify-content: center;
  align-self: center;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  width: 90%;
  line-height: 22px;
  margin-bottom: 3%;
`

const Code = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 40px;
  text-align: center;
  justify-content: center;
  font-family:  ${({ theme }) => theme.fontFamilyBold};
  margin-bottom: 3%;
  letter-spacing: 11px;
  cursor:pointer;
`

const Container = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.colors.blueDark};
  align-items: flex-start;
`

const Body = styled.div`
  background: ${({ theme }) => theme.colors.blueDark};
  flex-direction: column;
  display:flex;
  width:100%;
`
