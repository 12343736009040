/* eslint-disable no-unused-vars */
const master = 'https://api.meixter.com'
const mastershared = 'https://app.meixter.com'

const dev = 'https://develop.meixter.com'
const devshared = 'https://devapp.meixter.com'

const url = dev
const urlshared = devshared

export const API_URL = `${url}/api/v1`
export const URI = 'https://multimedia.meixter.com'
export const API_SHARE = urlshared
export const API_DOWNLOAD = `${url}/download`
export const URI_WS = 'wss://develop.meixter.com'
export const URI_VIEW = 'https://multimediameixter.s3.us-east-2.amazonaws.com'

export const APP_STATE = {
  PUBLIC: 'PUBLIC_LOGIN',
  PRIVATE: 'MAIN_APP',
  REGISTER: 'REGISTER'
}

export const STATUS = {
  SUCCESS: 'SUCCESS',
  NOT_STARTED: 'NOT_STARTED',
  FETCHING: 'FETCHING',
  FAILED: 'FAILED'
}

export const LOCALES = {
  ENGLISH: { id: 1, name: 'en', label: 'ENGLISH' },
  SPANISH: { id: 2, name: 'es', label: 'ESPAÑOL' }
}

export const ROUTES = {
  LOGIN: `${API_URL}/auth/login`,
  VERIFY: `${API_URL}/account/phoneVerify`,
  ACCOUNT: `${API_URL}/account/info`,
  ACCOUNT_PROFILE: `${API_URL}/account/profile`,
  UPLOADIMAGE: `${API_URL}/account/profileImage`,
  VERIFYNICK: `${API_URL}/account/verifyNickname`,
  PROFILEDATA: `${API_URL}/account/profileData`,
  ADJUSTPROFILE: `${API_URL}/account/profileAdjust`,
  FOLLOW_TEACH: `${API_URL}/account/follow`,
  TAGSTREND: `${API_URL}/tag/trend`,
  TAGSALL: `${API_URL}/tag/all`,
  MYTAGS: `${API_URL}/tag/user/all`,
  CONFIG_GENERAL: `${API_URL}/Config/General`,
  CONFIG_LANGUAGE: `${API_URL}/Config/Language`,
  CONFIG_TRACKS: `${API_URL}/Config/Track`,
  LESSON: `${API_URL}/Lesson/Lesson`,
  LESSON_VIDEO: `${API_URL}/Lesson/loadMedia`,
  LIST_LESSON: `${API_URL}/Lesson/listByUser`,
  GET_LESSON: `${API_URL}/Lesson/findById`,
  FREEZE_LESSON: `${API_URL}/Lesson/offer`,
  COMMENTS_LESSON: `${API_URL}/Lesson/Comment`,
  ALL_LESSON: `${API_URL}/Lesson/findByTags`,
  SAVE_LESSON: `${API_URL}/Lesson/Save`,
  SAVED_LESSON: `${API_URL}/Lesson/Saved`,
  FOLLOWING_LESSON: `${API_URL}/Lesson/following`,
  VIEW_LESSON: `${API_URL}/Lesson/View`,
  WEEKLY: `${API_URL}/Calendar/weeklySchedule`,
  SEARCH_DAY: `${API_URL}/Calendar/searchSpecialDays`,
  SPECIAL_DAY: `${API_URL}/Calendar/specialDays`,
  AVAILABLE_TIMES: `${API_URL}/Calendar/availableTimes`,
  DISABLED_DATES: `${API_URL}/Calendar/timesNotAvailable`,
  PAYMENT_METHODS: `${API_URL}/PaymentMethods`,
  PURCHASE_COINS: `${API_URL}/Wallet/PurchasedCoins`,
  BOOKING: `${API_URL}/Session/Booking`,
  TEACHER_SESSIONS: `${API_URL}/Session/Teacher`,
  SESSIONS_USER: `${API_URL}/Session/User`,
  MY_FREE_COINS: `${API_URL}/user/freeCoins`,
  CLAIM_COINS: `${API_URL}/user/claimCoins`,
  CONFIRM_STRIPE: `${API_URL}/user/confirmStripeAccount`,
  LINK_STRIPE: `${API_URL}/user/linkStripeAccount`,
  UPDATE_PROFILE: `${API_URL}/account/profileUpdate`,
  VERIFY_PHONE: `${API_URL}/account/verifyPhone`,
  DELETE_ACCOUNT: `${API_URL}/user/cancelAccount`,
  BUY_MEMBERSHIP: `${API_URL}/Membership`,
  MY_EARNINGS: `${API_URL}/Wallet/myEarnings`,
  MY_PAYMENT: `${API_URL}/Wallet/myPaymentHistory`,
  WITHDRAWAL: `${API_URL}/Wallet/Withdrawal`,
  CHART: `${API_URL}/Wallet/myStadistics`,
  TAGS_LESSON: `${API_URL}/Lesson/mainTagList`,
  TAG_FOLLOW: `${API_URL}/tag/follow`,
  NEGATIVERATING: `${API_URL}/Config/NegativeRating`,
  POSITIVERATING: `${API_URL}/Config/PositiveRating`,
  FINISH_SESSION: `${API_URL}/Session/Finished`,
  FINISH_SESSION_TEACH: `${API_URL}/Session/FinishedStudent`,
  ALL_LOCALE: `${API_URL}/Config/Locale/full`,
  FINISHSESSION: `${API_URL}/Session/finishSession`,
  DELETE_RECORD: `${API_URL}/Session/deleteRecord`

}

export const ENDPOINTS = {
  VERIFY: '/account/phoneVerify',
  LOGIN: '/auth/login',
  ACCOUNT: '/account/info',
  ACCOUNT_PROFILE: '/account/profile',
  UPDATE_PROFILE: '/account/profileUpdate',
  UPLOADIMAGE: '/account/profileImage',
  MY_FREE_COINS: '/user/freeCoins',
  CLAIM_COINS: '/user/claimCoins',
  VERIFYNICK: '/account/verifyNickname',
  PROFILEDATA: '/account/profileData',
  TAGSTREND: '/tag/trend',
  ADJUSTPROFILE: '/account/profileAdjust',
  COMMENTS_LESSON: '/Lesson/Comment',
  PAYMENT_METHODS: '/PaymentMethods',
  CONFIG_TRACKS: '/Config/Track',
  CONFIG_LANGUAGE: '/Config/Language',
  A_LESSON: 'Lesson/findByIdLogOut',
  ALL_LESSON: '/Lesson/findByTags',
  LESSON_FREE: '/Lesson/findByTagsFree',
  LESSON: '/Lesson/Lesson',
  SAVED_LESSON: '/Lesson/Saved',
  FOLLOWING_LESSON: '/Lesson/following',
  LESSON_VIDEO: '/Lesson/loadMedia',
  SAVE_LESSON: '/Lesson/Save',
  DISABLED_DATES: '/Calendar/timesNotAvailable',
  AVAILABLE_TIMES: '/Calendar/availableTimes',
  LESSON_ONE: '/Lesson/findById',
  TAGS_LESSON: '/Lesson/mainTagList',
  TAG_FOLLOW: '/tag/follow',
  BOOKING: '/Session/Booking',
  FREEZE_LESSON: '/Lesson/offer',
  LIST_NOTIS: '/Notifications/List',
  VIEW_NOTI: '/Notifications/Views',
  PENDING_NOTI: '/Notifications/Pending',
  SUPPORT: '/Support',
  SUPPORT_GENERAL: '/SupportGeneral',
  SUPPORT_LESSON: '/SupportLesson',
  PENALTY_CANCEL: '/Session/Penalty',
  CANCEL_SESSION: '/Session/Cancel',
  CONFIG_GENERAL: '/Config/General',
  PURCHASE_COINS: '/Wallet/PurchasedCoins',
  BUY_MEMBERSHIP: '/Membership',
  TEACHER_STATISTICS: '/Lesson/Stats',
  FOLLOW_TEACH: '/account/follow',
  SHARED_P2P: '/Lesson/ShareP2P',
  GET_ALL_USERS: '/user/userList',
  LIST_LESSON: '/Lesson/listByUser',
  TEACHER_SESSIONS: '/Session/Teacher',
  VALIDATE_CODE_REF: '/Lesson/referralCode',
  LESSON_FEEDBACK: '/Lesson/FeedBack',
  LESSON_VIEWED: '/Lesson/View',
  INVOICES: '/Wallet/invoice/user'
}
