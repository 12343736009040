import React, { Suspense } from 'react'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from '../styles/GlobalStyle'
import { darkTheme } from '../styles/theme'
import { AppContextProvider } from '../context'
import { LessonsProvider } from '../context/Lesssons'
import { Routes } from '../routes'
import { Loader } from './Loader'
import { LocaleContextProvider } from '../i18n/LocaleContext'

export const App = () => {
  return (
    <LocaleContextProvider>
      <AppContextProvider>
        <LessonsProvider>
          <Suspense fallback={<Loader />}>
            <ThemeProvider theme={darkTheme}>
              <GlobalStyle />
              <Routes />
            </ThemeProvider>
          </Suspense>
        </LessonsProvider>
      </AppContextProvider>
    </LocaleContextProvider>
  )
}
