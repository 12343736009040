import React from 'react'
import {
  CreditCardItemWrapper,
  CheckContainer,
  CardIconConteiner,
  CardNumberConteiner,
  EditConteiner,
  DeleteConteiner
} from './styles'

// Icons
import IMasterCard from '../../assets/images/iconMaster.png'
import IVisa from '../../assets/images/iconVisa.png'
import IDinners from '../../assets/images/iconDiners.png'
import IAmex from '../../assets/images/iconAmex.png'
import CheckOut from '../../assets/images/checkOut.png'
import IEdit from '../../assets/images/iconEdit.png'
import IClose from '../../assets/images/close-circle.png'
import { Check } from '../../assets/static/Check'

// card={
// {
//   _id: card._id,
//   type: card.type,
//   cardNumber: card.cardNumber,
//   cardHolderName: card.cardHolderName,
//   expirationDate: card.expirationDate
//   default: card.default
// }
// }

export const CreditCardItem = (
  {
    card,
    openModal,
    onDefault
  }) => {
  const selectedCard = (typecard) => {
    if (typecard === 'MASTERCARD') {
      return IMasterCard
    } else if (typecard === 'VISA') {
      return IVisa
    } else if (typecard === 'DINERS') {
      return IDinners
    } else if (typecard === 'AMEX') {
      return IAmex
    } else {
      return IVisa
    }
  }

  return (
    <CreditCardItemWrapper>
      <CheckContainer>
        <button onClick={() => onDefault(card)}>
          {
          card.default
            ? <Check width='32px' />
            : <img src={CheckOut} width='25px' />
          }
        </button>
      </CheckContainer>
      <CardIconConteiner>
        <img src={selectedCard(card.type)} width='32px' />
      </CardIconConteiner>
      <CardNumberConteiner>
        <h3>{card.cardNumber.substr(-4)}</h3>
      </CardNumberConteiner>
      <EditConteiner>
        <button onClick={() => openModal('update', card)}>
          <img src={IEdit} width='20px' />
        </button>
      </EditConteiner>
      <DeleteConteiner>
        <button onClick={() => openModal('delete', card)}>
          <img src={IClose} width='20px' />
        </button>
      </DeleteConteiner>
    </CreditCardItemWrapper>
  )
}
