import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'

// Components
import { Loader } from '../Loader'
import Chart from '../Chart'
import { BuyMembership } from '../BuyMembership'

// Resource
import IconTimer from '../../assets/images/IconTimer.png'
import Stars from '../../assets/images/star.png'
import Starout from '../../assets/images/starout.png'

// Hooks
import { useAppContext } from '../../hooks/useAppContext'
import useTranslation from '../../i18n'

// Services
import http from '../../services/DataService'
import { ROUTES, URI } from '../../constants'

export function Earnings ({ handleChangeViewMembership }) {
  const { profile } = useAppContext()
  const [list, setList] = useState([])
  const [balance, setBalance] = useState({ usd: 0, coins: 0 })
  // const [refreshing, setRefreshing] = useState(false)
  const [loading, setLoading] = useState(false)
  const [ahora, setAhora] = useState(Date.now())
  const { t } = useTranslation()

  const getList = async () => {
    try {
      const response = await http.getAll(ROUTES.MY_EARNINGS)
      const list = response.result
      let coins = 0
      let usd = 0
      console.log(list)
      for (let i = 0; i < list.length; i++) {
        if (list[i].usd !== undefined) { usd = parseFloat(usd) + parseFloat(list[i].usd) }
        coins = parseFloat(coins) + parseFloat(list[i].balanceTeacher)
      }
      setBalance({ usd, coins })
      console.log('list', list)
      setList(list)
    } catch (error) {
      if (error.status === 401) {
        toast.error(error.message_en)
      } else if (error.status === 403) {
        toast.error(error.message_en)
      } else {
        toast.error(error.message)
      }
    }
  }

  useEffect(() => {
    async function upload () {
      setLoading(true)
      setAhora(Date.now())
      await getList()
      setLoading(false)
    }

    upload()
  }, [])

  return (
    <Container>
      <ToastContainer />
      {loading && <Loader />}
      {
        (!loading) &&
          <>
          {
            profile.membership === 'Free' &&
              <BuyMembership onClickButton={handleChangeViewMembership} />
          }
          <ChartCont>
            <Chart />
          </ChartCont>
          <MenuExt>
            <Title wd='50%' empty size='14px'>{t('myEarnings')}</Title>
            <MenuExtCont wd='30%'>
              <Title empty mb='0px'>{balance.coins}</Title>
              <Title size='14px' mt='1px'>Coins</Title>
            </MenuExtCont>
            <MenuExtCont wd='30%'>
              <Title empty mb='0px'>{balance.usd}</Title>
              <Title size='14px' mt='1px'>£</Title>
            </MenuExtCont>
          </MenuExt>
          <Separator />
          <Title empty size='14px' mt='10%'>{t('myEarningsHistory')}</Title>
          {
            list.map((item, index) => {
              return (
              <ListItem key={index + 400}>
                <DateTime>{moment(item.date).format('DD MMM, hh:mm a')}</DateTime>
                <ItemInfo>
                  <RowCover>
                    <ImageCover resizeMode='cover' src={`${URI + '/' + item.lessonCover}?time=${ahora}`} />
                    <ButtomCover>
                      <IconViews resizeMode='contain' src={IconTimer} />
                      <TextViews>{item.duration}</TextViews>
                    </ButtomCover>
                  </RowCover>
                  <RowInfo>
                    <Teacher img={item.userImage} rating={item.userRating} nickname={item.userNickname} />
                    <Title size='14px' empty>{`${item.usd} £`}</Title>
                    <Membership>{item.membership}</Membership>
                  </RowInfo>
                </ItemInfo>
              </ListItem>
              )
            })
          }
        </>}
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.colors.blueDark};
  align-items: flex-start;
  padding-left:5px;
  padding-bottom:10px;
  width:100%;
  height:100%;
`

const ChartCont = styled.div`
  width:100%;
  height:200px;
  background-color:#fff;
  flex-direction:row;
  align-self:center;
  justify-content:center;
  display:flex;
  margin-top:5px;
`

const Title = styled.div`
  margin-top: ${props => props.mt || '5%'};
  margin-bottom: ${props => props.mb || '1%'};
  color: ${({ empty, theme }) => empty ? theme.colors.white : theme.colors.lilaLight};
  font-size: ${props => props.size || '16px'};
  text-align: left;
  align-self:${props => props.align || 'flex-start'};
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  ${props => props.wd ? `width:${props.wd}` : ''};
  margin-left:4px;
`

const Membership = styled.div`
  color: #6A65A8;
  font-size: 10px;
  text-align: left;
  align-self: flex-start;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  margin-left:4px;
`

// ---- flatList---
const ListItem = styled.div`
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayLight};
  margin-top: 10px;
  display:flex;
`

const ItemInfo = styled.div`
  flex-direction: row;
  display:flex;
`

const RowCover = styled.div`
  width: 35%;
  align-items: center;
  padding: 10px;
`
const RowInfo = styled.div`
  width: 65%;
`

const DateTime = styled.div`
  color:${({ theme }) => theme.colors.whiteTrasparent};
  font-size: 12px;
  text-align: left;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  width: 100%;
  margin-left: 30px;
`

const TextViews = styled.div`
  color: ${({ theme }) => theme.colors.whiteTrasparent};
  font-size: 14px;
  text-align: left;
  justify-content: center;
  align-items: center;
  align-self: center;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  margin-left: 5px;
`

const IconViews = styled.img`
  height: 20px;
  width: 17px;
  align-items: center;
  justify-content: center;
  align-self: center;
`

const ButtomCover = styled.div`
  flex-direction: row;
  align-items: flex-start;
  align-self:flex-start;
  margin-top: 10px;
  display:flex;
`

const ImageCover = styled.img`
  width: 78px;
  height: 110px;
  justify-content: center;
  align-items: center;
  align-self: center;
`

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.lila};
  opacity: 0.3;
  margin-top: 5px;
`

const MenuExt = styled.div`
  justify-content: flex-start;
  align-items: flex-start;
  display:flex;
  flex-direction:row;
  margin-bottom:10px;
  margin-top: 70px;
`

const MenuExtCont = styled.div`
  justify-content: center;
  align-items: center;
  display:flex;
  flex-direction:column;
  width:30%;
`

const TeacherContainer = styled.div`
  display:flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:100%;
`

const Avatar = styled.img`
  border-radius: 50%;
  overflow: hidden;
  height: 86%;
  width: 86%;
  object-fit: cover;
`
const BorderAvatar = styled.div`
  width: 45px;
  height: 45px;
  background-color: ${({ theme }) => theme.colors.violet};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`

const TeacherNickname = styled.div`
  top:-5px;
  width:80%;
  margin-left:3px;
`

const RatingView = styled.div`
  width:100%;
  flex-direction:row;
  align-items:flex-start;
  display:flex;
`

const Nick = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  width:100%;
  margin-bottom:2px;
`

const Star = styled.img`
  width:12px;
  height:12px;
`

const ViewStar = styled.div`
  width:10%;
  height:auto;
  align-items:center;
  align-self:center;
`

const Rating = ({ num }) => {
  if (num === undefined) { num = 0 }
  let i = 0
  const star = []
  const out = 5 - num
  while (i < parseInt(num)) {
    star.push(<Star src={Stars} key={i + 500} />)
    i++
  }
  i = 0
  while (i < parseInt(out)) {
    star.push(<Star src={Starout} key={i + 500} />)
    i++
  }

  return (
    <RatingView>{
      star.map((item, index) => (
  <ViewStar key={index + 300}>{item}</ViewStar>
      ))
    }
    </RatingView>
  )
}

const Teacher = ({ img, rating, nickname }) => (
  <TeacherContainer>
    <BorderAvatar>
      <Avatar src={`${URI + '/' + img}`} alt='profile' />
    </BorderAvatar>
    <TeacherNickname>
      <Nick>{`@${nickname}`}</Nick>
      <Rating num={rating} />
    </TeacherNickname>
  </TeacherContainer>
)
