import React from 'react'
import { LoaderComponent } from './styles.js'

// Hooks
import useTranslation from '../../i18n'

export const Loader = () => {
  const { localeProvider } = useTranslation()

  return (
    <LoaderComponent>
      {
      localeProvider.name === 'en'
        ? 'Wait a moment...'
        : 'Espera un momento...'
      }
    </LoaderComponent>
  )
}
