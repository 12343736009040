import React from 'react'
import { Nav } from './styles'
import { NavLink } from '../NavLink'

// Hooks
import useTranslation from '../../i18n'

export const NavBar = () => {
  const { localeProvider } = useTranslation()

  return (
    <Nav>
      <NavLink
        textNavLink={localeProvider.name === 'en' ? 'Learn' : 'Aprende'}
        toNavLink='/'
      />
      <NavLink
        textNavLink={localeProvider.name === 'en' ? 'Sessions' : 'Sesiones'}
        toNavLink='/sessions'
      />
      <NavLink
        textNavLink={localeProvider.name === 'en' ? 'Teach' : 'Enseña'}
        toNavLink='/teach/upcoming'
      />
      <NavLink
        textNavLink={localeProvider.name === 'en' ? 'Me' : 'Yo'}
        toNavLink='/me'
      />
    </Nav>
  )
}
