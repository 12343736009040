// import "./i18n";
import React, { useEffect } from 'react'
import I18n from './i18n'
import { useLocalStorage } from '../hooks/useLocalStorage'
import translateOrFallback from './TranslateFallback'
import * as api from '../services/Login'

import moment from 'moment'

const LocaleContext = React.createContext()

export const LocaleContextProvider = props => {
  const [locale, changeLocale] = useLocalStorage('language', { name: 'en' })
  I18n.defaultLocale = 'en'
  I18n.fallbacks = true
  I18n.locale = locale.name
  // moment.locale(locale.name)
  /*
    I18n.translations = {
      en: require("./languages/english.json"),
      es: require("./languages/spanish.json")
    };
  */

  useEffect(() => {
    async function upload () {
      await getAllLocale()
    }
    upload()
  }, [])

  const getAllLocale = async () => {
    console.log('consulting locale')
    try {
      const response = await api.getLocales()
      console.log('locales ready', response.result)
      I18n.translations = response.result
    } catch (error) {
    }
  }

  const _changeLocale = locale => {
    I18n.locale = locale.name
    moment.locale(locale.name)
    changeLocale(locale)
  }

  return (
    <LocaleContext.Provider
      value={{
        ...I18n,
        localeProvider: locale,
        t: translateOrFallback,
        changeLocale: _changeLocale
      }}
    >
      {props.children}
    </LocaleContext.Provider>
  )
}

export default LocaleContext
