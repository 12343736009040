import { useEffect, useState } from 'react'
import http from '../services/DataService'
import { ENDPOINTS } from '../constants'

import { useAppContext } from './useAppContext'

export const useCards = (updater, reload) => {
  const { isLoggin } = useAppContext()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [cards, setCards] = useState([])

  useEffect(async () => {
    if (isLoggin) {
      if (!updater) {
        setError(null)
        setLoading(true)

        try {
          const res = await http.getAll(ENDPOINTS.PAYMENT_METHODS)
          if (res.status === 200) {
            setCards(res.result)
          } else {
            setError(res.message_en)
          }
          setLoading(false)
        } catch (error) {
          setError(error.message)
        }

        setLoading(false)
      }
    }
  }, [updater, reload])

  return { cards, loading, error, setLoading }
}
