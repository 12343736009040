import React, { useState } from 'react'
import styled from 'styled-components'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Ripples from 'react-ripples'
import { ROUTES, URI } from '../../constants'
import http from '../../services/DataService'
import { ButtonAlert } from '../Button'
import { Loader } from '../../components/Loader'
import { useAppContext } from '../../hooks/useAppContext'
import AngleRight from '../../assets/images/angleRight.png'
import ManageDelete from '../../assets/images/manageDelete.png'
import ManageCancel from '../../assets/images/manageCancel.png'
import Close from '../../assets/images/close-circle-outline.png'
import moment from 'moment'
import useTranslation from '../../i18n'
import { useHistory } from 'react-router-dom'

export function MoreOptions ({
  handleReload
}) {
  const { profile, setProfile, setTokenVerify, setToken, setIsLoggin } = useAppContext()
  const [loading, setLoading] = useState(false)
  const [titleModal, setTitleModal] = useState('Main')
  const { t } = useTranslation()
  const history = useHistory()

  const handleLogOut = () => {
    setTokenVerify('')
    setToken('')
    setIsLoggin(false)
    setProfile({})
    history.push('/')
  }

  const handleDelete = async () => {
    setLoading(true)
    try {
      await http.update(ROUTES.DELETE_ACCOUNT)
      setLoading(false)
      // console.log(response)
      handleLogOut()
      // await logoutDirect()
    } catch (error) {
      console.log(error)
      setLoading(false)
      if (error.status === 401) {
        toast.error(error.message_en)
      } else if (error.status === 403) {
        toast.error(error.message_en)
      } else {
        toast.error(error.message)
      }
    }
  }

  const handleCancelated = async () => {
    setLoading(true)
    try {
      const response = await http.update(ROUTES.BUY_MEMBERSHIP)

      response.result.urlProfileImage = response.result.urlProfileImage && `${URI}/${response.result.urlProfileImage}?time=${Date.now()}`
      response.result.tree = response.result.tree && `${URI}/${response.result.tree}?time=${Date.now()}`

      setProfile(response.result)
      handleReload()
      //
      setTitleModal('Main')

      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
      if (error.status === 401) {
        toast.error(error.message_en)
      } else if (error.status === 403) {
        toast.error(error.message_en)
      } else {
        toast.error(error.message)
      }
    }
  }

  return (
    <Container>
      <ToastContainer />
      {loading && <Loader />}
      {(titleModal === 'Main' && !loading) &&
        <>
          <Rippless
            onClick={() => {
              setTitleModal('DeleteAccount')
            }}
            color='#FFFFFF80' during={500}
          >
            <ItemMenu>
              <IconMenu resizeMode='contain' src={ManageDelete} />
              <NameMenu>{t('DeleteAccount')}</NameMenu>
              <IconAngle resizeMode='contain' src={AngleRight} />
            </ItemMenu>
          </Rippless>
          {profile.membership !== 'Free' &&
            <Rippless
              onClick={() => {
                setTitleModal('CancelYourMembership')
              }}
              color='#FFFFFF80' during={500}
            >
              <ItemMenu>
                <IconMenu resizeMode='contain' src={ManageCancel} />
                <NameMenu>{t('CancelYourMembership')}</NameMenu>
                <IconAngle resizeMode='contain' src={AngleRight} />
              </ItemMenu>
            </Rippless>}
        </>}

      {(titleModal !== 'Main' && !loading) &&
        <ContainerModal>
          <HeaderModal>
            <MenuCenter>
              <Title>
                {t(titleModal)}
              </Title>
            </MenuCenter>
            <Menu>
              <div onClick={() => setTitleModal('Main')}>
                <IconClose resizeMode='contain' src={Close} />
              </div>
            </Menu>
          </HeaderModal>
          {titleModal === 'DeleteAccount' &&
            <>
              <AreYouDeleteAccount>{t('AreYouDeleteAccount')}</AreYouDeleteAccount>

              <ContainerButtom>

                <ButtonAlert
                  buttonActive='true'
                  buttonTitle={t('YesDeleteNow')}
                  buttonWidth='170px'
                  buttonHeight='45px'
                  buttonOnClick={() => { handleDelete() }}
                  buttonIconArrowRight='false'
                />
              </ContainerButtom>

              <RememberYouWonNotBeAction>{t('RememberYouWonNotBeAction')}</RememberYouWonNotBeAction>
            </>}

          {titleModal === 'CancelYourMembership' &&
            <>
              <AreYouDeleteAccount>{t('AreYouCancelMembership')}</AreYouDeleteAccount>

              <JustThinkAboutTime>{t('JustThinkAboutTime')} </JustThinkAboutTime>
              <ThereAreManyBenefitsMembership>{t('ThereAreManyBenefitsMembership')}:</ThereAreManyBenefitsMembership>

              <ContainerButtom>

                <ButtonAlert
                  buttonActive='true'
                  buttonTitle={t('YesCancelNow')}
                  buttonWidth='170px'
                  buttonHeight='45px'
                  buttonOnClick={() => { handleCancelated() }}
                  buttonIconArrowRight='false'
                />
              </ContainerButtom>

              <YourCutOffDate>{t('YourCutOffDate')}: {moment(profile.membershipEndDate).format('DD-MM-YYYY')}</YourCutOffDate>
              <MonthlyPaymen>
                {profile.membership === 'Yearly' ? t('YearlyPayment') : t('MonthlyPayment')}: {profile.membershipCost} USD
              </MonthlyPaymen>

              <WhenYouCancelYourMembership>{t('WhenYouCancelYourMembership')} (date).</WhenYouCancelYourMembership>
              <ForMoreInformationPleaseSeeOur>
                {t('ForMoreInformationPleaseSeeOur')}
                <TermsAndConditions href='https://meixter.com/terms/'> {t('TermsAndConditions')}</TermsAndConditions>
              </ForMoreInformationPleaseSeeOur>
            </>}
        </ContainerModal>}
    </Container>
  )
}

// -------cancel------------

const Rippless = styled(Ripples)`
  flex-direction: row;
  justify-content: center;
  width: 95%;
  cursor: pointer;
`

const TermsAndConditions = styled.a`
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  text-align: center;
  justify-content: center;
  align-self: center;
  line-height: 20px;
  /* margin-horizontal: 10px; */
  display:flex;
`

const ForMoreInformationPleaseSeeOur = styled.div`
  color: ${({ theme }) => theme.colors.lilaLight};
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  text-align: center;
  justify-content: center;
  align-self: center;
  line-height: 20px;
  display:flex;
  flex-direction: column;
  /* margin-horizontal: 10px; */
`

const WhenYouCancelYourMembership = styled.div`
  color: ${({ theme }) => theme.colors.lilaLight};
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  text-align: center;
  justify-content: center;
  align-self: center;
  margin-top: 20px;
  line-height: 20px;
  /* margin-horizontal: 10px; */
  display:flex;
`

const JustThinkAboutTime = styled.div`
  color: ${({ theme }) => theme.colors.lilaLight};
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  text-align: center;
  justify-content: center;
  align-self: center;
  margin-top: 20px;
  line-height: 20px;
`

const ThereAreManyBenefitsMembership = styled.div`
  color: ${({ theme }) => theme.colors.lilaLight};
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  text-align: center;
  justify-content: center;
  align-self: center;
  line-height: 20px;
`

const AreYouDeleteAccount = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  text-align: center;
  justify-content: center;
  align-self: center;
  margin-top: 20px;
  width: 230px;
  line-height: 24px;
`

const RememberYouWonNotBeAction = styled.div`
  color: ${({ theme }) => theme.colors.lila};
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  text-align: center;
  justify-content: center;
  align-self: center;
  margin-top: 20px;
`

const YourCutOffDate = styled.div`
  color: ${({ theme }) => theme.colors.lilaLight};
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  text-align: center;
  justify-content: center;
  align-self: center;
  margin-top: 20px;
  line-height: 20px;
`

const MonthlyPaymen = styled.div`
  color: ${({ theme }) => theme.colors.lilaLight};
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  text-align: center;
  justify-content: center;
  align-self: center;
  line-height: 20px;
`
const ContainerButtom = styled.div`
  align-self: center;
  justify-content: center;
  margin-top: 6%;
`

const ContainerModal = styled.div`
  flex: 1;
  align-items: flex-start;
  display:flex;
  flex-direction:column;
  padding:5px;
  height:100%;
  width:100%;
`

const HeaderModal = styled.div`
  height: 50px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color:${({ theme }) => theme.colors.blueDark};
  padding-top: 0px;
  padding-bottom: 10px;
  border-color: ${({ theme }) => theme.colors.lilaLight};
  border-bottom-width: 1px;
  display:flex;
`

const IconClose = styled.img`
  height: 26px;
  justify-content: center;
  align-items: center;
`

const ItemMenu = styled.div`
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 32px;
  display: flex;
  width: 100%;
  cursor:pinter;
`

const IconMenu = styled.img`
  width: 24px;
  height: 24px;
  align-self: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
`

const NameMenu = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  text-align: left;
  font-family: ${({ theme }) => theme.fontFamilyBold};
  align-items: center;
  align-self: center;
  flex: 4;
  cursor:pointer;
`

const IconAngle = styled.img`
  height: 22px;
  width: 18px;
  align-self: center;
  align-items: center;
`

const Container = styled.div`
  background: ${({ theme }) => theme.colors.blueDark};
  align-items: center;
  display: flex;
  flex-direction: column;
  width:100%;
  height:100;
  padding-bottom:10px;
`

const Title = styled.div`
  color: #fff;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fontFamilyMedium};
  margin-top: 10px;
`

const Menu = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
`
const MenuCenter = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  width:100%;
`
