// theme.js

/**
 * ---FontFamily---
 * fontFamilyMedium                 weigth --> higher
 * fontFamilyRegular
 * fontFamilyBold == fontFamily     weigth --> lower
 */

export const darkTheme = {
  backgroundLight: '#070d36',
  backgroundDark: '#0A0B25',
  primaryColor: '#757575',
  secondaryColor: '#00FEFF',
  acentuaryColor: '#E81D80',
  iconColor: '#8d45ff',
  whiteColor: '#fff',
  fontFamily: 'TitilliumWebSemiBold',
  fontFamilyBold: 'TitilliumWebSemiBold',
  fontFamilyMedium: 'TitlingGothicFBNormalMedium',
  fontFamilyRegular: 'TitlingGothicFBRegular',
  colors: {
    transparent: 'rgba(0,0,0,0)',
    background: '#1A1638',
    background2: '#131328',
    transparentLight: 'rgba(0,0,0, 0.2)',
    transparentLightHard: 'rgba(0,0,0, 0.4)',
    transparentBlack: 'rgba(0,0,0, 0.6)',
    transparentBlackHard: 'rgba(0,0,0, 0.8)',
    blueDark: '#0A0C24',
    fucsia: '#E81D80',
    lila: '#6A65A8',
    lilaLight: '#3A385B',
    black: '#000000',
    blackTransparent: '#000000A8',
    white: '#ffffff',
    whiteTrasparent: '#FFFFFF80',
    cyan: '#00FEFF',
    cyanTransparent: '#00FEFFB2',
    violet: '#8D45FF',
    violetDark: '#04051780',
    grayLight: '#3c3e514D',
    gray: '#3c3e51',
    blueSemiDark: '#171533',
    backgroundModal: '#4C4C55',
    backgroundLigth: '#070d36',
    greenActive: '#03E04C',
    yellow: '#E4FF00'
  }
}
