import React from 'react'
import {
  ModalArrowLeft,
  ModalContainer,
  Title,
  MainContainer
} from './styles'

// Components
import { Modal } from '../../components/Modal'
import { CreditCard } from '../CreditCard'

export const CreditCardReadModal = ({
  isOpen,
  onCloseModal,
  title = 'Payment Methods'
}) => {
  const handleBreak = () => {
    onCloseModal()
  }

  return (
    <Modal isOpen={isOpen} onCloseModal={onCloseModal} backgroundColor>
      <ModalArrowLeft height='30px' onClick={handleBreak} />
      <ModalContainer>
        <Title><h2>{title}</h2></Title>
        <MainContainer>
          <CreditCard />
        </MainContainer>
      </ModalContainer>
    </Modal>
  )
}
