import React, { useEffect, useState } from 'react'
import { LanguageComponent, ItemLanguage, IconCheckContent, IconCheck, TextLanguage } from './styles'

// Apps
import { toast } from 'react-toastify'

// Components
import { DrawMenu } from '../DrawMenu'

// Icons
import { FlagUSA } from '../../assets/static/Flags'
import FlagSpanish from '../../assets/images/flag-spanish.jpg'
import ISuccess from '../../assets/images/success.png'
import ICheckOut from '../../assets/images/checkOut.png'

// Hooks
import { useAppContext } from '../../hooks/useAppContext'
import useTranslation from '../../i18n'

// Services
import http from '../../services/DataService'
import { updateProfile } from '../../services/User'
import { ROUTES } from '../../constants'

// Context
import { useLessonsDispatch } from '../../hooks/useLessonsDispatch'
import { getMainLessonsChangeLanguage } from '../../context/Lesssons/actions'

export const Language = () => {
  const { profile: { adv }, setProfile } = useAppContext()
  const dispatch = useLessonsDispatch()
  const { changeLocale } = useTranslation()
  const [languages, setLanguages] = useState([])
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    (async function () {
      try {
        const resLanguage = await http.getAll(ROUTES.CONFIG_LANGUAGE)
        const filteredLanguages = resLanguage.result.filter(item => item.complete === 100)
        setLanguages(filteredLanguages)
      } catch (error) {
        // Error
      }
    })()
  }, [isOpen])

  const handleOpenModal = () => {
    setIsOpen(!isOpen)
  }

  const getLanguage = (name) => {
    if (adv === 'es' && name === 'English') {
      return 'Inglés'
    } else if (adv === 'es' && name === 'Spanish') {
      return 'Español'
    } else {
      return name
    }
  }

  const updateLanguage = async (item) => {
    try {
      if (adv !== item.adv) {
        // console.log('---update---')
        const data = {
          type: 'language',
          value: item._id
        }
        const res = await updateProfile(data)

        if (res.status === 200) {
          setProfile(res.result)
          changeLocale({ name: res.result.adv })
          getMainLessonsChangeLanguage(dispatch, item._id)
        }
      }
    } catch (error) {
      if (error.status === 401) {
        toast.error(error.message_en)
      } else if (error.status === 403) {
        toast.error(error.message_en)
      } else {
        toast.error(error.message)
      }
    }
  }

  return (
    <LanguageComponent onClick={handleOpenModal}>
      {
        adv === 'en'
          ? <FlagUSA width='26px' />
          : <img src={FlagSpanish} width='26px' />
      }
      <span>{adv === 'en' ? 'English' : 'Español'}</span>
      <DrawMenu
        isOpen={isOpen}
        height='80px'
        width='150px'
        opacity={'0.1'}
      >
        <ul>
          {
            languages.map((item) => (
              <ItemLanguage key={item._id} onClick={() => updateLanguage(item)}>
                <IconCheckContent>
                  {
                    adv === item.adv
                      ? <IconCheck src={ISuccess} width='18px' height='18px' />
                      : <IconCheck src={ICheckOut} width='20px' height='20px' />
                  }
                </IconCheckContent>
                <TextLanguage>
                  {getLanguage(item.name)}
                </TextLanguage>
              </ItemLanguage>
            ))
          }
        </ul>
      </DrawMenu>
    </LanguageComponent>
  )
}
