import React from 'react'
import { RiInstagramLine, RiFacebookFill, RiTwitterFill, RiYoutubeFill } from 'react-icons/ri'
import { SocialComponent, A } from './styles'

export const Social = () => {
  return (
    <SocialComponent>
      <A href='https://www.instagram.com/meixterapp/' target='noreferrer'>
        <RiInstagramLine size='16px' />
      </A>
      <A href='https://www.facebook.com/meixterapp' target='noreferrer'>
        <RiFacebookFill size='16px' />
      </A>
      <A href='https://twitter.com/meixterapp' target='noreferrer'>
        <RiTwitterFill size='16px' />
      </A>
      <A href='https://youtube.com/channel/UCwwoj6fuAIrrEvKcL1xSEXQ' target='noreferrer'>
        <RiYoutubeFill size='16px' />
      </A>
    </SocialComponent>
  )
}
