import styled from 'styled-components'

export const FooterComponent = styled.footer`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 40px;
  width: 100%;
  height: 25px;
  background-color: #00020f;
  z-index: 1;

  @media only screen and (min-width: 769px) {
    display: flex;
  }
`

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 500px;
  height: 100%;
`
