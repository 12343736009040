import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from '../Button'
import { useAppContext } from '../../hooks/useAppContext'
import Co2 from '../../assets/images/co2.png'
import EdenProjectsFullWhite from '../../assets/images/EdenProjectsFull_White.png'
import Elipse25 from '../../assets/images/Elipse25.png'
import Close from '../../assets/images/close-circle-outline.png'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import { MyWallet } from '../../container/MyWallet'
import useTranslation from '../../i18n'

export function MyTree () {
  const { profile } = useAppContext()
  const [typeModal, setTypeModal] = useState('Tree')
  const { t } = useTranslation()

  const openModal = (type) => {
    setTypeModal(type)
  }

  return (
    <Container>
      {(typeModal === 'Tree') &&
        <Body>
          <YourTreeGrowth>{t('YourTreeGrowth')}</YourTreeGrowth>
          <PanelTree>
            <IconTree resizeMode='cover' src={profile.tree} />
            <PanelTreeInfo>
              <PorcentTreeGrowth>{profile.growingtree}%</PorcentTreeGrowth>
              <TreeGrowth>{t('TreeGrowth')}</TreeGrowth>
              <div onClick={() => openModal('BuyMembership')}>
                <Faster>x3 {t('Faster')}</Faster>
              </div>
              <NumberTreePlanted>{profile.plantedTrees}</NumberTreePlanted>
              <TreePlanted>{t('TreePlanted')}</TreePlanted>
            </PanelTreeInfo>
          </PanelTree>

          <ContainerButtom>
            <Button
              buttonActive='true'
              buttonTitle={t('LearnMore')}
              buttonWidth='170px'
              buttonHeight='45px'
              buttonOnClick={() => { openModal('LearnMore') }}
            />
          </ContainerButtom>

          <EnviromentalFootprint>{t('EnviromentalFootprint')}</EnviromentalFootprint>
          <GoPremiumAndPlant>{t('GoPremiumAndPlant')}</GoPremiumAndPlant>

          <ImgProgress>
            <ImageProgress resizeMode='contain' src={Co2} />
            <Circule>
              <CircularProgressbarWithChildren
                value={profile.co2}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 1,
                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: 'butt',
                  // Text size
                  textSize: '16px',
                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,
                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',
                  // Colors
                  pathColor: '#00FEFF',
                  trailColor: '#6A65A8',
                  backgroundColor: '#00FEFF'
                })}
              >
                {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                <PorcentCO>{profile.co2}%</PorcentCO>
                <Co>CO<COMini>2</COMini></Co>
              </CircularProgressbarWithChildren>
            </Circule>
          </ImgProgress>
          <YourEnvironmentalFootprint>{t('YourEnvironmentalFootprint')}</YourEnvironmentalFootprint>

        </Body>}
      {(typeModal === 'BuyMembership' || typeModal === 'LearnMore') &&
        <ContainerModal>
          <HeaderModal>
            <Menu />
            <MenuCenter>
              <Title>
                {typeModal === 'BuyMembership' ? 'Buy Membership' : 'Reforestation project'}
              </Title>
            </MenuCenter>
            <Menu>
              <div onClick={() => { openModal('Tree') }}>
                <IconClose resizeMode='contain' src={Close} />
              </div>
            </Menu>
          </HeaderModal>
          {typeModal === 'LearnMore'

            ? <Body>
              <EdenImage resizeMode='contain' src={EdenProjectsFullWhite} />
              <AtEdenReforestationProject>{t('AtEdenReforestationProject')}:</AtEdenReforestationProject>

              <PanelTextModal>
                <IconPoint resizeMode='contain' src={Elipse25} />
                <TextPoints>{t('PlantedOverMillion')}</TextPoints>
              </PanelTextModal>

              <PanelTextModal>
                <IconPoint resizeMode='contain' src={Elipse25} />
                <TextPoints>{t('OurNationalLeaders')}</TextPoints>
              </PanelTextModal>

              <PanelTextModal>
                <IconPoint resizeMode='contain' src={Elipse25} />
                <TextPoints>{t('WeThenHireAndTrain')}</TextPoints>
              </PanelTextModal>

              <PanelTextModal>
                <IconPoint resizeMode='contain' src={Elipse25} />
                <TextPoints>{t('TheyPlantNativeTreeSpecies')}</TextPoints>
              </PanelTextModal>

              <PanelTextModal>
                <IconPoint resizeMode='contain' src={Elipse25} />
                <TextPoints>{t('ThenVillagesProtect')}</TextPoints>
              </PanelTextModal>

              <PanelTextModal>
                <IconPoint resizeMode='contain' src={Elipse25} />
                <TextPoints>{t('TopTierSeeding')}</TextPoints>
              </PanelTextModal>
            </Body>
            : <MyWallet handleChangeWithDrawal={() => {}} treeView='MyTree' />}
        </ContainerModal>}
    </Container>
  )
}

const Circule = styled.div`
  width:52%;
  height:52%;
  align-self:center;
  position:absolute;
  margin-left: 55px;
  margin-top: -5px;
`

const PanelTextModal = styled.div`
  display:flex;
  flex-direction: row;
  margin-bottom: 20px;
`

const IconPoint = styled.img`
  height: 18px;
  margin-right:4px;
`

const TextPoints = styled.div`
  color: ${({ theme }) => theme.colors.whiteTrasparent};
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  text-align: left;
  line-height: 20px;
  flex: 7;
`

const AtEdenReforestationProject = styled.div`
  color: ${({ theme }) => theme.colors.whiteTrasparent};
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  line-height: 20px;
  margin-bottom: 30px;
`

const EdenImage = styled.img`
  width: 250px;
  height: 86px;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-bottom: 30px;
  margin-top:10px;
`
const YourEnvironmentalFootprint = styled.div`
  color: ${({ theme }) => theme.colors.whiteTrasparent};
  font-size: 10px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  line-height: 20px;
  width: 95%;
  margin-bottom:10px;
`

const ImgProgress = styled.div`
  height: 230px;
  margin-top:20px;
  margin-bottom:10px;
  display:flex;
  align-self:center;
  align-items:center;
  position:relative;
`

const ImageProgress = styled.img`
  height: 230px;
  justify-content: center;
  align-items: center;
  align-self: center;
  position: relative;
  /*margin-top:24px;*/
`

const Co = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontFamilyMedium};
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  display:flex;
`

const COMini = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 10px;
  font-family: ${({ theme }) => theme.fontFamilyMedium};
`

const PorcentCO = styled.div`
  color: ${({ theme }) => theme.colors.cyan};
  text-shadow: 0px 0px 5px ${({ theme }) => theme.colors.cyan};
  font-size: 18px;
  font-family: ${({ theme }) => theme.fontFamilyMedium};
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
`

const EnviromentalFootprint = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 15px;
  font-family: ${({ theme }) => theme.fontFamilyMedium};
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  margin-top: 20px;
`

const GoPremiumAndPlant = styled.div`
  color: ${({ theme }) => theme.colors.whiteTrasparent};
  font-size: 10px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  line-height: 30px;
`

const NumberTreePlanted = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 18px;
  font-family: ${({ theme }) => theme.fontFamilyMedium};
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  margin-top: 20px;
`
const TreePlanted = styled.div`
  color: ${({ theme }) => theme.colors.lila};
  font-size: 10px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  line-height: 10px;
`
const PorcentTreeGrowth = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 17px;
  font-family: ${({ theme }) => theme.fontFamilyMedium};
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
`

const TreeGrowth = styled.div`
  color: ${({ theme }) => theme.colors.lila};
  font-size: 10px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  line-height: 10px;
`

const Faster = styled.div`
  color: ${({ theme }) => theme.colors.cyan};
  text-shadow: 0px 0px 5px ${({ theme }) => theme.colors.cyan};
  font-size: 11px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  line-height: 10px;
  margin-top:10px;
  cursor:pointer;
`
const IconTree = styled.img`
  height: 177px;
  max-width: 187px;
  justify-content: center;
  align-items: center;
  align-self: center;
  opacity: 0.8;
  flex: 1;
`

const YourTreeGrowth = styled.div`
  color: ${({ theme }) => theme.colors.lila};
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamilyMedium};
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  margin-top: 20px;
`

const PanelTree = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display:flex;
`

const PanelTreeInfo = styled.div`
  flex-direction: column;
  display:flex;
  padding:4px;
`

const ContainerModal = styled.div`
  flex: 1;
  align-items: flex-start;
  display:flex;
  justify-content:center;
  flex-direction:column;
  padding:5px;
  height:100%;
  width:100%;
`

const HeaderModal = styled.div`
  height: 40px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display:flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lila};
`

const IconClose = styled.img`
  height: 20px;
  justify-content: center;
  align-items: center;
  cursor:pointer;
`

// body
const ContainerButtom = styled.div`
  align-self: center;
  justify-content: center;
  align-items:center;
  margin-bottom: 20px;
  margin-top: 10px;
`

const Container = styled.div`
  display:flex;
  background: ${({ theme }) => theme.backgroundDark};
  align-items: center;
  height:100%;
  width:100%;
  padding:10px;
`

const Body = styled.div`
  background: ${({ theme }) => theme.backgroundDark};
  flex-direction: column;
  display:flex;
  width:100%;
`

const Title = styled.div`
  color: #fff;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fontFamilyMedium};
  margin-top: 10px;
`

const Menu = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1;
`

const MenuCenter = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 5;
  height: 40px;
`
