import styled from 'styled-components'

export const CreditCardItemWrapper = styled.article`
  height: 40px;
  width: 240px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 18% 22% 28% 16% 16%;
  grid-template-areas: "card-check card-icon card-number card-edit card-delete";
`

const ItemCard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CheckContainer = styled(ItemCard)`
  grid-area: card-check;

  & button {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
`

export const CardIconConteiner = styled(ItemCard)`
  grid-area: card-icon;
`

export const CardNumberConteiner = styled(ItemCard)`
  grid-area:  card-number;

  & h3 {
    font-size: 14px;
    font-family: ${({ theme }) => theme.fontFamilyRegular};
    color: ${({ theme }) => theme.colors.white};
  }
`

export const EditConteiner = styled(ItemCard)`
  grid-area: card-edit;

  & button {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
`

export const DeleteConteiner = styled(ItemCard)`
  grid-area: card-delete;

  & button {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
`
