
import { LOCALES } from '../constants/index'

const I18n = {}
// default locale
I18n.defaultLocale = LOCALES.ENGLISH.name

// Enable fallbacks  to `en`
I18n.fallbacks = true

// current locale
I18n.locale = LOCALES.ENGLISH.name

I18n.translations = {
  en: {}// require("./languages/english.json"),
  // es: //require("./languages/spanish.json")
}

I18n.t = (data) => {
  if (I18n.translations[I18n.locale][data] === undefined) { return data }
  return I18n.translations[I18n.locale][data]
}

export default I18n
