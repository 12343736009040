import styled from 'styled-components'
import { device } from '../../styles/device'

export const HeaderComponent = styled.header`
  position: fixed;
  display: grid;
  grid-template-columns: 24% 1fr 24%;
  grid-template-rows: 56px;
  grid-template-areas: "controls logo user";
  width: 100%;
  top: 0;
  color: ${props => props.theme.whiteColor};
  background-color: ${props => props.theme.backgroundLight};

  @media ${device.tablet} {
    justify-content: space-evenly;
    grid-template-columns: 28% 1fr 28%;
  }
`

export const UserDisplay = styled.div`
  grid-area: user;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.tablet} {
    display:none;
  }
`
