import styled from 'styled-components'
import {
  fontStyleTitle,
  fontStyleContent
} from '../../styles/general'
import { ArrowLeft } from '../../assets/static/Arrow'

export const ModalArrowLeft = styled(ArrowLeft)`
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
`

const ContainerModal = styled.section`
  height: 410px;
  width: 550px;
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.backgroundDark};
`

export const MainContent = styled(ContainerModal)`
  display: grid;
  grid-template-rows: 10% 90%;
  grid-template-columns: 1fr;
  grid-template-areas:  "title"
                        "content";
`

export const Title = styled.section`
  grid-area: title;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  & h2{
    ${fontStyleTitle};
    font-size: 16px;
  }
`

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  grid-area: content;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr;
  grid-template-areas:  "main-message"
                        "form-report";
`

export const MainMessage = styled.div`
  grid-area: main-message;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & p{
    ${fontStyleContent};
    color: ${({ theme }) => theme.colors.lilaLight};
    text-align: center;
  }
`

export const FormContent = styled.form`
  width: 100%;
  height: 100%;
  grid-area: form-report;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`

export const FormItem = styled.div`
  height: ${({ height }) => height || '70px'};
  /* height: 100px; */
  width: 100%;
  display: grid;
  grid-template-rows: 44% 56%;
  grid-template-columns: 1fr;
  grid-template-areas:  "title-item"
                        "input-item";
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
`

export const FormItemTitle = styled.div`
  grid-area: title-item;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & h3 {
    ${fontStyleContent};
    color: ${({ theme }) => theme.colors.fucsia};
    font-size: 16px;
  }
`

export const FormItemInput = styled.div`
  grid-area: input-item;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & input {
    ${fontStyleContent};
    font-size: 15px;
    border: 0;
    outline: none;
    margin: none;
    height: 100%;
    width: 100%;
    background-color: transparent;
  }

  & textarea{
    height: 100%;
    width: 100%;
    ${fontStyleContent};
    background-color: transparent;
    font-size: 16px;
    border: 0;
    margin: none;
    outline: none;

    &::-webkit-input-placeholder { /* Edge */
      text-align: center;
      line-height: 25px;
      ${({ theme }) => theme.colors.gray};
    }

    &::-ms-input-placeholder { /* Internet Explorer */
      text-align: center;
      line-height: 25px;
      ${({ theme }) => theme.colors.gray};
    }

    &::placeholder {
      text-align: center;
      line-height: 25px;
      ${({ theme }) => theme.colors.gray};
    }
  }
`

export const ContentSucces = styled(ContentContainer)`
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:  "succes-content";
`

export const SuccesContent = styled.div`
  grid-area: succes-content;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;

  & h2{
    ${fontStyleTitle};
    font-size: 22px;
  }

  & p{
    ${fontStyleContent};
    color: ${({ theme }) => theme.colors.lila}
  }
`

export const TicketContent = styled.div`
  height: 65px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & h1{
    ${fontStyleTitle};
    font-size: 20px;
  }

  & p{
    ${fontStyleContent};
  }
`
