import styled, { keyframes } from 'styled-components'
import { scrollBar } from '../../styles/general'

const animationCountriesList = (height) => keyframes`
  from {
    height: 0;
  }
  to {
    height: ${height};
  }
`

export const ModalComponent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: ${({ opacity }) => `rgba(0, 0, 0, ${opacity})`};
`

export const DrawMenuWrapper = styled.section`
  position: fixed;
  top: 60px;
  left: 77%;
  z-index: 8;

  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  background-color: ${({ theme }) => theme.colors.backgroundLigth};
  animation: ${({ height }) => animationCountriesList(height)} 0.3s alternate;
`

export const ContentMenu = styled.div`
  position: absolute;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  z-index: 9;
  width: 100%;
  height: 100%;

  overflow-y: scroll;
  ${scrollBar}
`
