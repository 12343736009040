import React, { useState, useRef, Fragment } from 'react'
import styled from 'styled-components'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Ripples from 'react-ripples'
import { ROUTES, URI, ENDPOINTS } from '../../constants'
import http from '../../services/DataService'
import { Button } from '../Button'
import { Loader } from '..//Loader'
import { ProgressCircle } from '../ProgressCircle'
import { useAppContext } from '../../hooks/useAppContext'
import AngleRight from '../../assets/images/angleRight.png'
import ManageAccount from '../../assets/images/manageAccount.png'
import ManageNickname from '../../assets/images/manageNickname.png'
import ManagePhone from '../../assets/images/managePhone.png'
import ReportProblem from '../../assets/images/reportProblem.png'
import MoreOptions from '../../assets/images/moreOptions.png'
import Success from '../../assets/images/success.png'
import CheckOut from '../../assets/images/checkOut.png'
import Close from '../../assets/images/close-circle-outline.png'
import Email from '../../assets/images/email.png'
import Phone from '../../assets/images/phone.png'
import IconNames from '../../assets/images/iconName.png'
import IconNickname from '../../assets/images/iconNickname.png'
import Gallery from '../../assets/images/gallery.png'
import Camera from '../../assets/images/camera.png'
import Componente31 from '../../assets/images/Componente 3 – 1.png'
import {
  ChangeImage
} from '../../container/Login/styles'
import useTranslation from '../../i18n'

// Resources
import IInstagram from '../../assets/images/manageInstagram.png'

// Helper
import { ACCEPTABLE_IMG_TYPES, getBase64 } from '../../helpers/getBase64'

export function Account ({ handleChangeWithDrawal, handleReload }) {
  const { profile, setProfile } = useAppContext()
  const [titleModal, setTitleModal] = useState('Main')
  const [verifyNick, setVerifyNick] = useState(false)
  const [loading, setLoading] = useState(false)
  const [verifyPhone, setVerifyPhone] = useState(false)
  const [filepreview, setFilePreview] = useState(profile.urlProfileImage)
  const inputFilePreview = useRef(null)
  const [params, setParams] = useState({
    bio: profile.bio,
    fullName: profile.fullName,
    nickname: profile.nickname,
    phone: profile.phone,
    email: profile.email || '',
    instagram: profile.instagram || ''
  })
  const { t } = useTranslation()
  const [error, setError] = useState({
    bio: '',
    fullName: '',
    nickname: '',
    phone: '',
    email: '',
    instagram: ''
  })

  const onChangeImagePreview = async (event) => {
    if (ACCEPTABLE_IMG_TYPES.includes(event.target.files[0].type)) {
      try {
        setLoading(true)
        const b64 = await getBase64(event.target.files[0])
        const data = {
          register: true,
          profileImage: b64
        }

        const res = await http.create(ENDPOINTS.UPLOADIMAGE, data)
        if (res.status === 200) {
          setFilePreview(URL.createObjectURL(event.target.files[0]))
          res.result.urlProfileImage = res.result.urlProfileImage && `${URI}/${res.result.urlProfileImage}?time=${Date.now()}`
          res.result.tree = res.result.tree && `${URI}/${res.result.tree}?time=${Date.now()}`
          setProfile(res.result)
          handleReload()
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        if (error.status) {
          toast.error(error.error.message_en)
        }
      }
    } else {
      toast.error(
        profile.adv === 'en'
          ? 'The format is not allowed'
          : 'El formato no esta permitido'
      )
    }
  }

  const _handleGender = async (gender) => {
    setLoading(true)
    try {
      const data = {
        type: 'gender',
        value: gender.toLowerCase()
      }
      // let response = await api.profileUpdate(data)
      const response = await http.update(ROUTES.UPDATE_PROFILE, data)
      response.result.urlProfileImage = response.result.urlProfileImage && `${URI}/${response.result.urlProfileImage}`
      response.result.tree = response.result.tree && `${URI}/${response.result.tree}`
      setProfile(response.result)
      setLoading(false)
      toast.success('updated successfully')
      // closeModal()
    } catch (error) {
      setLoading(false)

      if (error.status === 401) {
        toast.error(error.message_en)
      } else if (error.status === 403) {
        toast.error(error.message_en)
      } else {
        toast.error(error.message)
      }
    }
  }

  const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

  const validate = (type) => {
    if (params[type] === '') {
      setError(prevState => ({ ...prevState, [type]: 'enter data' }))
      toast.error('enter data')
      return false
    }
    if (type === 'email' && !regexEmail.test(params[type])) {
      setError(prevState => ({ ...prevState, [type]: 'invalid mail format' }))
      toast.error('invalid mail format')
      return false
    }

    setError(prevState => ({ ...prevState, [type]: '' }))
    return true
  }

  const _handleUpdate = async (type) => {
    if (validate(type)) {
      try {
        setLoading(true)
        const data = {
          type: type,
          value: params[type]
        }

        // let response = await api.profileUpdate(data)
        const response = await http.update(ROUTES.UPDATE_PROFILE, data)
        response.result.urlProfileImage = response.result.urlProfileImage && `${URI}/${response.result.urlProfileImage}`
        response.result.tree = response.result.tree && `${URI}/${response.result.tree}`
        setProfile(response.result)
        handleReload()
        setError(prevState => ({ ...prevState, [type]: '' }))
        setLoading(false)
        toast.success('updated successfully')
        setTitleModal('main')
      } catch (error) {
        setLoading(false)
        if (error.status === 401) {
          toast.error(error.message_en)
        } else if (error.status === 403) {
          toast.error(error.message_en)
        } else {
          toast.error(error.message)
        }
      }
    }
  }

  const handleNickname = async () => {
    setLoading(true)
    try {
      const data = {
        nickname: params.nickname
      }
      // let response = await api.verifyNickname(data);
      const response = await http.create(ROUTES.VERIFYNICK, data)
      setLoading(false)
      setVerifyNick(response.result.perm)
      if (!response.result.perm) { setError(prevState => ({ ...prevState, nickname: 'nickname in use' })) } else { setError(prevState => ({ ...prevState, nickname: '' })) }
    } catch (error) {
      setLoading(false)
      setVerifyNick(false)
      if (error.status === 401) {
        setError(prevState => ({ ...prevState, nickname: error.message_en }))
        toast.error(error.message_en)
      } else if (error.status === 403) {
        toast.error(error.message_en)
      } else {
        toast.error(error.message)
      }
    }
  }

  const handlePhone = async () => {
    setLoading(true)
    try {
      const data = {
        phone: params.phone
      }
      // let response = await api.verifyPhone(data);
      const response = await http.getAll(ROUTES.VERIFY_PHONE, data)
      setLoading(false)
      setVerifyPhone(response.result.perm)
      if (!response.result.perm) { setError(prevState => ({ ...prevState, phone: 'number in use' })) } else { setError(prevState => ({ ...prevState, phone: '' })) }
    } catch (error) {
      setLoading(false)
      setVerifyPhone(false)

      if (error.status === 401) {
        setError(prevState => ({ ...prevState, phone: error.message_en }))
        toast.error(error.message_en)
      } else if (error.status === 403) {
        toast.error(error.message_en)
      } else {
        toast.error(error.message)
      }
    }
  }

  return (
    <Container>
      <ToastContainer />
      {loading && <Loader />}
      {(titleModal === 'Main' && !loading) &&
        <>
          <Rippless
            onClick={() => {}}
            color='#FFFFFF80' during={500}
            avatar
          >
            <ProgressCircle percentil='100%'>
              <ChangeImage
                type='file'
                ref={inputFilePreview}
                filepreview={filepreview}
                Componente31={Componente31}
              >
                <input
                  type='file'
                  accept='image/png, image/jpg, image/jpeg'
                  ref={inputFilePreview}
                  onChange={onChangeImagePreview}
                />
              </ChangeImage>
            </ProgressCircle>

          </Rippless>
          <PanelSexo>
            <div style={{ flex: 1 }} onClick={() => _handleGender('male')}>
              <TextSexo active={profile.gender === 'male'}>{t('man')}</TextSexo>
            </div>
            <div style={{ flex: 1 }} onClick={() => _handleGender('female')}>
              <TextSexo active={profile.gender === 'female'}>{t('woman')}</TextSexo>
            </div>
            <div style={{ flex: 1 }} onClick={() => _handleGender('other')}>
              <TextSexo active={profile.gender === 'other'}>{t('other')}</TextSexo>
            </div>
          </PanelSexo>
          <Rippless
            onClick={() => setTitleModal('registerYourBio')}
            color='#FFFFFF80' during={500}
          >
            <ItemMenu>
              <IconMenu resizeMode='contain' src={ManageAccount} />
              <NameMenu>{t('registerYourBio')}</NameMenu>
              <IconAngle resizeMode='contain' src={AngleRight} />
            </ItemMenu>
          </Rippless>
          <Rippless
            onClick={() => setTitleModal('registerYourName')}
            color='#FFFFFF80' during={500}
          >
            <ItemMenu>
              <IconMenu resizeMode='contain' src={ManageAccount} />
              <NameMenu>{t('registerYourName')}</NameMenu>
              <IconAngle resizeMode='contain' src={AngleRight} />
            </ItemMenu>
          </Rippless>
          <Rippless
            onClick={() => setTitleModal('YourNickname')}
            color='#FFFFFF80' during={500}
          >
            <ItemMenu>
              <IconMenu resizeMode='contain' src={ManageNickname} />
              <NameMenu>{t('YourNickname')}</NameMenu>
              <IconAngle resizeMode='contain' src={AngleRight} />
            </ItemMenu>
          </Rippless>
          <Rippless
            onClick={() => setTitleModal('instagram')}
            color='#FFFFFF80' during={500}
          >
            <ItemMenu>
              <IconMenu resizeMode='contain' src={IInstagram} />
              <NameMenu>Instagram</NameMenu>
              <IconAngle resizeMode='contain' src={AngleRight} />
            </ItemMenu>
          </Rippless>
          <Rippless
            onClick={() => setTitleModal('PhoneNumber')}
            color='#FFFFFF80' during={500}
          >
            <ItemMenu>
              <IconMenu resizeMode='contain' src={ManagePhone} />
              <NameMenu>{t('PhoneNumber')}</NameMenu>
              <IconAngle resizeMode='contain' src={AngleRight} />
            </ItemMenu>
          </Rippless>
          <Rippless
            onClick={() => setTitleModal('YourEmail')}
            color='#FFFFFF80' during={500}
          >
            <ItemMenu>
              <IconMenu resizeMode='contain' src={ReportProblem} />
              <NameMenu>{t('YourEmail')}</NameMenu>
              <IconAngle resizeMode='contain' src={AngleRight} />
            </ItemMenu>
          </Rippless>
          <Rippless
            onClick={() => handleChangeWithDrawal()}
            color='#FFFFFF80' during={500}
          >
            <ItemMenu>
              <IconMenu resizeMode='contain' src={MoreOptions} />
              <NameMenu>{t('MoreOptions')}</NameMenu>
              <IconAngle resizeMode='contain' src={AngleRight} />
            </ItemMenu>
          </Rippless>
        </>}
      {(titleModal !== 'Main' && !loading) &&
        <ContainerModal>
          <HeaderModal>
            <MenuCenter>
              <Title>
                {t(titleModal)}
              </Title>
            </MenuCenter>
            <Menu>
              <div onClick={() => setTitleModal('Main')}>
                <IconClose resizeMode='contain' src={Close} />
              </div>
            </Menu>
          </HeaderModal>

          {titleModal === 'Your Photo' &&
            <>
              <ContainerIconos>
                <PanelIconos onClick={() => {}}>
                  <IconCam resizeMode='contain' src={Camera} />
                  <TextIcon>{t('camera')}</TextIcon>
                </PanelIconos>
                <PanelIconos onClick={() => {}}>
                  <IconGalery resizeMode='contain' src={Gallery} />
                  <TextIcon>{t('gallery')}</TextIcon>
                </PanelIconos>
              </ContainerIconos>
              <ChooseTheWayToUploadYourProfilePhoto>{t('ChooseTheWayToUploadYourProfilePhoto')}</ChooseTheWayToUploadYourProfilePhoto>
            </>}
          {
            titleModal === 'registerYourBio' &&
              <>
                <ContainerInput>
                <IconName resizeMode='contain' src={IconNames} />
                <InputFullname
                  error={error.bio}
                  placeholder={t('registerYourBio')}
                  type='text'
                  value={params.bio}
                  onChange={(data) => { setParams(prevState => ({ ...prevState, bio: data.target.value })) }}
                />
                <Div />
              </ContainerInput>
              {error.bio !== '' && <ErrorText>{error.bio}</ErrorText>}
              <ContainerButtom>
                <Button
                  buttonActive='true'
                  buttonTitle={t('Update')}
                  buttonWidth='170px'
                  buttonHeight='45px'
                  buttonOnClick={() => { _handleUpdate('bio') }}
                />
              </ContainerButtom>
              </>
          }
          {titleModal === 'registerYourName' &&
            <>
              <ContainerInput>
                <IconName resizeMode='contain' src={IconNames} />
                <InputFullname
                  error={error.fullName}
                  placeholder={t('registerYourName')}
                  type='text'
                  value={params.fullName}
                  onChange={(data) => { setParams(prevState => ({ ...prevState, fullName: data.target.value })) }}
                />
                <Div />
              </ContainerInput>
              {error.fullName !== '' && (<ErrorText>{error.fullName}</ErrorText>)}

              <ContainerButtom>
                <Button
                  buttonActive='true'
                  buttonTitle={t('Update')}
                  buttonWidth='170px'
                  buttonHeight='45px'
                  buttonOnClick={() => { _handleUpdate('fullName') }}
                />
              </ContainerButtom>
            </>}

            {titleModal === 'instagram' &&
            <>
              <ContainerInput>
                <IconName resizeMode='contain' src={IInstagram} />
                <InputFullname
                  error={error.instagram}
                  placeholder={t('instagram')}
                  type='text'
                  value={params.instagram}
                  onChange={(data) => { setParams(prevState => ({ ...prevState, instagram: data.target.value })) }}
                />
                <Div />
              </ContainerInput>
              {error.instagram !== '' && (<ErrorText>{error.instagram}</ErrorText>)}

              <ContainerButtom>
                <Button
                  buttonActive='true'
                  buttonTitle={t('Update')}
                  buttonWidth='170px'
                  buttonHeight='45px'
                  buttonOnClick={() => { _handleUpdate('instagram') }}
                />
              </ContainerButtom>
            </>}

          {titleModal === 'YourNickname' &&
            <>
              <ContainerInput>
                <IconName resizeMode='contain' src={IconNickname} />
                <InputFullname
                  error={error.nickname}
                  placeholder={t('YourNickname')}
                  value={params.nickname}
                  onChange={(data) => {
                    setVerifyNick(false)
                    setParams(prevState => ({ ...prevState, nickname: data.target.value }))
                  }}
                  onBlur={() => handleNickname()}
                />
                <div style={{ flex: 1 }} onClick={() => handleNickname()}>
                  <IconCheck
                    resizeMode='contain' src={verifyNick ? Success : CheckOut}
                  />
                </div>
              </ContainerInput>
              {error.nickname !== '' && (<ErrorText>{error.nickname}</ErrorText>)}

              {verifyNick &&
                <ContainerButtom>

                  <Button
                    buttonActive='true'
                    buttonTitle={t('Update')}
                    buttonWidth='170px'
                    buttonHeight='45px'
                    buttonOnClick={() => { _handleUpdate('nickname') }}
                  />
                </ContainerButtom>}
            </>}

          {titleModal === 'PhoneNumber' &&
            <>
              <ContainerInput>
                <IconName resizeMode='contain' src={Phone} />
                <InputFullname
                  error={error.phone}
                  placeholder={t('PhoneNumber')}
                  value={params.phone}
                  onChange={(data) => {
                    setVerifyPhone(false)
                    setParams(prevState => ({ ...prevState, phone: data.target.value }))
                  }}
                  onBlur={() => handlePhone()}
                />
                <div style={{ flex: 1 }} onClick={() => handlePhone()}>
                  <IconCheck
                    resizeMode='contain' src={verifyPhone ? Success : CheckOut}
                  />
                </div>
              </ContainerInput>
              {error.phone !== '' && (<ErrorText>{error.phone}</ErrorText>)}

              {verifyPhone &&
                <ContainerButtom>
                  <Button
                    buttonActive='true'
                    buttonTitle={t('Update')}
                    buttonWidth='170px'
                    buttonHeight='45px'
                    buttonOnClick={() => { _handleUpdate('phone') }}
                  />
                </ContainerButtom>}
            </>}

          {titleModal === 'YourEmail' &&
            <>
              <ContainerInput>
                <IconName resizeMode='contain' src={Email} />
                <InputFullname
                  error={error.email}
                  placeholder='name@email.com'
                  value={params.email}
                  onChange={(data) => {
                    setParams(prevState => ({ ...prevState, email: data.target.value }))
                  }}
                />
              </ContainerInput>
              {error.email !== '' && (<ErrorText>{error.email}</ErrorText>)}
              <ContainerButtom>
                <Button
                  buttonActive='true'
                  buttonTitle={t('Update')}
                  buttonWidth='170px'
                  buttonHeight='45px'
                  buttonOnClick={() => { _handleUpdate('email') }}
                />
              </ContainerButtom>
            </>}
        </ContainerModal>}

    </Container>
  )
}

const Rippless = styled(Ripples)`
  flex-direction: row;
  justify-content: center;
  width:95%;
  cursor:pinter;
  ${({ avatar }) => avatar ? 'padding:5px;' : ''}
`

const Div = styled.div`
  flex: 1;
`

const InputFullname = styled.input`
  border: none;
  outline: none;
  height: 40px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};
  flex: 2;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.lilaLight};
  }
  ::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.lilaLight};
  }
  background:transparent;
`

const ContainerInput = styled.div`
  justify-content: space-between;
  align-items: center;
  align-self: center;
  flex-direction: row;
  margin-top: 5%;
  /* margin-horizontal: 10px; */
  display:flex;
  width:98%;
  border-bottom: 1px solid ${({ error, theme }) => error ? theme.colors.fucsia : theme.colors.grayLight};;
`

const IconName = styled.img`
  height: 20px;
  justify-content: center;
  align-items: center;
  margin-right:5px;
`

const IconCheck = styled.img`
  height: 27px;
  width: 27px;
  justify-content: center;
  align-items: center;
  flex:1;
`

const ErrorText = styled.div`
  color: ${({ theme }) => theme.colors.fucsia};
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  text-align: center;
  justify-content: center;
  align-self: center;
  margin-top: 5px;
`

const ContainerButtom = styled.div`
  align-self: center;
  justify-content: center;
  margin-top: 6%;
`

const ContainerIconos = styled.div`
  justify-content: space-between;
  align-items: center;
  align-self: center;
  flex-direction: row;
  margin-top: 40px;
`

const PanelIconos = styled.div`
  flex-direction: column;
  justify-content: space-between;
`

const IconCam = styled.img`
  height: 60px;
  justify-content: center;
  align-items: center;
`

const IconGalery = styled.img`
  height: 60px;
  justify-content: center;
  align-items: center;
`

const TextIcon = styled.div`
  color: ${({ theme }) => theme.colors.lila};
  font-size: 14px;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: center;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
`
const ChooseTheWayToUploadYourProfilePhoto = styled.div`
  color: ${({ theme }) => theme.colors.lilaLight};
  font-size: 14px;
  align-items: center;
  align-self: center;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  margin-top: 40px;
`

const ContainerModal = styled.div`
  flex: 1;
  align-items: flex-start;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
  height: 100%;
  width: 100%;
`

const HeaderModal = styled.div`
  height: 50px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color:${({ theme }) => theme.colors.blueDark};
  padding-top: 0px;
  padding-bottom: 10px;
  border-color: ${({ theme }) => theme.colors.lilaLight};
  border-bottom-width: 1px;
  display:flex;
`

const IconClose = styled.img`
  height: 26px;
  justify-content: center;
  align-items: center;
`

const TextSexo = styled.div`
  color: ${({ active, theme }) => active ? theme.colors.cyan : theme.colors.white};
  font-size: 14px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  align-items: center;
  align-self: center;
  line-height: 24px;
  ${({ active, theme }) => active && `text-shadow: 0px 0px 8px ${theme.colors.cyan}`};
  ${({ active, theme }) => active && `border-color: ${theme.colors.cyan}`};
  ${({ active, theme }) => active && `border-bottom:3px solid ${theme.colors.cyan}`};
  cursor:pointer;
`

const PanelSexo = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  /* margin-horizontal: 20%; */
  margin-bottom: 5%;
  display: flex;
  width: 90%;
`

const ItemMenu = styled.div`
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 32px;
  display: flex;
  width: 100%;
  cursor:pinter;
`

const IconMenu = styled.img`
  width: 20px;
  height: 20px;
  align-self: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
`

const NameMenu = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  text-align: left;
  font-family: ${({ theme }) => theme.fontFamilyBold};
  align-items: center;
  align-self: center;
  flex: 4;
  cursor:pointer;
`

const IconAngle = styled.img`
  height: 22px;
  width:18px;
  align-self: center;
  align-items: center;
`

const Container = styled.div`
  background: ${({ theme }) => theme.colors.blueDark};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width:100%;
  height:100;
  padding-bottom:10px;
`

const Title = styled.div`
  color: #fff;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fontFamilyMedium};
  margin-top: 10px;
`

const Menu = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1;
`

const MenuCenter = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  width:100%;
`
