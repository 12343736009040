import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: ${({ inputLada }) => inputLada === 'true' ? '32px 60px 1fr' : ({ inputIcon }) => inputIcon === 'true' ? '32px 1fr' : '1fr'};
  grid-template-rows: ${({ inputTitle }) => inputTitle !== ''
                        ? '18px 46px 4px'
                        : ({ inputBorderBottom }) => inputBorderBottom === 'true'
                          ? '46px 4px'
                          : '46px'
                        };
  grid-template-areas: ${({ inputTitle }) => inputTitle !== '' ? ({ inputLada }) => inputLada === 'true' ? "'title title title'" : ({ inputIconRight }) => inputIconRight ? "'title title title'" : ({ inputIcon }) => inputIcon === 'true' ? "'title title'" : "'title'" : ''}
                       ${({ inputIcon }) => inputIcon === 'true' ? ({ inputLada }) => inputLada === 'true' ? "'icon lada input'" : ({ inputIconRight }) => inputIconRight ? "'icon input icon-right'" : "'icon input'" : "'input'"}
                       ${({ inputBorderBottom }) => inputBorderBottom === 'true' ? ({ inputLada }) => inputLada === 'true' ? "'select select select'" : ({ inputIconRight }) => inputIconRight ? "'select select select'" : ({ inputIcon }) => inputIcon === 'true' ? "'select select'" : "'select'" : ''}
                       ;
  align-items: center;
  justify-content: space-between;
  padding: ${({ inputBorderBottom }) => inputBorderBottom === 'true' ? '2px 6px' : '7px 6px'};
  width: ${({ inputWidth }) => inputWidth};
  height: ${({ inputHeight }) => inputHeight};
  background-color: transparent;
  border-radius: 2px;
  margin: 2% auto;
  ${({ inputType }) => inputType === 'search' ?? 'margin: 0 auto'};

  input {
    color: #fff;
    &:focus ~ .input__select {
      ${({ inputBorderBottom }) => inputBorderBottom === 'true' && 'border-bottom: 1px solid #00FEFF'};
    }
  }

  .input__select {
    grid-area: select;
    width: 100%;
    height: 100%;
    text-align: center;
    ${({ inputBorderBottom }) => inputBorderBottom === 'true' && 'border-bottom: 1px solid #6A65A8'};
    transition: 260ms all ease-in-out;
  }
`

export const TitleComponent = styled.div`
  grid-area: title;
  color: #E81D80;
  font-family: 'TitilliumWebSemiBold';
  font-size: 14px;
`

export const LabelComponet = styled.label`
  display: grid;
  grid-template-rows: min-content auto;
  grid-gap: 2px;
  justify-content: center;
  width: 60px;
  font-size: ${({ inputFontSize }) => inputFontSize};
  cursor: pointer;

  input {
    display: none;
    width: 0;
    height: 0;
    &[type='radio']:checked ~ .radio__label {
      color: #00FEFF;
    }

    &[type='radio']:checked ~ .radio__control {
      background-color: #00FEFF;
      box-shadow: 0 0 10px #00FEFF;
    }
  }

  .radio__control {
    width: 70px;
    height: 4px;
    text-align: center;
    border-radius: 2px;
    background-color: transparent;
    transition: 180ms all ease-in-out;
  }

  .radio__label {
    width: 100%;
    height: 100%;
    text-align: center;
    transition: 260ms all ease-in-out;
  }
`

export const InputComponent = styled.input`
  grid-area: input;
  padding-left: ${({ inputIcon }) => inputIcon === 'true' ? '5px' : '0'};
  width: ${({ type }) => type === 'radio' ? 'auto' : '100%'};
  height: ${({ type }) => type === 'radio' ? 'auto' : '100%'};
  font-size: ${({ inputFontSize }) => inputFontSize};
  border: none;
  outline: none;
  background-color: transparent;

  &::placeholder {
    color: #6A65A8;
  }
`

export const IconRight = styled.div`
  grid-area: icon-right;
  cursor: pointer;
`

export const Lada = styled.div`
grid-area: lada;
width: auto;
color: ${({ theme }) => theme.colors.cyan};
font-family: ${({ theme }) => theme.fontFamilyMedium};
font-size: 18px;
background: transparent;
border: none;
cursor: pointer;
`
