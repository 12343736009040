import React from 'react'

export const Plus = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x='0'
    y='0'
    version='1.1'
    viewBox='0 0 37 37'
    xmlSpace='preserve'
    {...props}
  >
    <image
      width='40'
      height='40'
      overflow='visible'
      transform='translate(-1.5 -1.5)'
      xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAApCAYAAACoYAD2AAAACXBIWXMAAAsSAAALEgHS3X78AAAA GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABSBJREFUeNqsmQt7mjAUhhOIglfa rrv//9+2td1a6x1EGNm+4z6OgFbr85yiloQ3555ozTWvovR/7Ym7ShPYqx5jrwSzHXOVre/fCG0v gOsSDdkmb4K1F8AFkJAkUKACU0D2kIKkPBfUngkYkPQgfYh/7wjUEKAH20Eyes/QJ0HtmYAhgcWV DEliAmVIAUwr2VayrmQD2QJaYDtB3QnAEPd4uEElo0omlSS4jvF9n8zeBOkBF5XMSdaANX/vLcpW UHcGYAw4D3ZbyV0lN5VM8X2ktGiUyXeA8ZCzSn5jUTOMOQnqTgAOoC0P9hHyAYBDgispGHgO8eM9 FphgYaJ5q1JUI6jr8MEYgPeVfK7kKyATaM+/cmgipYAwGN/HfQPMNYBEFGicCbaHxSpQp4LI0gOG WL0H/F7JF3zuA24F31ogGFJo0wAgwhxTLGyMz+y7nKIKZY2yDlkcPrMWp6TFLzBzhKicw7e8vEIL uYIUd0no/2KFOwqunFJTfoD2TNCmU1qUPDgA5AeY+AaT54B6gPyGRvfkf6whP/+SUk6Bufq4ZrCC pKeMtHrQpjY3Qybk7DEGe8DHSn7gugC4I38zMH0GOAHMyYQCOoVWxW3WKn82+iT74xgSYfIlNPcA wBkmlDQlkWug3ZL8dU+AUpkmmHui0tkGprcSQE7VZqksUlUG+C6FFn8BdAFAi3u8Zj7hYQaakehf YvxclVVHGWCE53Hk7yW9OdV2ySQRQB38YwOwV/JBcYtbCq4Ec40wn/jWhjLCCykgIUvo9GS7fDKk 1YZ4UEplrCANJNDgtwbIgHLmDONzKpErgAWkFIHkRH8U3QwaHKrAP9OmeEhIgB8B9xWwE4yJGywk JXBHAZVTgyKQvVrrV/mla+iKAtV2FSraHKWoG5hbavmY5uHoZijxVYn24ETbZ92FvaZVGtLduW2w jm1RhlPBpBto4zo3UA1NHNXrJQIpVjnSUJDNENVLKpuOwPptYF2tWtlQQ7WPZlQ95D5LJjYA/Al5 RERL7oxoYdINlUfd+lHtrm+S9J4koFWH+D5V3bUsJsX7GarSD+RWSVsRNR5DzGmoOU6pfh8aDdeg xZy0JQk1otzWQxpJKRDEpzKCfKIGJCMTj6jC9CjA1mrhh42aI3Nxy7+l9mtE5SuhhkG6F0nQjrrs Ob5bErjMIf3AEM9NMceKSuK+bm7fDvka+R9SOpMlBk5V68YaTKn0BdCGwXWJBwYYf4Px96qr0n3p TpnbaHML5Io2TAntc+4xgcXYOa18BeiSekPOqZ+Q+O+pEVlTFliovrTUZbFUG6c1AGbU3Yi5Jbd5 TTxTetlTgrZUPcZU36UJCaG1FwTWs3Kjf1qsNb3/Tc67uzkGjykShzBVjzZpr9BCSg+Q+i/bB9ll JuS7z9T2SYrKVBpqNLeAZhQQsUq6Yj6BTpQ/laTpgdqfB6TBn0hRT1joprYFadyIHWtzg8HclcjW VDQr/jpp0WRf5VfxwScAPkCjq6PUc0bFMaRN7oakZUsINKSyWKoKJOMkpckG7hdAtS82ng3VIeva zCnv8ecF/HKiTjDChkyxozwo9fwFASkNtAC2ngnZC86CxuRnI9r8O7Vb5HOgFTS2wJV3h/mpQ6u3 nKo5OlWLCU43qxpySydpevd41vHfueeTGpZ7QN5qBKpR3inJj+CuOp9sPiMPWk57g5ZD1KZT3rK2 +b/6pLf7QL/r3Pzdzsvf49eHtvfv9svDZZBv+y3n6t9w/OuPAAMAz3KD6gFGDHUAAAAASUVORK5C YII='
    />
    <g fill='#00FEFF'>
      <path d='M18.1 4.9C10.7 4.9 4.6 11 4.6 18.4s6.1 13.5 13.5 13.5 13.5-6.1 13.5-13.5-6-13.5-13.5-13.5zm0 25c-6.3 0-11.5-5.2-11.5-11.5S11.8 6.9 18.1 6.9s11.5 5.2 11.5 11.5-5.1 11.5-11.5 11.5z' />
      <path d='M23.1 17.4h-4v-4c0-.6-.4-1-1-1s-1 .4-1 1v4h-4c-.6 0-1 .4-1 1s.4 1 1 1h4v4c0 .6.4 1 1 1s1-.4 1-1v-4h4c.6 0 1-.4 1-1s-.4-1-1-1z' />
    </g>
  </svg>
)
