import styled from 'styled-components'
import {
  fontStyleTitle,
  fontStyleContent
} from '../../styles/general'

export const DefaultCreditCardWrapper = styled.div`
  height: 58px;
  width: 155px;
`

export const DefaultCreditCardContent = styled.div`
  height: 100%;
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.cyan};
  border-radius: 8px;
  display: grid;
  grid-template-columns: 32% 48% 20%;
  grid-template-areas:  "card-summary letter-summary button-summary";
  cursor: pointer;
`

export const CreditCardSummary = styled.div`
  grid-area: card-summary;
  display: flex;
  align-items: center;
  justify-content: center;

  & img{
    width: 30px;
  }
`

export const CreditCardText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & h3 {
    ${fontStyleTitle}
  }

  & p {
    ${fontStyleContent};
    color: ${({ theme }) => theme.colors.gray}
  }
`

export const ButtonSummary = styled.button`
  grid-area: button-summary;
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const EmptyDefaultCreditCardContent = styled(DefaultCreditCardContent)`
  grid-template-columns: 1fr 20%;
  grid-template-areas:  "text-empty button-summary";
`

export const EmptyTextCreditCardContent = styled.div`
  grid-area: text-empty;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  h2{
    ${fontStyleTitle};
    color: ${({ theme }) => theme.colors.cyan};
  }
`
