/* eslint-disable no-empty */
import React, { useState, useEffect } from 'react'
import {
  ModalArrowLeft,
  ModalContainer,
  TitleContainer,
  Title,
  MainContainer,
  FormCard,
  FormMain,
  FormButton,
  FormItem,
  FormItemTitle,
  FormItemIcon,
  FormItemInput,
  FormTypeItem,
  FormDiv,
  FormItemExpiration,
  FormItemCVV,
  FormDelete,
  ContainerSmallCreditCard,
  SmallCreditIcon,
  SmallCreditNumber
} from './styles'
import moment from 'moment'

// Components
import { Modal } from '../../components/Modal'
import { Button, ButtonAlert } from '../../components/Button'
import { Loader } from '../../components/Loader'

// Icons
import ICreditCard from '../../assets/images/creditcard.png'
import IHolderName from '../../assets/images/holdername.png'
import IExpireDate from '../../assets/images/expireDate.png'
import ICVV from '../../assets/images/cvv.png'

// Notifications
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// API
import http from '../../services/DataService'
import { ENDPOINTS } from '../../constants'

import { getCardType, getIconCreditCard } from '../../helpers/CreditCard'
import useTranslation from '../../i18n'

export const CreditCardModal = (
  {
    isOpen,
    card,
    onCloseModal,
    cardsLength,
    viewModal
  }) => {
  const [loading, setLoading] = useState(true)
  const [formData, setFormData] = useState({})
  const { t } = useTranslation()

  // Inicialize formData withc create and update
  useEffect(() => {
    if (viewModal === 'create') {
      setFormData({
        cardNumber: '',
        cardHolderName: '',
        expirationDate: '',
        cvv: '',
        type: '',
        default: !(cardsLength > 0)
      })
    }
    if (viewModal === 'update') {
      setFormData({
        id: card._id,
        cardNumber: '',
        cardHolderName: card.cardHolderName,
        expirationDate: card.expirationDate,
        cvv: '',
        type: '',
        default: card.default
      })
    }
    setLoading(false)
  }, [isOpen, viewModal])

  // Erase data formData when componend will unmount
  useEffect(() => {
    return () => {
      setLoading(false)
      setFormData({})
    }
  }, [isOpen])

  const handleChangeForm = (event) => {
    if (event.target.name === 'cardNumber') {
      const numbersCard = event.target.value.replace(/\s/g, '').replace(/\D/g, '')
      const type = getCardType(numbersCard)
      const cardNumber = numbersCard.replace(/([0-9]{4})/g, '$1 ').trim()

      setFormData({
        ...formData,
        cardNumber,
        type
      })
    } else if (event.target.name === 'expirationDate') {
      const expirationCard = formatString(event.target.value)
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
        expirationDate: expirationCard
      })
    } else {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value
      })
    }
  }

  const handleBlurExpirationDate = () => {
    const splitedDate = String(formData.expirationDate).split('/')
    const year = splitedDate[splitedDate.length - 1]

    if (year.length === 2) {
      if (moment(`${splitedDate[0]}/20${year}`, 'MM/YYYY').isAfter(moment())) {} else {
        toast.error(t('ExpirationAfterCurrent'))
        setFormData({
          ...formData,
          expirationDate: ''
        })
      }
    } else {
      toast.error(t('ExpirationFormatMMYYYY'))
      setFormData({
        ...formData,
        expirationDate: ''
      })
    }
  }

  const handleSendData = async (event) => {
    event.preventDefault()

    if (
      formData.cardNumber !== '' &&
      formData.cardHolderName !== '' &&
      formData.expirationDate !== '' &&
      formData.cvv !== '' &&
      formData.type !== ''
    ) {
      try {
        setLoading(true)
        // Delete spaces between creditcard numbers
        formData.cardNumber = formData.cardNumber.replace(/\s/g, '')

        const res = await http.create(ENDPOINTS.PAYMENT_METHODS, formData)
        if (res.status === 200) {
          setLoading(false)
          onCloseModal()
        } else {
          setLoading(false)
          toast.warning(res.message_en)
        }
      } catch (error) {
        toast.warning('Something was wrong, Try again later')
      }
    } else {
      toast.warning('Sorry, You have fields to complete on the card')
    }
  }

  const handleUpdateData = async (event) => {
    event.preventDefault()
    if (
      formData.id !== '' &&
      formData.cardNumber !== '' &&
      formData.cardHolderName !== '' &&
      formData.expirationDate !== '' &&
      formData.cvv !== '' &&
      formData.type !== '' &&
      formData.default !== ''
    ) {
      try {
        setLoading(true)
        // Delete spaces between creditcard numbers
        formData.cardNumber = formData.cardNumber.replace(/\s/g, '')

        const res = await http.update(ENDPOINTS.PAYMENT_METHODS, formData)
        if (res.status === 200) {
          setLoading(false)
          onCloseModal()
        } else {
          setLoading(false)
          toast.warning(res.message_en)
        }
      } catch (error) {
        toast.warning('Something was wrong, Try again later')
      }
    } else {
      toast.warning('Sorry, You have fields to complete on the card')
    }
  }

  const handleDeleteData = async () => {
    try {
      setLoading(true)
      const res = await http.remove(ENDPOINTS.PAYMENT_METHODS, { id: card._id })
      if (res.status === 200) {
        setLoading(false)
        onCloseModal()
      } else {
        setLoading(false)
        toast.warning(res.message_en)
      }
    } catch (error) {
      toast.warning('Something was wrong, Try again later')
    }
  }

  // Date
  const formatString = (string) => {
    return string.replace(
      /^([1-9]\/|[2-9])$/g, '0$1/' // To handle 3/ > 03/
    ).replace(
      /^(0[1-9]{1}|1[0-2]{1})$/g, '$1/' // 11 > 11/
    ).replace(
      /^([0-1]{1})([3-9]{1})$/g, '0$1/$2' // 13 > 01/3
    ).replace(
      /^(\d)\/(\d\d)$/g, '0$1/$2' // To handle 1/11 > 01/11
    ).replace(
      /^(0?[1-9]{1}|1[0-2]{1})([0-9]{2})$/g, '$1/$2' // 141 > 01/41
    ).replace(
      /^([0]{1,})\/|[0]{1,}$/g, '0' // To handle 0/ > 0 and 00 > 0
    ).replace(
      /[^\d/]|^[/]{0,}$/g, '' // To allow only numbers and /
    ).replace(
      /\/\//g, '/' // Prevent entering more than 1 /
    )
  }

  return (
    <Modal isOpen={isOpen} onCloseModal={onCloseModal} backgroundColor>
      <ModalArrowLeft height='30px' onClick={onCloseModal} />
      <ModalContainer>
        {
        (viewModal === 'create') &&
          <>
            <TitleContainer>
              <Title><h3>{t('AddCreditCard')}</h3></Title>
            </TitleContainer>
            <MainContainer>
              {
              loading
                ? <Loader />
                : <FormCard onSubmit={handleSendData}>
                  <FormMain>
                    <FormItem>
                      <FormItemTitle>
                        <h3>{t('CardNumber')}</h3>
                      </FormItemTitle>
                      <FormItemIcon>
                        <img src={ICreditCard} width='22px' />
                      </FormItemIcon>
                      <FormItemInput>
                        <input
                          type='text'
                          name='cardNumber'
                          // minLength={19}
                          maxLength={19}
                          placeholder='0000 0000 0000 0000'
                          value={formData.cardNumber}
                          onChange={handleChangeForm}
                        />
                      </FormItemInput>
                      <FormTypeItem>
                        <h3>{formData.type}</h3>
                      </FormTypeItem>
                    </FormItem>
                    <FormItem>
                      <FormItemTitle>
                        <h3>{t('CardholderName')}</h3>
                      </FormItemTitle>
                      <FormItemIcon>
                        <img src={IHolderName} width='22px' />
                      </FormItemIcon>
                      <FormItemInput>
                        <input
                          type='text'
                          name='cardHolderName'
                          value={formData.cardHolderName}
                          placeholder='John Doe'
                          onChange={handleChangeForm}
                        />
                      </FormItemInput>
                    </FormItem>
                    <FormDiv>
                      <FormItemExpiration>
                        <FormItemTitle>
                          <h3>{t('ExpirationDate')}</h3>
                        </FormItemTitle>
                        <FormItemIcon>
                          <img src={IExpireDate} width='22px' />
                        </FormItemIcon>
                        <FormItemInput>
                          <input
                            type='numeric'
                            name='expirationDate'
                            minLength={5}
                            maxLength={5}
                            value={formData.expirationDate}
                            placeholder='07/25'
                            onChange={handleChangeForm}
                            onBlur={handleBlurExpirationDate}
                          />
                        </FormItemInput>
                      </FormItemExpiration>
                      <FormItemCVV>
                        <FormItemTitle>
                          <h3>CVV</h3>
                        </FormItemTitle>
                        <FormItemIcon>
                          <img src={ICVV} width='22px' />
                        </FormItemIcon>
                        <FormItemInput>
                          <input
                            type='numeric'
                            name='cvv'
                            minLength={3}
                            maxLength={3}
                            value={formData.cvv}
                            placeholder='123'
                            onChange={handleChangeForm}
                          />
                        </FormItemInput>
                      </FormItemCVV>
                    </FormDiv>
                  </FormMain>
                  <FormButton>
                    <Button
                      buttonType='submit'
                      buttonTitle={t('AddCreditCard')}
                      buttonActive='true'
                      buttonFontSize='17px'
                      buttonWidth='190px'
                      buttonHeight='50px'
                      buttonIconArrowRight='true'
                    />
                  </FormButton>
                  </FormCard>
              }
            </MainContainer>
          </>
        }
        {
        (viewModal === 'update') &&
          <>
            <TitleContainer>
              <Title><h3>{t('EditCreditCard')}</h3></Title>
            </TitleContainer>
            <MainContainer>
              {
              loading
                ? <Loader />
                : <FormCard onSubmit={handleUpdateData}>
                  <FormMain>
                    <FormItem>
                      <FormItemTitle>
                        <h3>{t('CardNumber')}</h3>
                      </FormItemTitle>
                      <FormItemIcon>
                        <img src={ICreditCard} width='22px' />
                      </FormItemIcon>
                      <FormItemInput>
                        <input
                          type='number'
                          name='cardNumber'
                          minLength={16}
                          maxLength={16}
                          placeholder='0000 0000 0000 0000'
                          value={formData.cardNumber}
                          onChange={handleChangeForm}
                        />
                      </FormItemInput>
                      <FormTypeItem>
                        <h3>{formData.type}</h3>
                      </FormTypeItem>
                    </FormItem>
                    <FormItem>
                      <FormItemTitle>
                        <h3>{t('cardHolderName')}</h3>
                      </FormItemTitle>
                      <FormItemIcon>
                        <img src={IHolderName} width='22px' />
                      </FormItemIcon>
                      <FormItemInput>
                        <input
                          type='text'
                          name='cardHolderName'
                          value={formData.cardHolderName}
                          placeholder='John Doe'
                          onChange={handleChangeForm}
                        />
                      </FormItemInput>
                    </FormItem>
                    <FormDiv>
                      <FormItemExpiration>
                        <FormItemTitle>
                          <h3>{t('ExpirationDate')}</h3>
                        </FormItemTitle>
                        <FormItemIcon>
                          <img src={IExpireDate} width='22px' />
                        </FormItemIcon>
                        <FormItemInput>
                          <input
                            type='numeric'
                            name='expirationDate'
                            minLength={7}
                            maxLength={7}
                            value={formData.expirationDate}
                            placeholder='07/2021'
                            onChange={handleChangeForm}
                          />
                        </FormItemInput>
                      </FormItemExpiration>
                      <FormItemCVV>
                        <FormItemTitle>
                          <h3>CVV</h3>
                        </FormItemTitle>
                        <FormItemIcon>
                          <img src={ICVV} width='22px' />
                        </FormItemIcon>
                        <FormItemInput>
                          <input
                            type='numeric'
                            name='cvv'
                            minLength={3}
                            maxLength={3}
                            value={formData.cvv}
                            placeholder='123'
                            onChange={handleChangeForm}
                          />
                        </FormItemInput>
                      </FormItemCVV>
                    </FormDiv>
                  </FormMain>
                  <FormButton>
                    <Button
                      buttonType='submit'
                      buttonTitle={t('EditCreditCard')}
                      buttonActive='true'
                      buttonFontSize='17px'
                      buttonWidth='190px'
                      buttonHeight='50px'
                      buttonIconArrowRight='true'
                    />
                  </FormButton>
                </FormCard>
              }
            </MainContainer>

          </>
        }
        {
        (viewModal === 'delete') &&
          <>
            <TitleContainer>
              <Title><h3>{t('DeleteCreditCard')}</h3></Title>
            </TitleContainer>
            <MainContainer>
              {
              loading
                ? <Loader />
                : <FormCard>
                  <FormDelete>
                    <ContainerSmallCreditCard>
                      <SmallCreditIcon>
                        <img src={getIconCreditCard(card.type)} width='32px' />
                      </SmallCreditIcon>
                      <SmallCreditNumber>
                        <h3>{card.cardNumber.substr(-4)}</h3>
                      </SmallCreditNumber>
                      <SmallCreditNumber />
                    </ContainerSmallCreditCard>
                    <div />
                    <h3>{t('AreYouSureYouWantDelete')}</h3>
                    <p>{t('RememberYouWontBe')}</p>
                  </FormDelete>
                  <FormButton>
                    <ButtonAlert
                      buttonTitle={t('YesDeleteNow')}
                      buttonActive='true'
                      buttonFontSize='17px'
                      buttonWidth='190px'
                      buttonHeight='50px'
                      buttonIconArrowRight='false'
                      buttonOnClick={handleDeleteData}
                    />
                  </FormButton>
                  </FormCard>
              }
            </MainContainer>
          </>
        }
      </ModalContainer>
      <ToastContainer />
    </Modal>
  )
}
