/* eslint-disable node/handle-callback-err */

import http from './DataSource'
import { toast } from 'react-toastify'

export function handler (err) {
  if (err.response && err.response.data) {
    return err.response.data
  }
  return err
}

const get = async (resorce, params = {}) => {
  try {
    const res = await http.get(`${resorce}`, { params })
    return res.data
  } catch (e) {
    throw handler(e)
  }
}

const getById = async (resorce, id) => {
  const resp = await http.get(`${resorce}/${id}`)
    .then(function (response) {
      return response.data.result
    })
    .catch(function (error, response) {
      return false
    })

  return resp
}

const getAll = async (resorce, params = {}) => {
  try {
    const res = await http.get(`${resorce}`, { params })
    return res.data
  } catch (e) {
    throw handler(e)
  }
  // const resp = await http.get(`${resorce}`)
  //   .then(function (response) {
  //     return response
  //   })
  //   .catch(function (error) {
  //     return false
  //   })
  // return resp
}

const create = async (resorce, params) => {
  const resp = await http.post(`${resorce}`, params)
    .then(function (response) {
      return response.data
    })
    .catch(function (error, response) {
      return false
    })

  return resp
}

// OK
const update = async (resorce, params) => {
  try {
    const res = await http.put(`${resorce}`, params)
    return res.data
  } catch (error) {
    throw handler(error)
  }
  // const resp = await http.put(`${resorce}`, params)
  //   .then(function (response) {
  //     return response.data
  //   })
  //   .catch(function (error) {
  //     return false
  //   })
  // return resp
}

// OK
const remove = async (resorce, id) => {
  try {
    const res = await http.delete(`${resorce}`, { params: id })
    return res.data
  } catch (e) {
    throw handler(e)
  }
  // .then(function (response) {
  //   toast.success(response.data.message_en)
  //   return response.data.result
  // })
  // .catch(function (error) {
  //   toast.error(error)
  //   return false
  // })
}

const submit = async (data, resorce) => {
  let resp = ''
  let flag = false
  if (data._id !== '') { resp = await update(resorce, data) } else resp = await create(resorce, data)

  if (resp) {
    toast.success(resp.message_en)
    flag = true
  }
  return flag
}

// Use it to update form but basckend use post
const submitFormData = async (resorce, form) => {
  return await create(resorce, form)
}

export default {
  getById,
  getAll,
  get,
  create,
  update,
  remove,
  submit,
  submitFormData
}
