import React, { useState } from 'react'
import {
  CreditCardWrapper,
  MainContainer,
  ContentPayment,
  ContentCreditCards,
  AddCreditCardContainer,
  CreditCardButton,
  CreditCardText
} from './styles'

// Containers
import { CreditCardModal } from '../CreditCardModal'
import { Loader } from '../../components/Loader'

// Components
import { CreditCardItem } from '../../components/CreditCardItem'

// Icons
// import { ArrowLeft } from '../../assets/static/Arrow'
import { Plus } from '../../assets/static/Plus'

// Notifications
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// Hooks
import { useCards } from '../../hooks/useCards'

// API
import http from '../../services/DataService'
import { ENDPOINTS } from '../../constants'
import useTranslation from '../../i18n'

export const CreditCard = () => {
  // Modal, Cards
  const [isOpenPayment, setIsOpenPayment] = useState(false)
  const [viewModal, setViewModal] = useState('') // create update delete
  const [dataModal, setDataModal] = useState('')
  const [reload, setReload] = useState(false)
  const { cards, loading, setLoading } = useCards(isOpenPayment, reload)
  const { t } = useTranslation()

  // Modal
  const handleOpenModal = (newModalView, card) => {
    setDataModal(card)
    setViewModal(newModalView)
    setIsOpenPayment(true)
  }

  const handleCloseModal = () => {
    setViewModal('')
    setDataModal('')
    setIsOpenPayment(false)
  }

  /*
    Credit Card Default
  */
  const hanldeDefaultCreditCard = async (card) => {
    if (
      card.id !== '' &&
      card.cardNumber !== '' &&
      card.cardHolderName !== '' &&
      card.expirationDate !== '' &&
      card.cvv !== '' &&
      card.type !== '' &&
      card.default !== ''
    ) {
      try {
        const updatedCard = { ...card, default: !card.default, id: card._id }
        delete updatedCard._id
        const res = await http.update(ENDPOINTS.PAYMENT_METHODS, updatedCard)
        if (res.status === 200) {
        // Reload
          setReload(!reload)
        } else {
          setLoading(false)
          toast.warning(res.message_en)
        }
      } catch (error) {
        toast.warning('Something was wrong, Try again later')
      }
    } else {
      toast.warning('Sorry, You have fields to complete on the card')
    }
  }

  return (
    <>
      <CreditCardWrapper>
        <MainContainer>
          {
          loading
            ? <Loader />
            : <>
              <ContentPayment>
                <p>{t('WellAlways')}</p>
                <p>{t('AddCreditDebitCardsToYourWallet')}</p>
              </ContentPayment>
              <ContentCreditCards>
                {
                  cards.map((card) =>
                    <CreditCardItem
                      key={card._id}
                      card={
                        {
                          _id: card._id,
                          type: card.type,
                          cardNumber: card.cardNumber,
                          cardHolderName: card.cardHolderName,
                          expirationDate: card.expirationDate,
                          cvv: card.cvv,
                          default: card.default
                        }
                      }
                      openModal={handleOpenModal}
                      onDefault={hanldeDefaultCreditCard}
                    />)
                }
              </ContentCreditCards>
              <AddCreditCardContainer onClick={() => handleOpenModal('create', '')}>
                <CreditCardButton>
                  <button>
                    <Plus width='30px' />
                  </button>
                </CreditCardButton>
                <CreditCardText>
                  <h3>{t('AddCreditDebitCard')}</h3>
                </CreditCardText>
              </AddCreditCardContainer>
            </>
            }
        </MainContainer>
      </CreditCardWrapper>
      <ToastContainer />
      <CreditCardModal
        isOpen={isOpenPayment}
        card={dataModal}
        cardsLength={cards.length}
        viewModal={viewModal}
        onCloseModal={handleCloseModal}
      />
    </>
  )
}
