import styled from 'styled-components'

export const EmptyViewWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  & img{
    width:80%
  }

  & h3{
    font-family: ${({ theme }) => theme.fontFamilyMedium};
    color: ${({ theme }) => theme.whiteColor};
    font-size: 14px;
  }

  & p {
    font-family: ${({ theme }) => theme.fontFamilyRegular};
    color: ${({ theme }) => theme.primaryColor};
    font-size: 12px;
    text-align: center;
  }
`

export const ElementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: unset;
  align-items: center;
  width: 100%;
`
