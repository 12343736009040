import React, { useEffect, useState } from 'react'
import {
  MyWalletWrapper,
  MainContainer,
  BalanceContainer,
  BalanceContent,
  IconBalance,
  TitleBalance,
  CoinsBalance,
  ButtonWithdrawalContent,
  BuyCoinsContent,
  CoinsBuyContent,
  InputBuyCoins,
  ButtonControl,
  TextCoins,
  TitleBuy,
  ButtonBuyContent,
  SubscriptionContainer,
  CarrouselContainer,
  SlideContent,
  ButtonsContainer,
  ButtonSubscriptionContainer,
  MontlyButton,
  YearlyButton,
  ContentSucces,
  MembershipStatus
} from './styles'
import moment from 'moment'

// Components
import { Loader } from '../../components/Loader'
import { Button } from '../../components/Button'
import { ArrowDown } from '../../assets/static/ArrowDown'

// Notifications
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// Container
import { PurchaseModal } from '../PurchaseModal'
import { SubscribeModal } from '../SubscribeModal'

// Icons
import ShieldDolars from '../../assets/images/wallet.png'
import IPremium from '../../assets/images/icon-Premium.png'
import ICoinsPackage from '../../assets/images/icon-coins-package.png'
import IRecord from '../../assets/images/iconRecord.png'
import ICheck from '../../assets/images/icon-Check.png'
import ITrees from '../../assets/images/icon-Trees.png'
import INoAds from '../../assets/images/icon-noAds.png'
import GSuccess from '../../assets/images/animation_succes.gif'
import UpArrow from '../../assets/images/arrowup.png'

// Hooks
import { useConfig } from '../../hooks/useConfig'
import { useAppContext } from '../../hooks/useAppContext'
import useTranslation from '../../i18n'
// Libraries

// Swipper
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
// import 'swiper/swiper-bundle.css'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'

SwiperCore.use([Autoplay, Pagination, Navigation])

const carrouselData = [
  {
    image: IPremium,
    text: 'Zero commissions 9.99% when you sell a Lesson'
  },
  {
    image: ICoinsPackage,
    text: '10% Discount on coins purchase*, learn for less'
  },
  {
    image: IRecord,
    text: 'Free unlimited, recording of you lessons, learn on the go!'
  },
  {
    image: ICheck,
    text: 'Verification badge, boost your visibility'
  },
  {
    image: ITrees,
    text: '300%, trees planted per interaction, give more'
  },
  {
    image: INoAds,
    text: 'No Ads, No interrumption'
  }
]

export const MyWallet = ({ handleChangeWithDrawal, treeView }) => {
  const { profile } = useAppContext()
  const [loading, setLoading] = useState(false)
  const { config, loading: loading1 } = useConfig()
  const [coinsToBuy, setCoinsToBuy] = useState(1)
  const [isOpenPurchaseModal, setIsOpenPurchaseModal] = useState(false)
  const [membershipType, setMembershipType] = useState(null)
  const [isOpenBuyMembership, setIsOpenBuyMembership] = useState(false)
  const { t } = useTranslation()

  // Loading handle
  useEffect(() => {
    if (loading1) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [loading1])

  // Coins to Buy
  const hanldeChangeCoinsToBuy = (state) => {
    if (state === 'up') {
      if (coinsToBuy < 1000) setCoinsToBuy(coinsToBuy + 1)
    } else if (state === 'down') {
      if (coinsToBuy > 0) setCoinsToBuy(coinsToBuy - 1)
    } else {
      if (state > 0 && state < 1000) { setCoinsToBuy(state) }
    }
  }

  // Purchase modal
  const handleOpenPurchaseModal = () => {
    setIsOpenPurchaseModal(true)
  }

  const handleClosePurchaseModal = () => {
    setIsOpenPurchaseModal(false)
  }

  // Buy Membership modal
  const handleOpenMembershipModal = (type) => {
    setMembershipType(type)
    setIsOpenBuyMembership(true)
  }

  const handleCloseMembershipModal = () => {
    setMembershipType(null)
    setIsOpenBuyMembership(false)
  }

  const getCoinsPerDolar = (coins) => {
    return coins * config.coinValue
  }

  const getDiscount = (coins) => {
    const discount = (100 - config.coinPremiunCost) / 100
    return (coins * discount).toFixed(2)
  }

  if (loading) {
    return (
      <MyWalletWrapper>
        <Loader />
      </MyWalletWrapper>
    )
  }

  return (
    <>
      <MyWalletWrapper>
        <MainContainer>
          {treeView === undefined &&
            <BalanceContainer>
              <BalanceContent>
                <IconBalance>
                  <img src={ShieldDolars} width='22px' />
                </IconBalance>
                <TitleBalance>
                  <p>{t('CurrentBalance')}</p>
                </TitleBalance>
                <CoinsBalance>
                  <h2>{parseFloat(profile.balance).toFixed(2)} coins</h2>
                </CoinsBalance>
                <ButtonWithdrawalContent>
                  <Button
                    buttonTitle={t('Withdrawal')}
                    buttonActive='true'
                    buttonFontSize='16px'
                    buttonWidth='125px'
                    buttonHeight='50px'
                    buttonIconArrowRight='true'
                    buttonIconArrowSize='20px'
                    buttonBorder='false'
                    buttonOnClick={handleChangeWithDrawal}
                  />
                </ButtonWithdrawalContent>
              </BalanceContent>
              <BuyCoinsContent>
                <TitleBuy>
                  <p>{t('BuyCoins')}</p>
                </TitleBuy>
                <CoinsBuyContent>
                  <InputBuyCoins>
                    <input
                      type='number'
                      min='1'
                      max='999'
                      value={coinsToBuy}
                      onChange={(e) => hanldeChangeCoinsToBuy(e.target.value)}
                    />
                  </InputBuyCoins>
                  <ButtonControl gridArea='button-buy-up' onClick={() => hanldeChangeCoinsToBuy('up')}>
                    <img src={UpArrow} width='15px' height='15px' />
                  </ButtonControl>
                  <ButtonControl gridArea='button-buy-down' onClick={() => hanldeChangeCoinsToBuy('down')}>
                    <ArrowDown rotate='rotate(0)' width='15px' />
                  </ButtonControl>
                  <TextCoins>
                    <p>Coins</p>
                  </TextCoins>
                </CoinsBuyContent>
                <ButtonBuyContent>
                  <Button
                    buttonTitle={`£ 
                      ${profile.membership === 'Free'
                        ? getCoinsPerDolar(coinsToBuy)
                        : getDiscount(getCoinsPerDolar(coinsToBuy))}`
                    }
                    buttonActive='true'
                    buttonFontSize='16px'
                    buttonWidth='120px'
                    buttonHeight='42px'
                    buttonIconArrowRight='true'
                    buttonIconArrowSize='20px'
                    buttonOnClick={handleOpenPurchaseModal}
                  />
                  <p>{config.coinPremiunCost}{t('DiscountWithPremium')}</p>
                </ButtonBuyContent>
              </BuyCoinsContent>
            </BalanceContainer>}
          {
            profile.membership === 'Free' || profile.membership === undefined
              ? <SubscriptionContainer>
                <CarrouselContainer>
                  <Swiper
                    id='carousel'
                    pagination={{ clickable: false }}
                    scrollbar={{ draggable: false }}
                    loop
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: true
                    }}
                  >
                    {
                  carrouselData.map((item, i) =>
                    <SwiperSlide key={`${i}-slide`}>
                      <SlideContent>
                        <img src={item.image} />
                        <h2>{item.text}</h2>
                      </SlideContent>
                    </SwiperSlide>
                  )
                }
                  </Swiper>
                </CarrouselContainer>
                <ButtonsContainer>
                  <ButtonSubscriptionContainer>
                    <MontlyButton onClick={() => handleOpenMembershipModal('Monthly')}>
                      <h3>{t('Monthly')}</h3>
                      <p>{`${config.membershipMonthly} ${t('USDPerMonth')}`}</p>
                    </MontlyButton>
                  </ButtonSubscriptionContainer>
                  <ButtonSubscriptionContainer>
                    <YearlyButton onClick={() => handleOpenMembershipModal('Yearly')}>
                      <h3>{t('Yearly')}</h3>
                      <p>{`${config.membershipAnnual} ${t('USDPerMonth')}`}</p>
                    </YearlyButton>
                    <h2>{t('OffBuyingTheYear')}</h2>
                  </ButtonSubscriptionContainer>
                </ButtonsContainer>
              </SubscriptionContainer>
              : <SubscriptionContainer>
                <ContentSucces>
                  <img src={GSuccess} />
                  <h3>{t('MembershipActivated')}</h3>
                  <p>{t('NowYouHaveFullAccessToMeiXterPremiumFeatures')}</p>
                  <MembershipStatus>{`${t('YourCutOffDate')}: ${moment(profile.membershipEndDate).format('DD-MM-YYYY')}`} </MembershipStatus>
                  <MembershipStatus>{config.membership === 'Yearly' ? t('YearlyPayment') : t('MonthlyPayment')}: £ {profile.membershipCost}</MembershipStatus>
                </ContentSucces>
              </SubscriptionContainer>
          }
        </MainContainer>
      </MyWalletWrapper>
      <ToastContainer />
      <PurchaseModal
        coinsToBuy={coinsToBuy}
        isOpen={isOpenPurchaseModal}
        onCloseModal={handleClosePurchaseModal}
      />
      <SubscribeModal
        type={membershipType}
        config={config}
        isOpen={isOpenBuyMembership}
        onCloseModal={handleCloseMembershipModal}
      />
    </>
  )
}
