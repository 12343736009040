import React, { useEffect, useState } from 'react'
import {
  ModalArrowLeft,
  MainContent,
  Title,
  ContentContainer,
  MainMessage,
  FormContent,
  FormItem,
  FormItemTitle,
  FormItemInput,
  ContentSucces,
  SuccesContent,
  TicketContent
} from './styles'

// Components
import { Modal } from '../../components/Modal'
import { Button } from '../../components/Button'

// Notifications
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// API
import { ENDPOINTS } from '../../constants'
import http from '../../services/DataService'
import useTranslation from '../../i18n'

export const ReportIssueModal = ({
  idSession,
  idLesson,
  isOpen,
  onCloseModal
}) => {
  const [view, setView] = useState('main') // main success
  const [formData, setFormData] = useState({
    subject: '',
    message: ''
  })
  const [ticket, setTicket] = useState(null)
  const { t, localeProvider } = useTranslation()

  useEffect(() => {
    if (!isOpen) {
      setFormData({})
      setView('main')
    }
  }, [isOpen])

  // Views
  const handleBreak = () => {
    switch (view) {
      case 'main':
        onCloseModal()
        break
      case 'success':
        setView('main')
        break
      default:
        break
    }
  }

  const handleChangeForm = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (formData.subject) {
      if (formData.message) {
        try {
          let res

          if (idLesson) {
            res = await http.create(ENDPOINTS.SUPPORT_LESSON, {
              idLesson,
              subject: formData.subject,
              message: formData.message
            })
          } else if (idSession) {
            res = await http.create(ENDPOINTS.SUPPORT, {
              idSession,
              subject: formData.subject,
              message: formData.message
            })
          } else {
            res = await http.create(ENDPOINTS.SUPPORT_GENERAL, {
              subject: formData.subject,
              message: formData.message
            })
          }

          if (res.status === 200) {
            setTicket(res.result.code)
            setView('success')
            setTimeout(() => {
              onCloseModal()
            }, 5000)
          } else {
            toast.error(
              localeProvider.name === 'en'
                ? 'Sorry, We had a problem, try again later'
                : 'Lo siento, Tuvimos un problema, intenta de nuevo más tarde'
            )
          }
        } catch (error) {
          toast.error(
            localeProvider.name === 'en'
              ? 'Sorry, We had a problem, try again later'
              : 'Lo siento, Tuvimos un problema, intenta de nuevo más tarde'
          )
        }
      } else {
        toast.warning(
          localeProvider.name === 'en'
            ? 'You must add a message'
            : 'Debes agregar un mensaje'
        )
      }
    } else {
      toast.warning(
        localeProvider.name === 'en'
          ? 'You must add a subject'
          : 'Debes agregar un destinatario'
      )
    }
  }

  return (
    <Modal isOpen={isOpen} onCloseModal={onCloseModal} backgroundColor>
      <ModalArrowLeft height='30px' onClick={handleBreak} />
      <MainContent>
        {
          view === 'main' &&
            <>
              <Title><h2>{t('ReportProblem')}</h2></Title>
              <ContentContainer>
                <MainMessage>
                  <p>{t('YourSatisfactionIsVeryImportant')}</p>
                </MainMessage>
                <FormContent onSubmit={handleSubmit}>
                  <FormItem height={'54px'}>
                    <FormItemTitle>
                      <h3>{t('Subject')}</h3>
                    </FormItemTitle>
                    <FormItemInput>
                      <input
                        type='text'
                        name='subject'
                        placeholder={`${t('IssuesWithLesson')} : 4098991`}
                        value={formData.subject}
                        onChange={handleChangeForm}
                      />
                    </FormItemInput>
                  </FormItem>
                  <FormItem height={'122px'}>
                    <FormItemTitle>
                      <h3>{t('LeaveYourMessage')}</h3>
                    </FormItemTitle>
                    <FormItemInput>
                      <textarea
                        type='text'
                        name='message'
                        placeholder={`${t('TypeSomethingHere')} ...`}
                        value={formData.message}
                        onChange={handleChangeForm}
                      />
                    </FormItemInput>
                  </FormItem>
                  <Button
                    buttonTitle={t('SendSupportTicket')}
                    buttonWidth='222px'
                    buttonHeight='62px'
                    buttonFontSize='18px'
                    buttonActive='true'
                    buttonType='submit'
                  />
                </FormContent>
              </ContentContainer>
            </>
        }
        {
          view === 'success' &&
            <>
              <Title><h2>{t('TickedSent')}</h2></Title>
              <ContentSucces>
                <SuccesContent>
                  <h2>{t('ThankYou')}</h2>
                  <p>{t('YourSupportTicket')}</p>
                  <TicketContent>
                    <p>{t('YourTicket')}:</p>
                    <h1>{ticket}</h1>
                  </TicketContent>
                </SuccesContent>
              </ContentSucces>
            </>
        }
      </MainContent>
      <ToastContainer />
    </Modal>
  )
}
