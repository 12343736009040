import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { fontStyleContent } from '../../styles/general'
import moment from 'moment'

// Notification
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// Assets
import Success from '../../assets/images/success.png'
import Wallets from '../../assets/images/wallet.png'
import ArrowRight from '../../assets/images/arrowRight.png'
import Checks from '../../assets/images/check.png'
import Stripe from '../../assets/images/stripe.png'
import Arrowup from '../../assets/images/arrowup.png'
import Arrowdown from '../../assets/images/arrowdown.png'
import IconBalance from '../../assets/images/iconBalance.png'
import Close from '../../assets/images/close-circle-outline.png'

// Components
import { Loader } from '../../components/Loader'
import { Button } from '../Button'

// Hooks
import useTranslation from '../../i18n'
import { useAppContext } from '../../hooks/useAppContext'
import { useConfig } from '../../hooks/useConfig'

// Services
import { ROUTES, URI_WS } from '../../constants'
import http from '../../services/DataService'

// Helpers
import { getProfileUser } from '../../helpers/getProfileUser'

import socketio from 'socket.io-client'

export function WithDrawal () {
  const { config } = useConfig()
  const { profile, setProfile, reloadProfile } = useAppContext()
  const [list, setList] = useState([])
  const [request, setRequest] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState('Main')
  const [coins, setCoins] = useState(profile.balance)
  // const [refreshing, setRefreshing] = useState(false)
  const { t } = useTranslation()

  const getList = async () => {
    try {
      const response = await http.getAll(ROUTES.MY_PAYMENT)
      const list = response.result
      setList(list)
    } catch (error) {
      if (error.status === 401) {
        toast.error(error.message_en)
      } else if (error.status === 403) {
        toast.error(error.message_en)
      } else {
        toast.error(error.message)
      }
    }
  }
  useEffect(() => {
    async function upload () {
      setLoading(true)
      await getList()
      setLoading(false)
    }

    upload()
  }, [])

  useEffect(() => {
    setCoins(profile.balance)
  }, [profile.balance])

  useEffect(async () => {
    const options = {
      transports: ['websocket']
    }
    const socketIo = socketio.connect(URI_WS, options)

    socketIo.on('connect', function (data) {
      console.log('socket ON connect')
      console.log('Conneted to socket.oi: ', socketIo.id)
      socketIo.emit('joinStripe', {
        room: 'stripe'
      })
    })

    socketIo.on('stripe', (data) => {
      if (data.id.toString() === profile._id.toString() && data.confirmed === 'confirmed') { reloadProfile(true) }
    })

    return () => socketIo.disconnect()
  }, [])

  const stripeLink = async () => {
    try {
      setLoading(true)
      const result = await http.getAll(ROUTES.LINK_STRIPE, { idUser: profile._id })
      setLoading(false)
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      a.href = result.result.url
      a.target = '_blank'
      a.click()
    } catch (error) {
      setLoading(false)
      if (error.status === 401) {
        toast.error(error.message_en)
      } else if (error.status === 403) {
        toast.error(error.message_en)
      } else {
        toast.error(error.message)
      }
    }
  }

  const getDiscount = (coins) => {
    const discount = (100 - config.withdrawal) / 100
    return (coins * discount).toFixed(2)
  }

  const handleChangeCoins = (type) => {
    switch (type) {
      case 'up':
        if (coins < profile.balance) {
          setCoins(prev => prev + 1)
        } else {
          toast.warning(`${t('MaximunCoinsWithdraw')} ${profile.balance}`)
          setCoins(profile.balance)
        }
        break
      case 'down':
        coins > 0 ? setCoins(prev => prev - 1) : setCoins(0)
        break
      default:
        break
    }
  }

  // Allow change the value but if is bad reset It
  const [timerId, setTimerId] = useState(null)

  const handleChangeInputCoins = (e) => {
    clearTimeout(timerId)

    let newCoins = e.target.value.replace(/^(0+)/g, '')

    // Delete 0 starts
    newCoins = parseInt(newCoins)
    newCoins = isNaN(newCoins) ? 0 : newCoins

    const changeCoins = () => setTimeout(() => {
      if (newCoins > profile.balance) {
        setCoins(profile.balance)
        toast.warning(`${t('MaximunCoinsWithdraw')} ${profile.balance}`)
      }
    }, 700)

    setTimerId(changeCoins())
    setCoins(newCoins)
  }

  const BuyDolars = async () => {
    if (coins <= profile.balance) {
      if (coins > 0 && coins >= config.minimumWithdrawal) {
        try {
          setLoading(true)
          const res = await http.create(ROUTES.WITHDRAWAL, { coins })
          if (res.status === 200) {
            toast.success(profile.adv === 'en' ? res.message_en : res.message_es)
            setProfile(getProfileUser(res.result))
            setRequest(true)
            getList()
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
          if (error.status) {
            toast.error(profile.adv === 'en' ? error.message_en : error.message_es)
            return
          }
          toast.error(error.message)
        }
      } else {
        toast.warning(`${t('MinimunWithdraw')} ${config.minimumWithdrawal}`)
      }
    } else {
      toast.warning(`${t('MaximunCoinsWithdraw')} ${profile.balance}`)
    }
  }

  const getCoinsPerDolar = (coins) => {
    return coins * config.coinValue
  }

  return (
    <Container>
      <ToastContainer />
      {loading && <Loader />}
      {
        (modal === 'Main' && !loading) &&
          <>
            <Title size='14px' mt='6%' empty>{t('Requestwithdrawal')}</Title>
            <Title size='12px' mt='1%'>
              {`${t('RequestwithdrawalText')} ${config.minimumWithdrawal} ${t('Coins')}.`}
            </Title>
            <TextFoot mb='2px'>{t('CurrentBalance')}</TextFoot>
            <MenuExtNew>
              <BalanceCont>
                <Wallet src={Wallets} />
                <Title size='15px' wd='50%' mt='0px' mb='0px' empty>{`${parseFloat(profile.balance).toFixed(2)} Coins`}</Title>
              </BalanceCont>
              <ContainerButtomWD>
                <Buttom onClick={() => { setModal('Modal'); setRequest(false) }} style={{ cursor: 'pointer' }}>
                  <TextButton>
                    <div>Withdrawal</div>
                    <img src={ArrowRight} />
                  </TextButton>
                </Buttom>
              </ContainerButtomWD>
            </MenuExtNew>
            <Title size='12px' mb='5%'>
              {`${t('WithdrawalDiscount1')} ${config.coinPremiunCost}% ${t('WithdrawalDiscount1')}`}
            </Title>
            <Separator />
            <Title size='14px' mt='8%' mb='7%' empty>{t('PaymentsHistory')}</Title>
            {
              list.map((item, index) =>
                <ListItem key={index}>
                  <MenuExtCont>
                    <DateTime>{moment(item.createdAt).format('DD MMM, hh:mm a')}</DateTime>
                    <Title size='14px' mt='3px' empty>{`${item.coins} Coins - ${item.commissionPer}%`}</Title>
                  </MenuExtCont>
                  <MenuExtCont>
                    <Status text={item.status}>{item.status}</Status>
                    <Title size='14px' mt='3px' empty>{`£ ${item.usd.toFixed(2)}`}</Title>
                  </MenuExtCont>
                </ListItem>
              )
            }
          </>
      }
      {
        (modal !== 'Main' && !loading) &&
          <ContainerModal>
            <HeaderModal>
              <MenuCenter>
                <TitleModal>
                  {t('RequestwithdrawalModal')}
                </TitleModal>
              </MenuCenter>
              <MenuExtModal>
                <div onClick={() => setModal('Main')}>
                  <IconClose resizeMode='contain' src={Close} />
                </div>
              </MenuExtModal>
            </HeaderModal>
            {
              request &&
                <>
                  <Check src={Checks} />
                  <TitleModal size='14px' mt='6%' empty>{t('Requestwithdrawalresp')}</TitleModal>
                  <ModalText wd='80%' size='14px' mt='1%'>{t('RequestwithdrawalrespText')}</ModalText>
                </>
            }
            {
              !request &&
                <>
                  <ModalText wd='80%'>{t('RequestwithdrawalModalText')}</ModalText>
                  <ModalText wd='90%'>
                    {t('RequestwithdrawalModalText2')} {' '}
                    <ModalText active mt='1%' mb='7%'>
                      contact@meixter.com
                    </ModalText>
                  </ModalText>
                  {
                    profile.statusStripeAccount !== 'confirmed'
                      ? (
                        <ContainerStripe className='Stripe'>
                          <StripeTouch onClick={() => { stripeLink() }}>
                            <MenuExt className='Extencion'>
                              <StripeImg>
                                <StripeIcon src={Stripe} />
                              </StripeImg>
                              <MenuExtCont wd>
                                <TitleModal align='flex-start' size='14px'>{t('WithdrawalLinked')} </TitleModal>
                                <ModalText mt='0px' align='flex-start'>{t('WithdrawalStripe')}</ModalText>
                              </MenuExtCont>
                              <IconDownStripe resizeMode='contain' src={Arrowdown} />
                            </MenuExt>
                          </StripeTouch>
                        </ContainerStripe>
                        )
                      : (
                        <>
                          <SuccessCheckMark>
                            <img src={Success} alt='Success' />
                            <ModalText active mt='0' mb='0'>
                              {t('SuccessStripeLink')}
                            </ModalText>
                          </SuccessCheckMark>
                          <TextPink>
                            {t('HowManyCoinsDoYouWantToWithdraw')}
                          </TextPink>
                          <CoinsControl>
                            <Wallet src={IconBalance} />
                            <InputConainer>
                              <Input
                                // maxLength={10}
                                type='text'
                                placeholder='Coins'
                                maxLength={3}
                                onChange={handleChangeInputCoins}
                                value={coins}
                              />
                            </InputConainer>
                            <p>Coins</p>
                            <RowBuyCoinsLeft>
                              <div onClick={() => handleChangeCoins('up')}>
                                <IconArrow src={Arrowup} alt='Up' />
                              </div>
                              <div onClick={() => handleChangeCoins('down')}>
                                <IconArrow src={Arrowdown} alt='Down' />
                              </div>
                            </RowBuyCoinsLeft>
                          </CoinsControl>
                          <ModalText>
                            {t('IncludesMeixterMommissionDiscount')} {config.withdrawal}%
                          </ModalText>
                          <ContainerButtom align='center'>
                            <Button
                              buttonActive='true'
                              buttonTitle={`${t('WithdrawalBtn')} - ${getDiscount(getCoinsPerDolar(coins))} USD`}
                              buttonFontSize='17px'
                              buttonWidth='210px'
                              buttonHeight='50px'
                              buttonOnClick={BuyDolars}
                            />
                          </ContainerButtom>
                        </>
                        )
                  }
                </>
            }
          </ContainerModal>}
    </Container>
  )
}

const BalanceCont = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly
`

const Container = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.colors.blueDark};
  align-items: flex-start;
  padding:10px;
  width:100%;
  height:100%;
`

const Title = styled.div`
  margin-top: ${props => props.mt || '5%'};
  margin-bottom: ${props => props.mb || '1%'};
  color: ${({ empty, theme }) => empty ? theme.colors.white : theme.colors.lilaLight};
  font-size: ${props => props.size || '16px'};
  text-align: left;
  align-self: flex-start;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  ${props => props.wd ? `width:${props.wd}` : ''};
`

const Wallet = styled.img`
  height:24px;
  width:24px;
`

const TextFoot = styled.div`
  color: ${({ theme }) => theme.colors.fucsia};
  font-family: ${({ theme }) => theme.fontFamilyBold};
  font-size: 14px;
  align-self:flex-start;
  margin-left:${props => props.ml || '6%'};
`

const ContainerButtom = styled.div`
  align-self: ${props => props.align || 'flex-end'};
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  height:60px;
`

const ContainerButtomWD = styled.div`
  align-self: center;
  justify-content: center;
  margin-top: 4px;
  margin-bottom: 20px;
  width:90%;
  height:30px;
`

const TextButton = styled.div`
  color: ${({ theme }) => theme.colors.cyan};
  font-family: ${({ theme }) => theme.fontFamilyBold};
  font-size: 15px;
  text-align: right;
  display:flex;
  flex-direction:row;
  justify-content:center;
`

const Buttom = styled.div`
  width: 100%;
  height:100%;
  border-radius: 10px;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  flex-direction:row;
`

const MenuExt = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  align-self:center;
  flex-direction:row;
  width: 100%;
`

const MenuExtNew = styled.div`
  display:flex;
  align-items: flex-start;
  justify-content: center;
  align-self:center;
  padding-top: 10px;
  flex-direction:row;
  margin-bottom:10px;
  margin-top: ${props => props.mt || '3%'};
`

const ListItem = styled.div`
  flex-direction: row;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayLight};;
  margin-top: 20px;
  padding-bottom:15px;
  width:100%;
  display:flex;
  justify-content: space-between;
`

const Status = styled.div`
  color: ${({ text }) => text === 'completed' ? '#00FE4E' : '#E4FF00'};
  font-size: 12px;
  text-align: left;
  align-self: flex-start;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
`

const Separator = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.3;
  margin-top: 5px;
  background-color: ${({ theme }) => theme.colors.lila};
`

const MenuExtCont = styled.div`
  display:flex;
  flex-direction:column;
  align-items: flex-start;
  justify-content: flex-start;
  ${props => props.wd && 'width:62%'};
`

const DateTime = styled.div`
  width: 100%;
  font-size: 12px;
  text-align: left;
  align-self:flex-start;
  color: ${({ theme }) => theme.colors.whiteTrasparent};
  font-family: ${({ theme }) => theme.fontFamilyRegular};
`

const ContainerModal = styled.div`
  flex: 1;
  align-items: flex-start;
  display:flex;
  flex-direction:column;
  padding:5px;
  height:100%;
  width:100%;
`

const HeaderModal = styled.div`
  height: 50px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color:${({ theme }) => theme.colors.blueDark};
  padding-top: 0px;
  padding-bottom: 10px;
  border-color: ${({ theme }) => theme.colors.lilaLight};
  border-bottom-width: 1px;
  display:flex;
`

const IconClose = styled.img`
  height: 26px;
  justify-content: center;
  align-items: center;
`

const TitleModal = styled.div`
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  text-align: left;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
`

const MenuExtModal = styled.div`
  flex:1;
  display:flex;
  justify-content: center;
  align-items: center;
`

const MenuCenter = styled.div`
  width:100%;
  height: 40px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ContainerStripe = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 70px;
  margin: 0 auto;
`

const StripeTouch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width:87%;
  padding: 0 3%;
  height:83%;
  border: 2px solid #00FEFF;
  box-shadow: 0 0 10px #00FEFF, inset 0 0 10px #00FEFF;
  border-radius:10px;
`

const StripeImg = styled.div`
  /* align-self: center; */
  justify-content: center;
  height:27px;
  width:40px;
  border:1px solid #FFFFFF80;
  border-radius:5px;
  margin-right:8px;
  margin-left:8px;
  display:flex;
  flex-direction:row;
`

const StripeIcon = styled.img`
  width:33px;
  height:13px;
  align-self:center;
`

const ModalText = styled.div`
  margin-top: ${props => props.mt || '5%'};
  margin-bottom: ${props => props.mb || '1%'};
  color: ${({ active, theme }) => active ? theme.colors.cyan : theme.colors.whiteTrasparent};
  font-size: 12px;
  display: inline-block;
  text-align: center;
  align-self: ${props => props.align || 'center'};
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  ${props => props.wd ? `width:${props.wd}` : ''};
`
// 46 * 33
const RowBuyCoinsLeft = styled.div`
  width: 33px;
  height: 50px;
  display: flex;
  flex-direction: column;

  & img {
    flex-shrink: 0;
  }
  /* border: 1px solid green; */
`

const IconArrow = styled.img`
  /* border: 1px solid blue; */
  height: 24px;
  width: 100%;
`

const IconDownStripe = styled.img`
  width:24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-right:5px;
`

const CoinsControl = styled.div`
  /* border: 1px solid red; */
  display:flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  & p {
    ${fontStyleContent}
    /* margin: 0 4px; */
    /* border: 1px solid yellow; */
  }
`

const Check = styled.img`
  align-self: center;
  margin-top:10%;
  height:42px;
  width:42px;
`

const InputConainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction:row; */
  /* border: 1px solid orange; */

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  & input[type=number]::-webkit-inner-spin-button,
  & input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const Input = styled.input`
  width:50px;
  font-size: 15px;
  text-align:center;
  border: none;
  outline: none;
  background-color:transparent;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamilyMedium};

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.lilaLight};
  }

  :-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.lilaLight};
  }
`

const TextPink = styled.h3`
  color: ${({ theme }) => theme.colors.fucsia};
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  margin: 0 auto;
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 10px;
`

const SuccessCheckMark = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & img {
    width: 38px;
    height: 38px;
    margin-bottom: 10px;
  }
`
