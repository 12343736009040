import styled from 'styled-components'
import { device } from '../../styles/device'

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height:100%;
  width: 42%;

  @media ${device.tablet} {
    justify-content: 'flex-start';
    width: 45%;
  }
`

export const UserName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  margin-right: 8px;
`

export const Name = styled.div`

`

export const Status = styled.div`
  font-family: 'TitilliumWebSemiBold';
`

export const ContentImage = styled.div`
  cursor: pointer;
`
