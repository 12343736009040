import { useState, useEffect } from 'react'

// Services
import { getNotifications } from '../services/User'

export const useNotifications = (reload) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [notifications, setNotifications] = useState([])
  const [pending, setPending] = useState(0)

  useEffect(async () => {
    try {
      setError(null)
      setLoading(true)
      const res = await getNotifications()
      if (res.status === 200) {
        const count = res.result.filter(item => !item.view).length
        setPending(count)
        setNotifications(res.result)
      } else {
        setLoading(false)
        setError(res.message_en)
      }
    } catch (error) {
      setLoading(false)
      setError(error)
    }
    setLoading(false)
  }, [reload])

  return { notifications, pending, loading, error }
}
