import React, { useEffect, useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { useInvoice } from '../../hooks/useInvoice'
import {
  MyWalletWrapper,
  MainContainer
}
  from '../../container/MyWallet/styles'
import { Loader } from '../../components/Loader'
import image from '../../assets/images/download-outline.png'

export const Invoices = () => {
  const [loading, setLoading] = useState(false)
  const { inv, loading: loading1 } = useInvoice()

  // Loading handle
  useEffect(() => {
    if (loading1)setLoading(true)
    else setLoading(false)
  }, [loading1])

  if (loading) {
    return (
      <MyWalletWrapper>
        <Loader />
      </MyWalletWrapper>
    )
  }
  const handleDownload = (url) => {
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.target = '_blanck'
    a.href = url
    a.click()
  }

  return (
<MyWalletWrapper>
<MainContainer>
<Title>Here is your list of invoices within MeiXter.</Title>
{
inv.map((item, index) => (
<Container key={index + 300}>
<SubContainer>
<TextDate>{moment(item.updatedAt).format('DD MMM, hh:mm a')}</TextDate>
<ContainerText>
<TextType>{item.type}</TextType>
<TextTotal>{`${item.total}£`}</TextTotal>
</ContainerText>
</SubContainer>
<Image src={image} onClick={() => handleDownload(item.url)}/>
</Container>
))
}
</MainContainer>
</MyWalletWrapper>
  )
}

const Title = styled.div`
  font-family:${({ theme }) => theme.fontFamilyRegular};
  font-size: 12px;
  color:#3A385B;
  width: 90%;
  height: 22px;
  text-align: center;
  margin-top:1%;
  margin-bottom:20px;
`

const Container = styled.div`
  display:flex;
  justify-content: space-between;
  height: 45px;
  padding-bottom: 5px;
  border-bottom: 1px solid #FFFFFF1A;
  flex-direction: row;
  width:100%;
  padding-left:5px;
  padding-right:5px;
  margin-top:10px;
`

const TextDate = styled.div`
  font-family:${({ theme }) => theme.fontFamilyRegular};
  font-size: 11px;
  color:#FFFFFF80;    
  text-align: left;
  margin-bottom:2px;
`

const ContainerText = styled.div`
  flex-direction: row; 
  display:flex; 
`

const SubContainer = styled.div`
  flex-direction: column;  
`

const TextType = styled.div`
  font-family:${({ theme }) => theme.fontFamilyRegular};
  font-size: 12px;
  color:#E4FF00;    
  text-align: left;  
`

const TextTotal = styled.div`
  font-family:${({ theme }) => theme.fontFamilyRegular};
  font-size: 14px;
  color:#FFFFFF;   
  text-align: left;
  margin-left:7px;  
`

const Image = styled.img`
  width:22px;
  height:30px;
  cursor:pointer;  
`
