import axios from 'axios'
import { toast } from 'react-toastify'
import { API_URL } from '../constants'

const http = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-type': 'application/json'
  }
})

http.interceptors.response.use((response) => {
  return response
}, function (error) {
  // Do something with response error
  if (error.response.status) {
    if (error.response.status === 401 || error.response.status === 500 || error.response.status === 403) {
      toast.error(error.response.data.message_en)
      if (error.response.status === 403) {
        try {
          window.localStorage.removeItem('@TOKEN')
          window.localStorage.removeItem('@TOKEN_VERIFY')
          window.location.href = '/'
        } catch (error) {
          // Testing
        }
      }
    }
  }
  return Promise.reject(error.response)
})

http.interceptors.request.use(
  config => {
    // eslint-disable-next-line no-undef
    const item = window.localStorage.getItem('@TOKEN')
    const token = item !== null ? JSON.parse(item) : ''

    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  error => {
    Promise.reject(error)
  })

export default http
