import styled from 'styled-components'
import {
  fontStyleTitle,
  fontStyleContent,
  centerHorizontalVertical
} from '../../styles/general'

export const NotificationItemWrapper = styled.li`
  width: 100%;
  height: 100%;
  padding: 10px 8px;
  display: grid;
  grid-template-columns: 40px 1fr 18px;
  grid-template-rows: 22px 1fr 22px;
  grid-template-areas:  "avatar    title       .      "
                        "avatar   message notification"
                        "   .      time        .      ";
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueSemiDark};
  cursor: pointer;
`

export const AvatarContainer = styled.div`
  grid-area: avatar;
  ${centerHorizontalVertical};
  align-items: flex-start;
`

export const TitleContainer = styled.div`
  grid-area: title;
  ${centerHorizontalVertical};
  justify-content: flex-start;
  ${fontStyleTitle};
`

export const MessageContainer = styled.div`
  grid-area: message;
  ${centerHorizontalVertical};
  justify-content: flex-start;
  ${fontStyleContent};
`

export const NotificationContainer = styled.div`
  grid-area: notification;
  ${centerHorizontalVertical};
`

export const TimeContainer = styled.div`
  grid-area: time;
  ${centerHorizontalVertical};
  justify-content: flex-start;
  text-align: center;

  & img {
    margin-right: 4px;
  }

  & p {
    ${centerHorizontalVertical};
    ${fontStyleContent};
    font-weight: 400;
    font-size: 12px;
    height: 12px;
    color: ${({ theme }) => theme.colors.whiteTrasparent};
  }
`

export const TextMessage = styled.p`
  ${fontStyleContent};
  font-size: 12px;
  font-weight: 400px;
`

export const CyanSpan = styled.span`
  ${fontStyleContent};
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.cyan};
`

export const BoldSpan = styled.span`
  ${fontStyleContent};
  font-size: 12px;
  font-weight: 600;
`
