import styled, { css } from 'styled-components'
import { device } from '../../styles/device'
import { Container } from '../../components/Input/styles'
import { ButtonComponent } from '../../components/Button/styles'
import ProfileDefault from '../../assets/images/DefaultProfile.png'

export const fontStyleTitle = css`
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  font-weight: 400;
`

export const fontStyleContent = css`
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
`

export const scrollBar = css`
  &::-webkit-scrollbar {
      width: 8px;     /* Tamaño del scroll en vertical */
      height: 8px;    /* Tamaño del scroll en horizontal */
      display: none;  /* Ocultar scroll */
  }

  /* Ponemos un color de fondo y redondeamos las esquinas del thumb */
  &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 4px;
  }
  /* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
  &::-webkit-scrollbar-thumb:hover {
      background: #b3b3b3;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }
  /* Cambiamos el fondo cuando esté en active */
  &::-webkit-scrollbar-thumb:active {
      background-color: #999999;
  }

  /* Ponemos un color de fondo y redondeamos las esquinas del track */
  &::-webkit-scrollbar-track {
      background: #e1e1e1;
      border-radius: 4px;
  }
  /* Cambiamos el fondo cuando esté en active o hover */
  &::-webkit-scrollbar-track:hover,
  &::-webkit-scrollbar-track:active {
    background: #d4d4d4;
  }
`

export const ContainerModal = styled.div`
  height: ${({ height }) => height || 350}px;
  width: 550px;
  padding: 10px 20px;

  @media ${device.tablet} {
    width: 100%;
    height: 100%;
  }
`

const ModalContainer = styled.section`
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: 14% 86%;
  grid-template-columns: 1fr;
  grid-template-areas:  "title"
                        "content";
`

// Welcome
export const LoginWelcome = styled(ModalContainer)`
  grid-template-rows: 1fr;
  grid-template-columns: 45% 1fr;
  grid-template-areas:  "left-content rigth-content";

  @media ${device.tablet} {
    grid-template-rows: 35% 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:  "left-content"
                          "rigth-content";
  }
`

export const LoginLeft = styled.div`
  grid-area: left-content;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Welcome = styled.div`
  color: #E81D80;
  font-family: 'DeadStockDemo';
  font-size: 40px;
`

export const LoginTitle = styled.div`
  font-family: 'TitlingGothicFBNormalMedium';
  font-size: 24px;
  color: #fff;
`

export const LoginRight = styled.form`
  grid-area: rigth-content;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  width: 100%;
  height: 100%;

  ${Container}{
    max-width: 366px;
  }
`

export const LoginCountry = styled(ModalContainer)``

export const LoginCountryTitle = styled.div`
  grid-area: title;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const H3 = styled.h3`
  ${fontStyleTitle}
  color: #6A65A8;
`

// Country
export const LoginCountryContent = styled.div`
  width: 80%;
  height: 100%;
  margin: 0 auto;
  grid-area: content;
  display: flex;
  flex-direction: column;
`

export const LoginCountryLists = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 228px;
  overflow: auto;

  ${scrollBar}
`

export const LoginCountryList = styled.button`
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 6px;
  color: #fff;
  border: 2px solid transparent;
  cursor: pointer;

  &:hover {
    border: 2px solid #00FEFF;
  }
`

// Verify
export const LoginVerify = styled(LoginWelcome)`
  .pincode-input-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;

    .pincode-input-text {
      font-size: 20px;
      color: #fff;
      border-radius: 10px;
      border: 2px solid #00FEFF !important;
    }

    .pincode-input-text:focus {
      box-shadow: 0 0 10px #00FEFF, inset 0 0 10px #00FEFF !important;
    }
  }
`

export const LoginTitleVerify = styled.div`
  height: 50px;
  display: flex;
  align-items: center;

  & h3{
    ${fontStyleTitle}
    font-weight: 600;
    font-size: 24px;
  }
`

export const LoginSubTitleVerify = styled.div`
  height: 40px;
  display: flex;
  align-items: center;

  & p {
    ${fontStyleContent}
    color: ${({ theme }) => theme.colors.lila};
    text-align: center;
  }
`

export const PinContent = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  max-width: 250px;
`

export const LoginPhoneVerify = styled.div`
  height: 40px;
  display: flex;
  align-items: center;

  & p {
    ${fontStyleContent}
    color: ${({ theme }) => theme.colors.fucsia};
  }
`

// Profile
export const LoginProfile = styled(ModalContainer)`
  grid-template-columns: 0% 100%;
  grid-template-rows: 5% 3% 80%;
  grid-template-areas:  "title-profile title-profile"
                        "subtitle-profile subtitle-profile"
                        "left-content rigth-content";
`

export const LoginProfileLeft = styled(LoginLeft)`
  padding-bottom: 60px;
`

export const LoginTitleProfile = styled.div`
  grid-area: title-profile;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  & h3{
    ${fontStyleTitle}
    font-weight: 600;
    font-size: 22px;
  }
`

export const LoginSubtitleProfile = styled.div`
  grid-area: subtitle-profile;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  & p {
    ${fontStyleContent};
    color: ${({ theme }) => theme.colors.violet};
  }
`

export const LoginFormProfile = styled.form`
  grid-area: rigth-content;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: fit-content;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  ${ButtonComponent}{
    margin-top: 10px;
    flex-shrink: 0;
  }
`

export const ImageContainer = styled.div`
  width: 100%;
  height: 132px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const ButtonLoad = styled.section`
  width: 100px;
  height: 100px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  color: #6A65A8;
  position: relative;

  & span {
    margin-top: 4px;
    text-align: center;
    cursor: pointer;
  }

  & input {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    outline: 0 none;
    cursor: pointer;
  }
`

export const ChangeImage = styled.div`
  width: 111px;
  height: 111px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(
    ${({ filepreview }) => filepreview || ProfileDefault}
  );
  background-size: 100%;
  background-color: #9C9C9C;
  border-radius: 50%;
  overflow: hidden;
  /* cursor: pointer; */
`

export const LoginCountries = styled(ModalContainer)`
  width: 80%;
  margin: 0 auto;
`

export const LoginCalendar = styled(ModalContainer)`
  width: 80%;
  margin: 0 auto;

  .react-calendar {
    width: 100%;
    color: #fff;
    font-family: 'TitilliumWebSemiBold';
    background-color: #070d36;
    border: none;
    &__month-view__days__day--neighboringMonth {
      color: #757575;
    }
    &__month-view__weekdays__weekday abbr {
      text-decoration: solid;
    }
    &__tile {
      color: #fff;
      border: 1px solid transparent;
      &--now {
        color: #00FEFF;
        background-color: transparent;
      }
      &--active {
        background-color: #00FEFF;
        color: #070d36;
        border-radius: 6px;
        &:enabled:hover,
        &:enabled:focus {
          background-color: #00FEFF;
          box-shadow: 0 0 10px #00FEFF;
          border-radius: 6px;
        }
      }

      &:enabled:hover,
      &:enabled:focus {
        color: #fff;
        background-color: #070d36;
        border: 1px solid #00FEFF;
        border-radius: 6px;
      }
    }
    &__navigation button {
      color: #fff;
      &:enabled:hover,
      &:enabled:focus {
        background-color: transparent;
        border: 1px solid #00FEFF;
        border-radius: 6px;
      }
    }
  }
`

export const LoginBio = styled(ModalContainer)`
  grid-template-columns: 0% 100%;
  grid-template-rows: 5% 3% 80%;
  grid-template-areas:  "title-profile title-profile"
                        "subtitle-profile subtitle-profile"
                        "left-content rigth-content";

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`

export const LoginBioLeft = styled(LoginLeft)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 60px;
`

export const LoginRightForm = styled.form`
  grid-area: rigth-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;

  ${Container}{
    margin-top: 0;
  }

  & h3 {
    ${fontStyleContent};
    font-size: 12px;
  }
`

export const LoginPhone = styled.div`
  color: #E81D80;
  font-size: 14px;
`

export const WebCamContainer = styled(ContainerModal)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  width: 550px;

  & video {
    width: 100%;
    height: 100%;
  }
`
