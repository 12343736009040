import { useState } from 'react'
import copy from 'copy-to-clipboard'

export const useCopyToClipboard = () => {
  const [copied, setCopied] = useState(false)

  function handleCopy (text) {
    if (typeof text === 'string' || typeof text === 'number') {
      copy(text.toString())
      setCopied(true)
    } else {
      setCopied(false)
    }
  }

  return { handleCopy, copied }
}
