import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { device } from '../../styles/device'

export const NavLinkComponent = styled(NavLink)`
  grid-area: logo;
  text-align: center;
  height: 100%;

  @media ${device.tablet} {
    align-items: center;
  }
`
