import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  NotificationComponent,
  ModalContainer,
  HeaderContainer,
  TitleConteiner,
  H3,
  List,
  MainContainer,
  Space
} from './styles'

// Components
import { SideRightModal } from '../SideRightModal'
import { NotificationItem } from '../NotificationItem'
import { MyEarnings } from '../MyEarnings'
import { MyWallet } from '../../container/MyWallet'

// Components View
import { WithDrawal } from '../WithDrawal'
import { EmptyView } from '../EmptyView'

// Icons
import { Alert } from '../../assets/static/Alert'

// Hooks
import { useNotifications } from '../../hooks/useNotifications'
import useTranslation from '../../i18n'

// Services
import { updateViewNotification } from '../../services/User'

export const Notification = () => {
  const { t, localeProvider } = useTranslation()
  const [view, setView] = useState('main')
  const [reload, setReload] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const history = useHistory()
  const { notifications, pending } = useNotifications(reload)

  useEffect(() => {
    setView('main')
    const updateNotifications = setInterval(() => setReload(!reload), 300000)

    return () => {
      setView('main')
      clearInterval(updateNotifications)
    }
  }, [])

  const handleOpenModal = () => {
    setIsOpen(true)
  }

  const handleModal = () => {
    switch (view) {
      case 'main':
        setIsOpen(false)
        break
      case 'withdrawall':
        hanldeChangeView('main')
        break
      case 'earnings':
        hanldeChangeView('main')
        break
      case 'wallet':
        hanldeChangeView('main')
        break
      default:
        break
    }
  }

  const hanldeChangeView = async (newView, id, idLesson) => {
    const res = await updateViewNotification(id)
    if (res.status === 200) {
      setReload(!reload)

      switch (newView) {
        case 'teach':
          history.push('/teach')
          break
        case 'share':
          history.push(`/shared/${idLesson}`)
          break
        case 'upcoming':
          history.push('/teach/upcoming')
          break
        case 'completed':
          history.push('/teach/completed')
          break
        case 'lesson':
          history.push('/teach/lesson')
          break
        case 'session':
          history.push('/sessions')
          break
        default:
          break
      }
      setIsOpen(false)
    }
  }

  return (
    <>
      <SideRightModal
        isOpen={isOpen}
        onCloseModal={handleModal}
        opacity='0.1'
      >
        {
          view === 'main' &&
            <ModalContainer>
              <HeaderContainer>
                <TitleConteiner>
                  <H3>{t('Notifications')}</H3>
                </TitleConteiner>
              </HeaderContainer>
              <MainContainer>
                <List>
                  {notifications.length === 0 && (
                    <EmptyView>
                      <p>
                        {
                          localeProvider.name === 'en'
                            ? 'You have no notifications'
                            : 'No tiene notificaciones'
                          }
                      </p>
                    </EmptyView>
                  )}
                  {notifications.map((item, index) =>
                    <NotificationItem
                      key={index}
                      onCloseModal={() => setIsOpen(false)}
                      notification={item}
                      handleChangeView={hanldeChangeView}
                    />
                  )}
                </List>
              </MainContainer>
            </ModalContainer>
        }
        {
          view === 'withdrawall' &&
            <ModalContainer>
              <HeaderContainer>
                <TitleConteiner>
                  <H3>Withdrawall</H3>
                </TitleConteiner>
              </HeaderContainer>
              <MainContainer>
                <WithDrawal />
              </MainContainer>
            </ModalContainer>
        }
        {
          view === 'earnings' &&
            <ModalContainer>
              <HeaderContainer>
                <TitleConteiner>
                  <H3>My Earnings</H3>
                </TitleConteiner>
              </HeaderContainer>
              <MainContainer>
                <Space />
                <MyEarnings />
              </MainContainer>
            </ModalContainer>
        }
        {
          view === 'wallet' &&
            <ModalContainer>
              <HeaderContainer>
                <TitleConteiner>
                  <H3>My Wallet</H3>
                </TitleConteiner>
              </HeaderContainer>
              <MainContainer>
                <MyWallet
                  handleChangeWithDrawal={() => setView('earnings')}
                />
              </MainContainer>
            </ModalContainer>
        }
      </SideRightModal>
      <NotificationComponent isPending={pending > 0} onClick={handleOpenModal}>
        <Alert width='38px' />
        {
          pending > 0 &&
            <div>
              <span>{pending}</span>
            </div>
        }
      </NotificationComponent>
    </>
  )
}
