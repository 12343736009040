import React, { useEffect, useState } from 'react'
import {
  ModalArrowLeft,
  LoadingContainer,
  ModalContainer,
  Title,
  MainContainer,
  SubtitleContent,
  BalanceContent,
  CardFieldValues,
  Field,
  Value,
  ButtonContainer,
  ContentSucces,
  PanelType,
  PanelItems,
  IconCheck,
  TextPoints
} from './styles'

// Components
import { Modal } from '../../components/Modal'
import { Loader } from '../../components/Loader'
import { Button } from '../../components/Button'
import { DefaultCreditCard } from '../../components/DefaultCreditCard'

// Icons
import GSuccess from '../../assets/images/animation_succes.gif'
import Success from '../../assets/images/success.png'
import CheckOut from '../../assets/images/checkOut.png'

// Helper
import { getDefaultCard } from '../../helpers/CreditCard'

// Notifications
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// Hooks
import { useCards } from '../../hooks/useCards'
import { useAppContext } from '../../hooks/useAppContext'

// Libraries
import moment from 'moment'

// Service
import { getAccountInfo } from '../../services/User'

// API
import http from '../../services/DataService'
import { ENDPOINTS } from '../../constants'
import useTranslation from '../../i18n'

export const SubscribeModal = ({
  type,
  config,
  isOpen,
  onCloseModal
}) => {
  const { setProfile, profile } = useAppContext()
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const { cards, loading: loading1, error: error1 } = useCards(isOpen)
  const [defaultCard, setDefaultCard] = useState(null)
  const [payMethod, setPayMethod] = useState('TDC')
  const [view, setView] = useState('main') // main, success
  const { t } = useTranslation()

  const handleBreak = () => {
    switch (view) {
      case 'main':
        onCloseModal()
        break
    }
  }

  // Loading handle
  useEffect(() => {
    if (loading1) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [loading1])

  // Error handle
  useEffect(() => {
    if (error === null) return
    if (error) {
      toast.error(error)
    }
  }, [error, error1])

  // setDefaultCard
  useEffect(() => {
    setDefaultCard(getDefaultCard(cards))
  }, [cards, isOpen])

  const handleSubscription = async () => {
    if (defaultCard !== null) {
      try {
        setLoading(true)
        const res = await http.create(ENDPOINTS.BUY_MEMBERSHIP, {
          type,
          idCard: defaultCard._id,
          payMethod
        })

        if (res.status === 200) {
          const res2 = await getAccountInfo()
          if (res2.status === 200) {
            setProfile(res2.result)
          }
          setLoading(false)
          setView('success')
          setTimeout(() => {
            onCloseModal()
          }, 5000)
        } else {
          setLoading(false)
          setError(res.message_en)
        }
      } catch (error) {
        setLoading(error.message)
      }
    } else {
      toast.warning('You need a default credit card')
    }
  }

  if (loading) {
    return (
      <Modal isOpen={isOpen} onCloseModal={onCloseModal} backgroundColor>
        <ModalArrowLeft height='30px' onClick={handleBreak} />
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      </Modal>
    )
  }

  return (
    <>
      <Modal isOpen={isOpen} onCloseModal={onCloseModal} backgroundColor>
        <ModalArrowLeft height='30px' onClick={handleBreak} />
        <ModalContainer>
          <Title><h2>Buy Membership</h2></Title>

          <MainContainer>
            {
            view === 'main' &&
              <>
                { ((parseFloat(profile.balance) > parseFloat(config.membershipMonthly) && type === 'Monthly') || (parseFloat(profile.balance) > parseFloat(config.membershipAnnual) && type === 'Yearly')) &&
                  <PanelType>
                    <PanelItems>
                      <IconCheck
                        resizeMode='contain' src={payMethod === 'coins' ? Success : CheckOut} onClick={() => setPayMethod('coins')}
                      />
                      <TextPoints active={payMethod === 'coins'}>{t('WithCoins')}</TextPoints>
                    </PanelItems>
                    <PanelItems>
                      <IconCheck
                        resizeMode='contain' src={payMethod === 'TDC' ? Success : CheckOut} onClick={() => setPayMethod('TDC')}
                      />
                      <TextPoints active={payMethod === 'TDC'}>{t('WithTDC')}</TextPoints>
                    </PanelItems>
                  </PanelType>
                }
                <SubtitleContent>
                  <h3>Order Summary</h3>
                </SubtitleContent>
                <DefaultCreditCard
                  defaultCard={defaultCard}
                />
                <BalanceContent>
                  <CardFieldValues>
                    <Field>Membership type:</Field>
                    {type === 'Yearly' && <Value>Yearly</Value>}
                    {type === 'Monthly' && <Value>Monthly</Value>}
                  </CardFieldValues>
                  <CardFieldValues>
                    <Field>Total Order:</Field>
                    {type === 'Yearly' && <Value>{config.membershipAnnual} £</Value>}
                    {type === 'Monthly' && <Value>{config.membershipMonthly} £</Value>}
                  </CardFieldValues>
                  <CardFieldValues>
                    <Field>Next Renovation:</Field>
                    {type === 'Yearly' && <Value>{moment().add(1, 'year').format('MMM DD, YYYY')}</Value>}
                    {type === 'Monthly' && <Value>{moment().add(1, 'months').format('MMM DD, YYYY')}</Value>}
                  </CardFieldValues>
                </BalanceContent>
                <ButtonContainer>
                  {
                defaultCard !== null &&
                  <>
                    {type === 'Monthly' &&
                      <Button
                        buttonTitle={`Pay ${config.membershipMonthly} £`}
                        buttonWidth='194px'
                        buttonHeight='56px'
                        buttonFontSize='18px'
                        buttonActive='true'
                        buttonOnClick={handleSubscription}
                      />}
                    {type === 'Yearly' &&
                      <Button
                        buttonTitle={`Pay ${config.membershipAnnual} £`}
                        buttonWidth='194px'
                        buttonHeight='56px'
                        buttonFontSize='18px'
                        buttonActive='true'
                        buttonOnClick={handleSubscription}
                      />}
                  </>
              }
                </ButtonContainer>
              </>
            }
            {
            view === 'success' &&
              <>
                <ContentSucces>
                  <img src={GSuccess} />
                  <h3>Membership Activated</h3>
                  <p>Now you have full Acces to MeiXter's premium features</p>
                </ContentSucces>
              </>
            }
          </MainContainer>
        </ModalContainer>
      </Modal>
      <ToastContainer />
    </>
  )
}
