import React from 'react'
import { MarginPurple, Image } from './styles'
import { FaUserCircle } from 'react-icons/fa'

export const Photo = ({
  withPurple = true,
  photoImage,
  photoSize = '26px'
}) => (
  <>
    {
      photoImage
        ? <MarginPurple size={photoSize} withPurple={ withPurple}>
            <Image src={photoImage} alt='profile' />
          </MarginPurple>
        : <FaUserCircle size={photoSize} />
    }
  </>
)
