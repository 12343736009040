import styled from 'styled-components'
import {
  fontStyleTitle,
  fontStyleContent,
  scrollBar
} from '../../styles/general'

import IBackground from '../../assets/images/Bg_003.png'

export const MyWalletWrapper = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${IBackground});
  background-repeat: no-repeat;
  background-size: 100% 300px;
  background-position: bottom center;
`

// Content
const Content = styled.section`
  width: 100%;
  height: 100%;

  ${scrollBar}
`

export const MainContainer = styled(Content)`
  grid-area: content;
  display: flex;
  flex-direction: column;
`

export const BalanceContainer = styled.div`
  height: 210px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueSemiDark};
`

export const BalanceContent = styled.div`
  display: grid;
  width: 90%;
  height: 70px;
  grid-template-rows: 35% 65%;
  grid-template-columns: 30px 94px 1fr;
  grid-template-areas:  "     .         title-balance title-balance"
                        "icon-balance   coins-balance button-balance";
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueSemiDark};
`

const GeneralGridArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const IconBalance = styled(GeneralGridArea)`
  grid-area: icon-balance;
`

export const TitleBalance = styled(GeneralGridArea)`
  grid-area: title-balance;
  justify-content: flex-start;

  & p {
    ${fontStyleContent};
    color: ${({ theme }) => theme.colors.fucsia}
  }
`

export const CoinsBalance = styled(GeneralGridArea)`
  grid-area: coins-balance;
  justify-content: center;

  & h2 {
    ${fontStyleTitle};
  }
`

export const ButtonWithdrawalContent = styled(GeneralGridArea)`
  grid-area: button-balance;
  justify-content: center;
`

export const BuyCoinsContent = styled.div`
  display: grid;
  width: 90%;
  height: 70px;
  grid-template-rows: 35% 65%;
  grid-template-columns: 52% 48%;
  grid-template-areas:  "title-buy button-buy"
                        "coins-buy button-buy";
`

export const TitleBuy = styled(GeneralGridArea)`
  grid-area: title-buy;
  justify-content: flex-start;

  & p {
    ${fontStyleContent};
    color: ${({ theme }) => theme.colors.fucsia}
  }
`

export const CoinsBuyContent = styled(GeneralGridArea)`
  grid-area: coins-buy;
  display: grid;
  grid-template-rows: 45% 55%;
  grid-template-columns: 42% 20% 38%;
  grid-template-areas:  "input-buy button-buy-up   coins-text"
                        "input-buy button-buy-down coins-text";
`

export const InputBuyCoins = styled.div`
  width: 100%;
  height: 100%;
  grid-area: input-buy;

  & input{
    width: 100%;
    height: 100%;
    ${fontStyleContent}
    font-size: 22px;
    border: none;
    outline: none;
    background-color: transparent;
    text-align: center;
  }

  & input[type=number]::-webkit-inner-spin-button,
  & input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    /* margin: 0; */
  }

  border-bottom: 1px solid ${({ theme }) => theme.colors.blueSemiDark};
`

export const ButtonControl = styled.button`
  width: 100%;
  height: 100%;
  grid-area: ${({ gridArea }) => gridArea};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: ${({ gridArea, theme }) => gridArea === 'button-buy-down' && `1px solid ${theme.colors.blueSemiDark}`};
`

export const TextCoins = styled.div`
  grid-area: coins-text;
  display: flex;
  align-items: center;
  justify-content: center;

  & p {
    ${fontStyleContent};
    font-size: 14px;
    text-align: center;
  }
`

export const ButtonBuyContent = styled(GeneralGridArea)`
  grid-area: button-buy;
  flex-direction: column;
  justify-content: space-between;

  & p {
    ${fontStyleContent};
    color: ${({ theme }) => theme.colors.violet};
    font-size: 10px;
    text-align: center;
    line-height: 12px;
  }
`

export const SubscriptionContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CarrouselContainer = styled.div`
  height: 180px;
  width: 100%;

  & > .swiper-container {
    width: 250px;
    height: 100%;
  }

  .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
    bottom: 0;
  }

  #carousel > div.swiper-pagination.swiper-pagination-bullets{
    position: absolute;
    border-radius: 0px;
    height: 10px;
    width: 100%;
    bottom: 8px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 50px;
  }

  #carousel > div.swiper-pagination.swiper-pagination-bullets > span{
    width: 20px;
    height: 3px;
    background-color: ${({ theme }) => theme.colors.whiteTrasparent};
    border-radius: 2px;
  }

  #carousel > div.swiper-pagination.swiper-pagination-bullets > span.swiper-pagination-bullet.swiper-pagination-bullet-active{
    width: 20px;
    height: 3px;
    background-color: ${({ theme }) => theme.colors.greenActive};
    border-radius: 2px;
  }

`

export const SlideContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  & img {
    width: 80px;
    height: 80px;
  }

  & h2 {
    ${fontStyleContent};
    text-align: center;
  }
`

export const ButtonsContainer = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`

export const ButtonSubscriptionContainer = styled.div`
  width: 120px;
  height: 100%;

  & h2 {
    ${fontStyleContent};
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: ${({ theme }) => theme.colors.fucsia};
    margin-top: 4px;
  }
`

const ButtonSubscription = styled.button`
  height: 50px;
  width: 100%;
  padding: 0;
  border-radius: 4px;
  cursor: pointer;

  & h3 {
    ${fontStyleTitle};
  }

  & p {
    ${fontStyleContent};
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }
`

export const MontlyButton = styled(ButtonSubscription)`
  box-shadow: 0px 0px 1px ${({ theme }) => theme.colors.violet}, inset 0px 0px 2px ${({ theme }) => theme.colors.violet};
  border: 2px solid ${({ theme }) => theme.colors.violet};
`

export const YearlyButton = styled(ButtonSubscription)`
  box-shadow: 0px 0px 1px ${({ theme }) => theme.colors.cyan}, inset 0px 0px 2px ${({ theme }) => theme.colors.cyan};
  border: 2px solid ${({ theme }) => theme.colors.cyan};
`

export const ContentSucces = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: inherit;
  margin-bottom: 10px;

  & img{
    width: 120px;
  }

  & h3 {
    ${fontStyleContent}
    font-size: 16px;
  }

  & p {
    ${fontStyleContent}
    color: ${({ theme }) => theme.colors.gray} ;
    margin-top: 6px;
    font-size: 14px;
    text-align: center;
  }
`

export const MembershipStatus = styled.p`
    ${fontStyleContent}
    color: ${({ theme }) => theme.colors.gray} ;
    margin-top: 6px;
    font-size: 12px;
    text-align: center;
`
