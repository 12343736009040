import * as React from 'react'

export const MenuLearnActive = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 43 38'
    {...props}
  >
    <path fill='none' d='M0 0h43v38H0z' />
    <g transform='translate(-11.183 -11.183)'>
      <image
        overflow='visible'
        width={39}
        height={38}
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAmCAYAAABH/4KQAAAACXBIWXMAAAsSAAALEgHS3X78AAAA GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABIhJREFUeNqsmAlv2zgUhE2ZvmK7 OXa96bGL/f9/q2jRbII6cepbB5cKvldMCPlMBAwUKTI5nHeSrnXgCiG41hGXcy603vlyBwg5+WYX ySD38J5E3Q5iNbIEruF7I1QlCO9B0O0g1o7wER2BkrSJayJlRBGRg4J3bybodxDrRgwiLiKGEX0I tmVBARLbiFXEMmIRsTY145gvJk799ljSPnnOIFGTuoq4ibiO+ABBL+RKlKqJzSKmkDczFxBMfdbI HlTWi2oZzz3ITCI+R3yE4IWQC0xeq/Yr4oH/1YQ3kA7ipy5RvIJ4tU9Jn/ifx5yXkPo34gsK9oWY KbPm+wqzPuESHfFLj6IZz4X4ZsFv95rVNZh0AsEaY/6vA+8KMLNAJovtJcouwQvZWsEm9XySOmyw DxC8IiAyBv3FoGtMuubdlPuGcbuMNcIKIxZeL+w54lEsUB4TEEbOInXIPYPEFN96hOAGgksmfCI4 WqhfL/BPcMnYS8aoGNP8sxI3aDSrSwh28ZVCnP5rxH3EHGJmZpuokIC6JaBueQ4sICe6O5I3X4Iy NW0aEJbnUgdeolhN8D/IFVIhzDRG7K+IvwmmCe/XoJ2UvZ25zzc4tNMVSaJdiyNv5feZRPoQM36G 3C3vShL0Su4576tjk/Cuol7JQBlK9PCtrrjCGKU+od6IMebisxY860T9k8mlJNtSyi5JzkOIdiFz LUk7QKR2he8RPyJ+QvZVorZSdw45DZYLSHxEoWsIdyE5gHyB49fEvoF7InsrizVCZUrQn9AkWJJu kf9qs/2DGYfif5XkvwcU+wbJGYp5GS+HbA7B3wn5FOVMvS7q1Wb9A6cfSdpZYLYZSv3gPoNEh8W0 8WPrZpbmf6bgKcqZeoEJzIQjKW9rInErlWOKKTcQGpNuBizmmW+CJOPyVLPuIptJXsykYzGia/Gx Hi5xC8mSAGlJs2pZ4WTlKplcfSUXsypK64oh3scdJrhEiUDmowsUrqPXnUIubZUWmGQmkbeRxiCX lsgqTw83uIJcxXdTaWazc6LVlNtKUr1nsDljWd185N1GSltbfNXa/8Dfg2Qb4I4h5xK/chKRUwbc JNE6x4+seyklbfQSVFJh2umGy+/Z6gVxekuwfUxmtfInag0kx60w94LnDpNaZFtFsUTtjqmt2mFo QfaS125EPWvFN1JzKylLHSKyK+QmUvI6Mk/V1NP5BsVs47KVaBoxsGPCXPxITWKLG5LLCjHrQNr/ sbTtufSGlTYCPnH2Qvah1tqMMecNKlzv2V2lJwBB0ohVljFmLZJ5tumGx7PpVdVW0nY/oYIN2Jb+ LOw5Q0nbICfR6plrIXM8M2+hxxk+yWG5bPEeJAq3sjVs7evB9lyakG1Pcsc8T8z7aj+RBkQhO/g7 2XU9Jpvqpl7vmIOikLT9d2Amyr1umTCtmXWDzBkrnTccR7gz6nBI6q5tEbUxeDHrrpZJTRuEaL8p g59JrpQdm7VKKzHpm4/Azr00kvNDx2ZvPTxsvUHBgweO73Hsei7Bg0e1Byc89sD6rGPVA+dz/wsw AEvHRv7kz/20AAAAAElFTkSuQmCC'
        transform='translate(13.183 11.183)'
      />
      <path
        fill='#FFF'
        d='M23.6 42.7c-1.9 0-3.4-1.5-3.4-3.4V21.1c0-1.9 1.5-3.4 3.4-3.4h7c.7 0 1.3.6 1.3 1.3 0 .7-.6 1.3-1.3 1.3h-7c-.4 0-.8.4-.8.8v18.2c0 .4.4.8.8.8h18.2c.4 0 .8-.4.8-.8v-7c0-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3v7c0 1.9-1.5 3.4-3.4 3.4H23.6zm5.3-7.5h-.5s-.1 0-.1-.1c0 0-.1 0-.1-.1-.1 0-.1-.1-.2-.2l-.2-.2s0-.1-.1-.1c0 0 0-.1-.1-.1V28c0-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3v2.8l9.3-9.3c.5-.5 1.3-.5 1.8 0s.5 1.3 0 1.8L32 32.6h2.8c.7 0 1.3.6 1.3 1.3 0 .7-.6 1.3-1.3 1.3h-5.9z'
      />
    </g>
  </svg>
)

export const MenuLearnInactive = props => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 43 38' {...props}>
    <path fill='none' d='M0 0h43v38H0z' />
    <path
      fill='#FFF'
      d='M12.417 31.517c-1.9 0-3.4-1.5-3.4-3.4v-18.2c0-1.9 1.5-3.4 3.4-3.4h7c.7 0 1.3.6 1.3 1.3 0 .7-.6 1.3-1.3 1.3h-7c-.4 0-.8.4-.8.8v18.2c0 .4.4.8.8.8h18.2c.4 0 .8-.4.8-.8v-7c0-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3v7c0 1.9-1.5 3.4-3.4 3.4h-18.2zm5.3-7.5h-.3-.1-.1s-.1 0-.1-.1c0 0-.1 0-.1-.1-.1 0-.1-.1-.2-.2l-.2-.2s0-.1-.1-.1c0 0 0-.1-.1-.1v-.1-.1-6.2c0-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3v2.8l9.3-9.3c.5-.5 1.3-.5 1.8 0s.5 1.3 0 1.8l-9.3 9.3h2.8c.7 0 1.3.6 1.3 1.3 0 .7-.6 1.3-1.3 1.3h-5.9z'
      opacity={0.4}
    />
  </svg>
)

export const MenuSessionActive = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 43 38'
    {...props}
  >
    <image
      overflow='visible'
      width={43}
      height={38}
      xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAAmCAYAAABdw2IeAAAACXBIWXMAAAsSAAALEgHS3X78AAAA GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABJhJREFUeNq8metS20gUhDWyhPGF GAJkk9TuJu//VvmzqQQSDMbGli3Njsh3SDMr+YLNqqpLwpLOtHrObQaXbDi89y75nw7nnF97fwNB J8+8JmkvZ99G3LUQrZFGcK9E2AhWEXxM2DUQNXK5IAvovAJhI1oGrAKWgkfSStg1EK1JHQX0AgYB /YBjSB9SYVW0JjcPmAVMAx4CCj7iiXDWMPU10WHAacBbzicBXZ4/NNla0UXAJGAc8JPzPb8Hev7R hzN52aa+B8H3AR8D3gWM+L3TQNTvEvAN75YoeRvwHbESU1WeSTIxYmQHKFoT/cx5hBEnivgdiSZR hnFir4Bsn2cK3KJA+UfSWeQCOS+coehHFO7zzFJ8yctguyrrJDZybPS4N4O4uoKrecZukBFMQ9Qc QTRlqiYEwEII+xeo2iEGBsRDj3FszCE8MsZOYjcwIzkP9sR/aqI/8Kkbvt7Sy65H0wyeQ96ykGWf Z+kya/hqS18dmfo7iH7hPMEdqhcoaxnnBKIJxE5FsE5TmsxajJn0lgOnKFoT/Qefmu9B9pjpro83 EB3ITGnVTNrIJi2ppWDqJxC9ifx2F7LmrwnqzqKgbT22IVthyDLBAsyjAdwWjYqRTbBRYLfcZpay LStNFRFXrMufbfm4jAhu5U7ZC8pjE4G0ITB9RKraws7ByK6rRDkRbkghV8h0r6SQvOjI9iRqhaRH Ih8S1R3IzaSQPPBb8ppkXQvSKA1dkNxPUbqgZF5TULykuzabewVY26pB+94TiP5JL3FBaqqJXXHt pSFZtthz+yqrFS2PkErJ/BDwd8AnqlKXaR9iw3x3xd8ZyKWsplF22Ug2jlAz3JVmo2dNMapeouhf 4ELIZihpK4BClOyLvS6Eq2g95tvI+ijdWMLPMGpl8VbSUK3qHyj7AeIj1OpKYz2h3VtKJ2UdlnVd mVQyzcE+Jhsv3DTdpNK+naOQ+dg5JN9zPZTUpf58z3tL6eTOuTeSXtaa7rlUtifCqqxVpwXTds95 wHNDVhAzUecSopcof6TLENQ9RX1LXRZwZ2CALVuH3TH2XILyl7L1QuxxRfY7Uh944Sdpx9ZljvNI MsE7iJyhfkf8zYrFEAUX2M85D0h7tjqY0dVdk/K0Z/a6YFSyM7qqb6hQMaBnwJSBjMRbpvtYbDkJ TvuAOUKUKNfB3pj7dSx8BXGD75sCbIXBMfcqXjrhb3P+Yz5E9xTipB4v7y2bdMWHZ9y3AvIdjMVt qmcBhiuoz94x8BI/GjCgTb/tJdguyiLqqEzdUtrBFSggO5bf59Ir33L/qcd9tskRBdlSrs3pu9Kk 9CWqLeUseEZbQy2hC0hY6b3ieioFw3Zkni3Bdfso0+3GX3H2RFi3daziHKHySqJ+GqWsJOquKlHz Gp/8RgBPpQFfSroqY6L/UVYIl0JmKSU3k6+2YLxC4bxha8mLO2mWuWHGzC/LTTuIa5ciDXu08VJd y+VRy9ZSvI57kDTVlPj9uk3lrRphIZ5KDxs3NekaspW4lWGlK4hNu94772bvsdG89YbxwcjuuYW/ 1Vb8Qcnu+8+RXQnq8a8AAwBxOSgK1OapmwAAAABJRU5ErkJggg=='
    />
    <path
      fill='#FFF'
      d='M15.8 31.4c-.5 0-1-.3-1.3-.8-.3-.5-.2-1 .1-1.5l5.6-8.7c.3-.4.7-.7 1.2-.7s1 .3 1.2.7l5.6 8.7c.3.4.3 1 .1 1.5-.3.5-.7.8-1.3.8H15.8zm2.7-2.9h5.9l-3-4.6-2.9 4.6zm11.9-1.8c0-.8.6-1.4 1.4-1.4 1.1 0 1.9-.9 1.9-1.9V11.2c0-1.1-.9-1.9-1.9-1.9H11.1c-1.1 0-1.9.9-1.9 1.9v12.2c0 1.1.9 1.9 1.9 1.9.8 0 1.4.6 1.4 1.4s-.6 1.4-1.4 1.4c-2.7 0-4.8-2.2-4.8-4.8V11.2c0-2.7 2.2-4.8 4.8-4.8h20.7c2.7 0 4.8 2.2 4.8 4.8v12.2c0 2.7-2.2 4.8-4.8 4.8-.8 0-1.4-.7-1.4-1.5z'
    />
  </svg>
)

export const MenuSessionInactive = props => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 43 38' {...props}>
    <path fill='none' d='M0 0h43v38H0z' />
    <path
      fill='#FFF'
      d='M15.8 31.4c-.5 0-1-.3-1.3-.8-.3-.5-.2-1 .1-1.5l5.6-8.7c.3-.4.7-.7 1.2-.7s1 .3 1.2.7l5.6 8.7c.3.4.3 1 .1 1.5-.3.5-.7.8-1.3.8H15.8zm2.7-2.9h5.9l-3-4.6-2.9 4.6zm11.9-1.8c0-.8.6-1.4 1.4-1.4 1.1 0 1.9-.9 1.9-1.9V11.2c0-1.1-.9-1.9-1.9-1.9H11.1c-1.1 0-1.9.9-1.9 1.9v12.2c0 1.1.9 1.9 1.9 1.9.8 0 1.4.6 1.4 1.4s-.6 1.4-1.4 1.4c-2.7 0-4.8-2.2-4.8-4.8V11.2c0-2.7 2.2-4.8 4.8-4.8h20.7c2.7 0 4.8 2.2 4.8 4.8v12.2c0 2.7-2.2 4.8-4.8 4.8-.8 0-1.4-.7-1.4-1.5z'
      opacity={0.4}
    />
  </svg>
)

export const MenuTeachActive = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 43 38'
    {...props}
  >
    <path fill='none' d='M0 0h43v38H0z' />
    <g transform='translate(-37.5 -37.5)'>
      <image
        overflow='visible'
        width={39}
        height={38}
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAmCAYAAABH/4KQAAAACXBIWXMAAAsSAAALEgHS3X78AAAA GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABJNJREFUeNqsWIdy4jAQxbIoCQHS r8/9/2/dXC69QSiuOinzNvOy2MaBeGbHYGlXT9ulqLPhcc5FnRZPFEVuG/46vtexDQIjmlO3iKO3 q5Bfx7/Gp4FGNcACGUVRzQKBSiJHsjW/5i01LwOMaoDFnqynLhEvIgKCwMJT7ikDlRgzxGsh0xBf STwZZBQM0NYA63na87TvaehpgEVi2pCDsNTT0tMC7xTjImOIt/ALOOab43d4Co/l1cRWqVp2G0Ad ejr2dORpDICWwBXYcRD67OkR7wXG98F7BFkCUHjDvKmne08Pyk2KN81Bawb/+wBz5um7p69YYJ/A OZgh7H7m6Y4WFtNNwBtknENmH/wZ8e3RtwzAXv3PquCwmCyCf3v6AQ0OCFgJcCvMd9DgHAsYgDkF /y9PJ5hrsKmgtX9Yewmwc4xFQWFWRRab9AwAA40wnpPz1qUmsUDYzAE2FjT3Bf8NZDxhUwHkLfm1 aL5jVeoQzY0B8BAOHcYS2l2CHa7w7R6LrGCWLgXWABs+wCZjgHPwUQm4nk5ZVgWDrYgyg0WD097A 8RcAmOD3DAvNYXKxQh/yupRKDH5LurKUBd6lNqsyOQPsgSnH4gHYH7ynAJZBg0KONDXCBiUIxEfF PUT7b/lNVxhbUWpilTBzaOcJwC6gpQRjIjCGpkdw/lNEuQSM5DKJ9Dk2+QLQGVUYp8FpDXI+S8l8 U4BbQYgh3xrD+UP6+AZwXcx9IU3lAHpH/rrE97JKc01FvSATJliMK4HkxhCRP0HnMG9OPjkjjaf4 dkl+nHGNtS26IUfqLjlJwpR9mFKS9g9KGxKRtxRMK6rHcwTagwbXRnNVHQh3HTHMOaG8eIZAcNDU Nfz0En6b0EYlPc0A+kNmbeoBI5VsJ1SiCorwv6AblWo6VJtTskhnrSvZAqBurSw0siJzXQDYFZw+ x9wBADqVGdjPtwan/TGjdLOCNm5gymt8TwnYGL8lqFIKlK18rsr/JLFKfcygiTlSxB0BNpScTwBy RU3DQlJYKKlto7UuvbDGHvH/mbqMKdXbEsm4B3DH+L/E+BMUZeoqRFs/052wdLAJTOWoKUgwJ6Zk vYcAGuL7PpXKd/XVtgRl6B2rs0ROacAQaKmZzCfNwACUq6Yg+ojmOCq7VA0GZNpINaHc5Qj1FfUo wutOZ2vgnKoIHerLxJknZMai4ZjJ/FLexhUHJlOnGVsBrOCEiDnSHZ/j2wh+VVYcpKtcogc/O0Mz cNCkMQ3OqbNBSlnbAowAG6s6uOmRxlPOJsfQXkZHxU7VJllzDGxJ/dcIZjiBBo7JpK6F5qrOwjHK m24k3jebIdmFh8BJjnoCDQGwD6FDZXbXog4bKnWGjoEJddRrm7UNZegWvhFDmwOV4zYBk3FOOQUd qOUwPatqNKsCIqcT/BWduh7VobrT5CsNEVxS23+PNR6qGs03cDBtSQePKYAVaK/1dUS0Qz3OqB5L oznXjWZVnmPTOgKqL3J2Acd3LHyJs2bWba/Adm2xNl5/fcbl4S7tVuPF4Wddu24LsPHKtdWCbS+s t+r1Gy6rw/NfgAEAVzYv94ieV9kAAAAASUVORK5CYII='
        transform='translate(39.5 37.5)'
      />
      <g fill='#FFF'>
        <path d='M70.2 57.3c-.7 0-1.3.6-1.3 1.3v7c0 .4-.4.8-.8.8H49.9c-.4 0-.8-.4-.8-.8V47.4c0-.4.4-.8.8-.8h7c.7 0 1.3-.6 1.3-1.3s-.6-1.3-1.3-1.3h-7c-1.9 0-3.4 1.5-3.4 3.4v18.2c0 1.9 1.5 3.4 3.4 3.4h18.2c1.9 0 3.4-1.5 3.4-3.4v-7c0-.7-.6-1.3-1.3-1.3z' />
        <path d='M71.5 45v-.1-.1s0-.1-.1-.1c0 0 0-.1-.1-.1 0-.1-.1-.1-.1-.2-.1-.1-.1-.1-.2-.1 0 0-.1 0-.1-.1 0 0-.1 0-.1-.1h-.1-6.3c-.7 0-1.3.6-1.3 1.3s.6 1.3 1.3 1.3H67L57.7 56c-.5.5-.5 1.3 0 1.8s1.3.5 1.8 0l9.3-9.3v2.8c0 .7.6 1.3 1.3 1.3s1.3-.6 1.3-1.3v-6c.1-.1.1-.2.1-.3z' />
      </g>
    </g>
  </svg>
)

export const MenuTeachInactive = props => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 43 38' {...props}>
    <path fill='none' d='M0 0h43v38H0z' />
    <g opacity={0.4} fill='#FFF'>
      <path d='M32.7 19.8c-.7 0-1.3.6-1.3 1.3v7c0 .4-.4.8-.8.8H12.4c-.4 0-.8-.4-.8-.8V9.9c0-.4.4-.8.8-.8h7c.7 0 1.3-.6 1.3-1.3s-.6-1.3-1.3-1.3h-7C10.5 6.5 9 8 9 9.9v18.2c0 1.9 1.5 3.4 3.4 3.4h18.2c1.9 0 3.4-1.5 3.4-3.4v-7c0-.7-.6-1.3-1.3-1.3z' />
      <path d='M34 7.5v-.1-.1s0-.1-.1-.1c0 0 0-.1-.1-.1 0-.1-.1-.1-.1-.2-.1-.1-.1-.1-.2-.1 0 0-.1 0-.1-.1 0 0-.1 0-.1-.1h-.1-6.3c-.7 0-1.3.6-1.3 1.3s.6 1.3 1.3 1.3h2.6l-9.3 9.3c-.5.5-.5 1.3 0 1.8s1.3.5 1.8 0l9.3-9.3v2.8c0 .7.6 1.3 1.3 1.3s1.3-.6 1.3-1.3v-6c.1-.1.1-.2.1-.3z' />
    </g>
  </svg>
)

export const MenuMeActive = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 43 38'
    {...props}
  >
    <path fill='none' d='M0 0h43v38H0z' />
    <image
      overflow='visible'
      width={37}
      height={38}
      xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAmCAYAAABDClKtAAAACXBIWXMAAAsSAAALEgHS3X78AAAA GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABJ1JREFUeNq8WIly2jAUtGwZc5gj pEmm1/9/WDs90jYQwmFjsCp19qXLiw0hmZaZHYyxpNW+0zLRKz7OOXPsf2OMe8m85hUkDI3X8zj6 ducSNGeSESIxwbSQqgny+1nkzBlkAoEEsIBcxzRXWHQP7DwqfMu9k8qZE4RYldSjA3QB+W0VqUCi 9Cg8NvguQXAv6rURMycIiRJh4Z7HwCP3GOK6D3Ipno2waAUyDx73HguPpcca5ES5RmLmCCGLxYTM xOMCCNcj3O+RKSOoEEitQOjO46fHL485yG3IrE+I2SMKdaDE2OPS48rj2uMNiI1AqEN+JZ8dFl6A 0BgKd0Euhmp//M+ve+BjtkG5mAhNQeStxztcXypCsYpMUb8CmSGQkx8m5H8lReghKWW2DAsHdT54 fASpSyzQJXUcTciBYSk4usr3JBjEt6qwvqhlG1RKoVIw0Y3He+AKu5Wd6vzkGq5jbHCMMTFU2SIi C4rIx83ZBl/KoIaY7gbXfUwqEyQqWJhkTXmpxrgeNloiCB7g9EKsFrXYOUXyLkhdgMwIRGtMuMFO 6yNpRRJoSXmqJreYYn7tCgc+xaYTUhNFaAMfENOluC++UdPGYvpvA4KWLDHC/EMouIJaYV5nyXSG lBoAXZAu1G47pFyBSXcYz+NErRJE5X4X/pkrpUzgY5X5EoqWDPcqMoOhiBNSIUHOcJ3BLGNcO4xf k0kz2nwPv1Oun7ah+nOxdfCfFSYW88oCWzjrHKR7VAUshX4h2Zt8MCXVkyaf0slPWO/JL9aYhCc/ BSa1xb1M5bCO6jRMU0bnCcVZtzBPRKQSKDIC+QIqSrbnJCnjO3g2JWKpbn/sc5tOIuloxz3yjwoL 5Lhfw7ROlRHdmyW6WbQtiztKpgY7zNTOMnLWPhRIKJoKpYbMw22OrgRPfMopRSLKK47I5FSMU+V/ CS1a47mceqi+ShcVtTD1QZlRZLhQJjSR5CHxGUPpoqD/pRMwVFoS/J+SipztD+ofK1VTmMsgRxWe y1CK59bUVVb4fwjifagsdXNHxVi6UPneUp2MbCiA4aPCdwUU2PGAcpCh3BUauFskzy0UmqCQX4Fc jvEV1U1pABeK1BPzSRtboILPMajAMxmF9AakQhf5Hd8S8gV1BX1AIkzMJVVgQa3x48tErMy3I1Iz LDajglmrbK5RkulLMhn37dKz3zWQ+ht9ZEIpoEsQGlG0JdSscfgPaZwUZGmTpRrUIBBU/UrqLjHu 4M3GNrzVyo5mqg8Xsw2odo1x3YdSMXUAMRYUHwrqfPb45PGtxQKHeYrUkhfJBb1ksoJTEJMcNgH5 HeW2FIvNYM451PkC3GL+suk1y7acAWwp7dcq/K+Re8RM3PpwsEi03cNUP0DmDvfW1MG2v/dBrYgc mZ16Q23KlJKopQK+J6X5DXmGcffqZXR/8mVUEds1dApr2nlOuStuSCuSNpbAivLUea/tLQccCTVl UoSlO+UGraaTlpJShKSMio+HzjrgaDkK4mOglLrThMwX0avVXtXRPfvPi46CWsjFLYdmbb0XH5o9 +0TvpceLZx0t/pPjxf95CBs+vwUYAL6uVouPsYTEAAAAAElFTkSuQmCC'
      transform='translate(3)'
    />
    <path
      fill='#FFF'
      d='M21.5 6.5C14.6 6.5 9 12.1 9 19s5.6 12.5 12.5 12.5S34 25.9 34 19 28.4 6.5 21.5 6.5zm0 22.6c-2 0-3.9-.6-5.5-1.6 0-.1 0-.2.1-.3V27c0-.1.1-.3.1-.4v-.1c.1-.2.1-.3.2-.5 0 0 0-.1.1-.1.1-.1.1-.2.2-.3 0-.1.1-.1.1-.2.1-.1.1-.2.2-.3 0-.1.1-.1.1-.2.1-.1.1-.2.2-.2 0-.1.1-.1.1-.2l.2-.2c.1-.1.1-.1.2-.1s.1-.1.2-.2.1-.1.2-.1.2-.1.2-.2c.1 0 .1-.1.2-.1s.2-.1.2-.1c.1 0 .1-.1.2-.1s.2-.1.3-.1c.1 0 .1-.1.2-.1s.2-.1.3-.1c.1 0 .1 0 .2-.1.1 0 .2-.1.3-.1h2.4c.1 0 .2 0 .3.1.1 0 .1 0 .2.1.1 0 .2.1.3.1.1 0 .1.1.2.1s.2.1.3.1c.1 0 .1.1.2.1s.2.1.2.1c.1 0 .1.1.2.1.1.1.2.1.2.2.1.1.1.1.2.1s.1.1.2.2l.2.2.2.2c.1.1.1.1.1.2l.2.2c0 .1.1.1.1.2.1.1.1.2.2.3 0 .1.1.1.1.2.1.1.1.2.2.3l.1.1c.1.2.1.3.2.5v.1c0 .1.1.3.1.4v.2c0 .1 0 .2.1.3-1.6 1-3.5 1.5-5.5 1.5zM18.2 17c0-1.8 1.5-3.3 3.3-3.3 1.8 0 3.3 1.5 3.3 3.3 0 1.8-1.5 3.3-3.3 3.3-1.8-.1-3.3-1.5-3.3-3.3zM29 25.8c-.1-.2-.1-.4-.2-.5v-.1c-.1-.2-.2-.3-.2-.5-.1-.2-.2-.3-.3-.4l-.1-.1c-.1-.1-.2-.3-.3-.4 0 0 0-.1-.1-.1-.1-.1-.2-.3-.3-.4-.1-.1-.2-.3-.4-.4l-.1-.1c-.1-.1-.3-.2-.4-.4l-.1-.1c-.1-.1-.3-.2-.4-.3-.1-.1-.3-.2-.4-.3l-.1-.1c-.2-.1-.3-.2-.5-.3H25c2.4-2 2.8-5.5.9-8s-5.5-2.8-8-.9-2.8 5.5-.9 8c.3.3.5.6.9.9h-.1c-.2.1-.3.2-.5.3l-.1.1c-.2.1-.3.2-.4.3-.1.1-.3.2-.4.3l-.1.1c-.1.1-.3.2-.4.4l-.1.1-.4.4c-.1.1-.2.3-.3.4l-.1.1c-.1.1-.2.3-.3.4l-.1.1c-.1.1-.2.3-.3.4-.1.2-.2.3-.2.5v.1c-.1.2-.1.3-.2.5-1.7-1.9-2.6-4.3-2.6-6.8.1-5.6 4.7-10.1 10.3-10 5.5.1 10 4.5 10 10 .1 2.5-.9 5-2.6 6.8z'
    />
  </svg>
)

export const MenuMeInactive = props => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 43 38' {...props}>
    <path fill='none' d='M0 0h43v38H0z' />
    <path
      opacity={0.4}
      fill='#FFF'
      d='M21.5 6.5C14.6 6.5 9 12.1 9 19s5.6 12.5 12.5 12.5S34 25.9 34 19 28.4 6.5 21.5 6.5zm0 22.6c-2 0-3.9-.6-5.5-1.6 0-.1 0-.2.1-.3V27c0-.1.1-.3.1-.4v-.1c.1-.2.1-.3.2-.5 0 0 0-.1.1-.1.1-.1.1-.2.2-.3 0-.1.1-.1.1-.2.1-.1.1-.2.2-.3 0-.1.1-.1.1-.2.1-.1.1-.2.2-.2 0-.1.1-.1.1-.2l.2-.2c.1-.1.1-.1.2-.1s.1-.1.2-.2.1-.1.2-.1.2-.1.2-.2c.1 0 .1-.1.2-.1s.2-.1.2-.1c.1 0 .1-.1.2-.1s.2-.1.3-.1c.1 0 .1-.1.2-.1s.2-.1.3-.1c.1 0 .1 0 .2-.1.1 0 .2-.1.3-.1h2.4c.1 0 .2 0 .3.1.1 0 .1 0 .2.1.1 0 .2.1.3.1.1 0 .1.1.2.1s.2.1.3.1c.1 0 .1.1.2.1s.2.1.2.1c.1 0 .1.1.2.1.1.1.2.1.2.2.1.1.1.1.2.1s.1.1.2.2l.2.2.2.2c.1.1.1.1.1.2l.2.2c0 .1.1.1.1.2.1.1.1.2.2.3 0 .1.1.1.1.2.1.1.1.2.2.3l.1.1c.1.2.1.3.2.5v.1c0 .1.1.3.1.4v.2c0 .1 0 .2.1.3-1.6 1-3.5 1.5-5.5 1.5zM18.2 17c0-1.8 1.5-3.3 3.3-3.3 1.8 0 3.3 1.5 3.3 3.3 0 1.8-1.5 3.3-3.3 3.3-1.8-.1-3.3-1.5-3.3-3.3zM29 25.8c-.1-.2-.1-.4-.2-.5v-.1c-.1-.2-.2-.3-.2-.5-.1-.2-.2-.3-.3-.4l-.1-.1c-.1-.1-.2-.3-.3-.4 0 0 0-.1-.1-.1-.1-.1-.2-.3-.3-.4-.1-.1-.2-.3-.4-.4l-.1-.1c-.1-.1-.3-.2-.4-.4l-.1-.1c-.1-.1-.3-.2-.4-.3-.1-.1-.3-.2-.4-.3l-.1-.1c-.2-.1-.3-.2-.5-.3H25c2.4-2 2.8-5.5.9-8s-5.5-2.8-8-.9-2.8 5.5-.9 8c.3.3.5.6.9.9h-.1c-.2.1-.3.2-.5.3l-.1.1c-.2.1-.3.2-.4.3-.1.1-.3.2-.4.3l-.1.1c-.1.1-.3.2-.4.4l-.1.1-.4.4c-.1.1-.2.3-.3.4l-.1.1c-.1.1-.2.3-.3.4l-.1.1c-.1.1-.2.3-.3.4-.1.2-.2.3-.2.5v.1c-.1.2-.1.3-.2.5-1.7-1.9-2.6-4.3-2.6-6.8.1-5.6 4.7-10.1 10.3-10 5.5.1 10 4.5 10 10 .1 2.5-.9 5-2.6 6.8z'
    />
  </svg>
)
