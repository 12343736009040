export const LessonsReducer = (initialState, action) => {
  switch (action.type) {
    case 'REQUEST_START':
      return {
        ...initialState,
        loading: true,
        error: null
      }
    case 'REQUEST_ERROR':
      return {
        ...initialState,
        loading: false,
        error: action.error
      }
    case 'CHANGE_STATE_LOGIN_MODAL':
      return {
        ...initialState,
        isLoginModalOpen: action.payload
      }
    case 'CHANGE_STATE_BOOKING_MODAL':
      return {
        ...initialState,
        isBookingModalOpen: !initialState.isBookingModalOpen
      }
    case 'CHANGE_STATE_TEACHER_MODAL_PANEL':
      return {
        ...initialState,
        isTeacherDetailsModalOpen: !initialState.isTeacherDetailsModalOpen
      }
    case 'CHANGE_STATE_TAG_MODAL_PANEL':
      return {
        ...initialState,
        isTagsUpdateModalOpen: !initialState.isTagsUpdateModalOpen
      }
    case 'CHANGE_STATE_CREATE_LESSON_MODAL':
      return {
        ...initialState,
        isCreateLessonModalOpen: !initialState.isCreateLessonModalOpen
      }
    case 'CHANGE_STATE_TEACH_CALENDAR_MODAL':
      return {
        ...initialState,
        isTeachCalendarModalOpen: !initialState.isTeachCalendarModalOpen
      }
    case 'CHANGE_STATE_FILTER_BY_TAGS_MODAL':
      return {
        ...initialState,
        isFilterByTagsModalOpen: !initialState.isFilterByTagsModalOpen
      }
    case 'CHANGE_STATE_STATISTICS_MODAL':
      return {
        ...initialState,
        isStatisticsModalOpen: !initialState.isStatisticsModalOpen
      }
    case 'CHANGE_STATE_MY_WALLET_MODAL':
      return {
        ...initialState,
        isMyWalletModalOpen: !initialState.isMyWalletModalOpen
      }
    case 'CHANGE_STATE_ISSUE_MODAL':
      return {
        ...initialState,
        isIssueModalOpen: action.payload
      }
    case 'CHANGE_ACTIVE_LESSONS':
      return {
        ...initialState,
        lessons: {
          ...initialState.lessons,
          activeLessons: action.payload
        }
      }
    case 'GET_MAIN_LESSONS':
      return {
        ...initialState,
        loading: false,
        lessons: {
          ...initialState.lessons,
          mainLessons: [...initialState.lessons.mainLessons, ...action.payload]
        }
      }
    case 'GET_MAIN_LESSONS_LANGUAGE':
      return {
        ...initialState,
        loading: false,
        lessons: {
          ...initialState.lessons,
          mainLessons: [...action.payload]
        }
      }
    case 'GET_MAIN_FREE_LESSONS':
      return {
        ...initialState,
        loading: false,
        lessons: {
          ...initialState.lessons,
          mainFreeLessons: [...initialState.lessons.mainFreeLessons, ...action.payload]
        }
      }
    case 'GET_SAVED_LESSONS':
      return {
        ...initialState,
        loading: false,
        lessons: {
          ...initialState.lessons,
          savedLessons: action.payload
        }
      }
    case 'GET_FOLLOWED_LESSONS':
      return {
        ...initialState,
        loading: false,
        lessons: {
          ...initialState.lessons,
          followedLessons: action.payload
        }
      }
    case 'GET_MY_LESSONS':
      return {
        ...initialState,
        loading: false,
        lessons: {
          ...initialState.lessons,
          myLessons: action.payload
        }
      }
    case 'GET_SCHEDULED_LESSONS':
      return {
        ...initialState,
        loading: false,
        lessons: {
          ...initialState.lessons,
          scheduledLessons: action.payload
        }
      }
    case 'GET_COMPLETED_LESSONS':
      return {
        ...initialState,
        loading: false,
        lessons: {
          ...initialState.lessons,
          completedLessons: action.payload
        }
      }
    case 'GET_COMPLETED_LESSONS_BY_TAGS':
      return {
        ...initialState,
        loading: false,
        lessons: {
          ...initialState.lessons,
          ...action.payload
        }
      }
    case 'CHANGE_ACTIVE_INDEX':
      return {
        ...initialState,
        activeIndex: action.payload
      }
    case 'SAVE_LESSONS':
      return {
        ...initialState,
        lessons: {
          ...initialState.lessons,
          mainLessons: action.payload.main,
          followedLessons: action.payload.followed,
          savedLessons: action.payload.saved
        }
      }
  }
}
