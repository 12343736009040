import styled from 'styled-components'

export const ContainerSubscription = styled.button`
  margin-top: 16px;
  height: 56px;
  width: 320px;
  min-width: 304px;
  padding: 6px 0;
  cursor: pointer;
  display:flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  border: 1px solid ${({ theme }) => theme.colors.violet};
  box-shadow: 0 0 4px ${({ theme }) => theme.colors.violet}, inset 0 0 10px ${({ theme }) => theme.colors.violet};
  overflow: hidden;
`

export const IconContent = styled.div`
  width: 32px;
  height: 100%;
  display:flex;
  justify-content: flex-end;
  align-items: center;
`

export const TextContent = styled.div`
  height: 100%;
  width: calc(94% - 32px);
  display:flex;
  justify-content:center;
  align-items: flex-start;
  flex-direction: column;
`

export const TextButtonTitle = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 11px;
  font-family: ${({ theme }) => theme.fontFamilyMedium};
  margin-left: 2%;
  margin-bottom: 5px;
  text-align: left;
`

export const TextButtonSubTitle = styled.div`
  color:${({ theme }) => theme.colors.lila};
  font-size: 11px;
  font-family: ${({ theme }) => theme.fontFamilyRegular};;
  margin-left: 2%;
  text-align: left;
`
