import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { fadeIn } from '../../styles/animation'
import { device } from '../../styles/device'

export const NavLinkComponent = styled(NavLink)`
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 2px;
  color: #fff;
  text-decoration: none;
  opacity: 0.4;
  &[aria-current] {
    ${fadeIn({ time: '0.5s' })};
    border-bottom: 6px solid #00FEFF;
    opacity: 1;
  }
  &:hover {
    border-bottom: 6px solid #00FEFF;
  }

  @media ${device.tablet} {
    &[aria-current] {
      ${fadeIn({ time: '0.5s' })};
      border-top: 4px solid #00FEFF;
      border-bottom: 0;
    }

    &:hover {
      border-top: 4px solid #00FEFF;
      border-bottom: 0;
    }
  }
`

export const Text = styled.div`
  font-family: 'TitilliumWebSemiBold';
`
