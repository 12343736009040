import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'

// Notifications
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Line } from 'react-chartjs-2'
// import { merge } from 'lodash'

// API
import { ROUTES } from '../../constants'
import http from '../../services/DataService'
import Close from '../../assets/images/close-circle-outline.png'
import Arrowdown from '../../assets/images/arrowdown.png'
import useTranslation from '../../i18n'

export default function () {
  // const [tooltipPos, setTooltipPos] = useState({ x: 0, y: 0, visible: false, value: 0, index: 0 })
  const [list, setList] = useState({ data: [{ _id: moment().format('YYYY-MM-DD'), usd: 10 }], dataSet: [0], labels: [0] })
  const [duration, setDuration] = useState('all')
  const [days, setDays] = useState(30)
  const [modal, setModal] = useState('main')
  // const refModalBottom = useRef()
  const { t } = useTranslation()

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          },
          gridLines: {
            borderColor: '#1B1465',
            color: '#1B1465'
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            borderColor: '#1B1465',
            color: '#1B1465'
          }
        }
      ]
    },
    elements: {
      point: {
        backgroundColor: '#00FE4E',
        borderColor: '#1B1465'
      },
      line: {
        backgroundColor: '#1B1465',
        borderWidth: 1
      }
    },
    legend: {
      display: false
    },
    responsive: true,
    tooltips: {
      callbacks: {
        title: (items, data) => moment(list.data[items[0].datasetIndex]._id, 'YYYY-MM-DD').format('DD MMM YYYY'), // data.datasets[items[0].datasetIndex].data[items[0].index].myProperty1,
        label: (items, data) => list.data[items.datasetIndex].usd + ' £'// data.datasets[item.datasetIndex].data[item.index].myProperty2
      },
      backgroundColor: '#1B1465',
      titleFontSize: 12,
      titleFontColor: '#6A65A8',
      bodyFontColor: '#FFF',
      bodyFontSize: 12,
      displayColors: false
    }
  }

  const openModal = (type) => {
    setModal(type)
  }

  const closeModal = () => {
    setModal('main')
  }

  // const DotContent = ({ index, x, y, value }) => {
  //   return (<Dot x={x} y={y}><DotText >{moment(list.data[index]._id, 'YYYY-MM-DD').format('DD MMM YYYY')}</DotText><DotText white>{`${list.data[index].usd} USD`}</DotText></Dot>)
  // }

  const getChart = async () => {
    try {
      // setLoading(true);
      const data = {
        duration: duration,
        days: days
      }
      const response = await http.getAll(ROUTES.CHART, data)// api.chart(data)

      const list = response.result
      if (list.data.length === 0) { setList({ data: [{ _id: moment().format('YYYY-MM-DD'), usd: 10 }], dataSet: [0], labels: [0] }) } else { setList(list) }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      if (error.status === 401) {
        toast.error(error.message_en)
      } else if (error.status === 403) {
        toast.error(error.message_en)
      } else {
        toast.error(error.message)
      }
    }
  }

  useEffect(() => {
    async function upload () {
      await getChart()
    }
    upload()
  }, [days, duration])

  return (
    <Container>
      <ToastContainer/>
      <Header>
        <div onClick={() => { openModal('duration') }}>
          <MenuExt>
            <TextMenu>{duration} {t('ChartDuration')}</TextMenu>
            <IconDown resizeMode='contain' src={Arrowdown}/>
          </MenuExt>
        </div>
        <div onClick={() => { openModal('days') }}>
          <MenuExt>
            <TextMenu>{days} {t('ChartDays')}</TextMenu>
            <IconDown resizeMode='contain' src={Arrowdown}/>
          </MenuExt>
        </div>
      </Header>
      <Charts>
        <Line data={{
          labels: list.labels,
          datasets: [{
            label: '# of Votes',
            data: list.dataSet,
            fill: false,
            backgroundColor: '#00FE4E',
            borderColor: '#1B1465'
          }]
        }}
        options={options}
        />
      </Charts>
      <ModalBottom active={modal !== 'main'}>
        <ContainerModal>
          <HeaderModal>
          <MenuExt></MenuExt>
          <MenuCenter>
            <TitleModal></TitleModal>
          </MenuCenter>
          <MenuExt>
            <div onClick={closeModal}>
            <IconClose resizeMode='contain' src={Close} />
            </div>
          </MenuExt>
        </HeaderModal>
          <ScrollViewModal>
          {
            modal === 'duration' &&
              <>
              <div onClick={() => { setDuration('all'); closeModal() }}>
              <TextEdit>All</TextEdit>
              </div>
              <div onClick={() => { setDuration('30min'); closeModal() }}>
              <TextEdit>{`30min ${t('ChartDuration')}`}</TextEdit>
              </div>
              <div onClick={() => { setDuration('60min'); closeModal() }}>
              <TextEdit>{`60min ${t('ChartDuration')}`}</TextEdit>
              </div>
            </>
          }
          {
            modal === 'days' &&
              <>
              <div onClick={() => { setDays(30); closeModal() }}>
                <TextEdit>{`30 ${t('ChartDays')}`}</TextEdit>
              </div>
              <div onClick={() => { setDays(15); closeModal() }}>
                <TextEdit>{`15 ${t('ChartDays')}`}</TextEdit>
              </div>
              <div onClick={() => { setDays(10); closeModal() }}>
                <TextEdit>{`10 ${t('ChartDays')}`}</TextEdit>
              </div>
            </>
          }
        </ScrollViewModal>
        </ContainerModal>
      </ModalBottom>
    </Container>
  )
}

const ModalBottom = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.backgroundDark};
  align-items: flex-start;
  border-radius: 12px;
  display:${({ active }) => active ? 'flex' : 'none'};
  z-index:10;
  position:absolute;
  top:28%;
  left:17%;
  border: 2px solid ${({ theme }) => theme.colors.grayLight};
  width:70%;
`

const Container = styled.div`
  background: ${({ theme }) => theme.colors.blueDark};
  height:100%;
  width:100%;
  position:relative;
`

const Header = styled.div`
  flex-direction: row;
  height: 60px;
  width:90%;
  justify-content:space-evenly;
  align-self:center;
  display:flex;
`

const IconDown = styled.img`
  height: 24px;
  justify-content: center;
  align-items: center;
  align-self: center;
  width:24px;
`

const TextMenu = styled.div`
  color: ${({ theme }) => theme.colors.cyan};
  font-size: 14px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamilyBold};
  cursor:pointer;
`

const Charts = styled.div`
  height:200px;
  width:95%;
  justify-content:center;
  flex-direction:row;
`

const MenuExt = styled.div`
  justify-content: flex-end;
  align-items: center;
  display:flex;
  padding:7px;
`

const MenuCenter = styled.div`
  justify-content: center;
  align-items: center;
  flex: 5;
`

const ContainerModal = styled.div`
  flex: 1;
  align-items: flex-start;
  display:flex;
  justify-content:center;
  flex-direction:column;
`

const HeaderModal = styled.div`
  height: 40px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display:flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lila};
`

const TitleModal = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  text-align: center;
  align-self: center;
  font-family: ${({ theme }) => theme.fontFamilyMedium};
`

const IconClose = styled.img`
  height: 26px;
  justify-content: center;
  align-items: center;
`

const ScrollViewModal = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 10px;
  margin-bottom:10px;
`

const TextEdit = styled.div`
  color: ${({ theme }) => theme.colors.cyan};
  font-size: 18px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamilyBold};
  margin-top: 5%;
  cursor:pointer;
`
